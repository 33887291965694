import { trim } from 'lodash'

export default function providerMainnetUrlForRetry(retry: number) {
  // The env variable can be a comma separated list of
  // provider urls
  const availableUrls = String(process.env.NEXT_PUBLIC_MAINNET_PROVIDER)
    .split(',')
    .map(trim)

  if (availableUrls?.[retry]) {
    return availableUrls?.[retry]
  }

  return availableUrls?.[0]
}
