import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import persistentToastSelectors from '../modules/persistentToast/persistentToastSelectors'

export default function PersistentToast() {
  const current = useSelector(persistentToastSelectors.selectCurrent)
  const toastId = React.useRef(null)

  useEffect(() => {
    if (toastId.current) {
      toast.dismiss(toastId.current)
    }

    if (current) {
      toastId.current = toast.info(current.message, {
        autoClose: false,
        theme: 'dark',
      })
    }

    return () => {
      if (toastId.current) {
        toast.dismiss(toastId.current)
      }
    }
  }, [current])

  return null
}
