import { BattleSoundsRanges } from '../../lib/BattleSoundsRanges'
import { randomIntFromInterval } from '../../lib/randomIntFromInterval'
import { Howl } from 'howler'
export default class AudioService {
  public static mainAudio: Howl
  public static duelAudio: Howl
  public static hurtAudio: Howl
  public static victoryFanfare: Howl

  public static getLobbySong() {
    return '/sounds/Lobby/Adventure.ogg.mp3'
  }

  public static getMedievalSong() {
    return '/sounds/Lobby/Medieval.ogg.mp3'
  }

  public static getArenaSong() {
    return '/sounds/Arena/Duel_Arena.ogg'
  }

  public static getWildernessSong() {
    const randomAudioTrack = randomIntFromInterval(1, 3)
    return `/sounds/Arena/Wilderness_${randomAudioTrack}.ogg`
  }

  public static victorySong() {
    return '/sounds/PostDuel/Victory_(Duel_Arena).ogg'
  }

  public static defeatSong() {
    return '/sounds/PostDuel/Loss_(Duel_Arena).ogg'
  }

  public static getBattleSounds({ player, action }) {
    if (!player?.statGenes?.class || !action) {
      return null
    }
    const randomAudioTrack = randomIntFromInterval(
      1,
      BattleSoundsRanges[`${player?.statGenes?.class}${action}`],
    )
    return `/sounds/Duel/${player?.statGenes?.class}/${action}/${randomAudioTrack}.flac`
  }

  public static getDamagedHitsounds({ player, action }) {
    const randomAudioTrack = randomIntFromInterval(1, 5)
    if (action === 'hurt') {
      return `/sounds/Duel/OnDamageHit/${randomAudioTrack}.flac`
    }
    return ''
  }
}
