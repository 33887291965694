const prefix = 'AUTH'

const authActions = {
  MODAL_OPENED: `${prefix}_MODAL_OPENED`,
  MODAL_CLOSED: `${prefix}_MODAL_CLOSED`,

  PROFILE_CHANGED: `${prefix}_PROFILE_CHANGED`,

  doChangeProfile(profile) {
    return {
      type: authActions.PROFILE_CHANGED,
      payload: profile,
    }
  },

  doOpenModal: () => {
    return {
      type: authActions.MODAL_OPENED,
    }
  },

  doCloseModal: () => {
    return {
      type: authActions.MODAL_CLOSED,
    }
  },
}

export default authActions
