import { createSelector } from 'reselect'

const selectRaw = (state) => state.audio

const isMusicMuted = createSelector([selectRaw], (raw) => raw.isMusicMuted)
const isEffectMuted = createSelector([selectRaw], (raw) => raw.isEffectMuted)
const loserAddress = createSelector([selectRaw], (raw) => raw.loserAddress)
const battleSounds = createSelector([selectRaw], (raw) => raw.battleSounds)

const audioSelectors = {
  selectRaw,
  isMusicMuted,
  isEffectMuted,
  loserAddress,
  battleSounds,
}

export default audioSelectors
