import chatActions from './chatActions'

const initialData = {
  onlineUsers: {},
}

const chatReducers = (state = initialData, { type, payload }) => {
  if (type === chatActions.ONLINE_USERS_CHANGED) {
    return {
      ...state,
      onlineUsers: payload,
    }
  }

  return state
}

export default chatReducers
