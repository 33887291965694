import { faBullhorn, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import React, { useEffect, useMemo, useState } from 'react'
import formatGold from '../../lib/formatGold'
import formatJewel from '../../lib/formatJewel'
import { OlderVersionsBattleService } from '../../modules/olderVersions/olderVersionsBattleService'
import styles from '../../styles/OlderVersions.module.scss'
import OlderVersionsModal from './olderVersionsModal'

export default function OlderVersionsBanner() {
  const [isClosed, setIsClosed] = useState(false)
  const { account } = useWeb3React()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>()
  const [olderVersionsModalOpen, setOlderVersionsModalOpen] = useState(false)

  useEffect(() => {
    if (account) {
      doFetch()
    }
  }, [account])

  async function doFetch() {
    try {
      setLoading(true)
      const data = await OlderVersionsBattleService.fetchAllDataFromAllVersions(
        account,
      )
      setData(data)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const isOpenBattlesEmpty = !data?.openBattles || !data?.openBattles.length

  const isGoldEmpty =
    !data?.goldAmount || ethers.BigNumber.from(data.goldAmount).isZero()

  const isJewelEmpty =
    !data?.jewelAmount || ethers.BigNumber.from(data.jewelAmount).isZero()

  let textComponent = useMemo(() => {
    let textComponents = [<span key="1">Please click here to </span>]

    if (!isJewelEmpty || !isGoldEmpty) {
      textComponents.push(<span key="2">withdraw your </span>)

      if (!isGoldEmpty) {
        textComponents.push(
          <strong key="3" style={{ color: 'var(--gold)' }}>
            {formatGold(String(data.goldAmount))} G
          </strong>,
        )

        if (isJewelEmpty) {
          textComponents.push(<span key="4"> </span>)
        } else {
          if (isOpenBattlesEmpty) {
            textComponents.push(<span key="5"> and </span>)
          } else {
            textComponents.push(<span key="6">, </span>)
          }
        }
      }

      if (!isJewelEmpty) {
        textComponents.push(
          <strong key="7" style={{ color: 'var(--gold)' }}>
            {formatJewel(String(data.jewelAmount))} J{' '}
          </strong>,
        )
      }

      if (!isOpenBattlesEmpty) {
        textComponents.push(<span key="8">and </span>)
      }
    }

    if (!isOpenBattlesEmpty) {
      textComponents.push(
        <span key="9">
          close your{' '}
          <strong style={{ color: 'var(--gold)' }}>
            {data.openBattles.length} open{' '}
            {data.openBattles.length === 1 ? 'duel' : 'duels'}
          </strong>{' '}
        </span>,
      )
    }

    textComponents.push(<span key="10">from the old version.</span>)
    return <>{textComponents}</>
  }, [data])

  if (
    isClosed ||
    loading ||
    (isOpenBattlesEmpty && isGoldEmpty && isJewelEmpty)
  ) {
    return null
  }

  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.bannerWrapperSecond}>
        <div className={styles.bannerWrapperThird}>
          <div className={styles.bannerContent}>
            <div
              onClick={() => setOlderVersionsModalOpen(true)}
              className={styles.bannerTextWrapper}
            >
              <span className={styles.bannerIconWrapper}>
                <FontAwesomeIcon
                  icon={faBullhorn}
                  className={styles.bannerIcon}
                  aria-hidden="true"
                />
              </span>
              <p className={styles.bannerText}>{textComponent}</p>
            </div>
            <div className={styles.bannerCloseButtonWrapper}>
              <button
                type="button"
                className="btn"
                onClick={() => setIsClosed(true)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className={styles.bannerIcon}
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <OlderVersionsModal
        isOpen={olderVersionsModalOpen}
        onClose={() => setOlderVersionsModalOpen(false)}
      />
    </div>
  )
}
