import promoCodeListModalActions from './promoCodeListModalActions'

const initialData = {
  userAddress: null,
}

const promoCodeListReducers = (state = initialData, { type, payload }) => {
  if (type === promoCodeListModalActions.MODAL_OPENED) {
    return {
      ...state,
      userAddress: payload,
    }
  }

  if (type === promoCodeListModalActions.MODAL_CLOSED) {
    return {
      ...state,
      userAddress: null,
    }
  }

  return state
}

export default promoCodeListReducers