import { uniq } from 'lodash'
import { createSelector } from 'reselect'

const selectRaw = (state) => state.liveGames

const selectState = createSelector([selectRaw], (raw) => raw.state)

const selectFetchId = createSelector([selectRaw], (raw) => raw.fetchId)

const selectData = createSelector([selectRaw], (raw) => {
  return (raw.data || []).filter((item) => {
    return ['FIRSTPLAYERIN', 'BOTHCONFIRMED'].includes(item.status)
  })
})

const selectActiveCount = createSelector([selectData], (data) => {
  return data.length
})

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter || {}
})

const selectFilteredData = createSelector(
  [selectData, selectFilter],
  (data, filter) => {
    return data.filter((item) => {
      if (
        filter.betAmount &&
        Number(item.bet) !== Number(filter.betAmount?.value)
      ) {
        return false
      }

      return true
    })
  },
)

const selectRenderedPage = createSelector([selectRaw], (raw) => {
  return raw.renderedPage
})

const selectItemsPerPage = createSelector([selectRaw], (raw) => {
  return raw.itemsPerPage
})

const selectPageData = createSelector(
  [selectFilteredData, selectRenderedPage, selectItemsPerPage],
  (filteredData, renderedPage, itemsPerPage) => {
    return filteredData.slice(
      renderedPage * itemsPerPage,
      (renderedPage + 1) * itemsPerPage,
    )
  },
)

const selectTotal = createSelector([selectFilteredData], (filteredData) => {
  return filteredData.length
})

const selectHeroesIdsInUse = createSelector([selectData], (data) => {
  return uniq(
    [
      ...data.map((item) => item.hero?.id),
      ...data.map((item) => item.otherHero?.id),
    ]
      .map((id) => Number(id))
      .filter(Boolean),
  )
})

const liveGamesSelectors = {
  selectState,
  selectData,
  selectPageData,
  selectRenderedPage,
  selectItemsPerPage,
  selectFilter,
  selectRaw,
  selectTotal,
  selectFetchId,
  selectActiveCount,
  selectHeroesIdsInUse,
}

export default liveGamesSelectors
