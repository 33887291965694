import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AbiItem } from 'web3-utils'
import classNames from '../../lib/classNames'
import formatGold from '../../lib/formatGold'
import formatJewel from '../../lib/formatJewel'
import Messages from '../../lib/messages'
import { Web3Errors } from '../../modules/error/Web3Errors'
import { OlderVersionsBattleService } from '../../modules/olderVersions/olderVersionsBattleService'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import styles from '../../styles/OlderVersions.module.scss'
import Spinner from '../spinner'

interface OlderVersionsBattlesProps {
  label: string
  contractAddress: string
  abi: AbiItem[]
}

export default function OlderVersionsBalance(props: OlderVersionsBattlesProps) {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const [state, setState] = useState('loading')
  const [goldBalance, setGoldBalance] = useState('0')
  const [jewelBalance, setJewelBalance] = useState('0')

  useEffect(() => {
    doFetch().then(() => {})
  }, [])

  async function doFetch() {
    try {
      setState('loading')
      const goldBalance = await OlderVersionsBattleService.fetchGoldBalance(
        account,
        props.contractAddress,
        props.abi,
        0,
      )
      setGoldBalance(goldBalance)

      const jewelBalance = await OlderVersionsBattleService.fetchJewelBalance(
        account,
        props.contractAddress,
        props.abi,
        0,
      )
      setJewelBalance(jewelBalance)
      setState('idle')
    } catch (error) {
      console.error(error)
      setState('error')
    }
  }

  async function doWithdrawJewel() {
    try {
      setState('withdrawing')
      dispatch(
        persistentToastActions.doAddToast(
          'olderVersionsBalance',
          'Waiting for withdraw confirmation...',
        ),
      )
      await OlderVersionsBattleService.withdrawJewel(
        account,
        props.contractAddress,
        props.abi,
        jewelBalance,
      )
      Messages.success('Jewel sucessfully withdrawn!')
      return doFetch()
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('olderVersionsBalance'))
    }
  }

  async function doWithdrawGold() {
    try {
      setState('withdrawing')
      dispatch(
        persistentToastActions.doAddToast(
          'olderVersionsBalance',
          'Waiting for withdraw confirmation...',
        ),
      )
      await OlderVersionsBattleService.withdrawGold(
        account,
        props.contractAddress,
        props.abi,
        goldBalance,
      )
      Messages.success('Gold sucessfully withdrawn!')
      return doFetch()
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('olderVersionsBalance'))
    }
  }

  return (
    <div className={styles.section}>
      <div className={classNames('heading', styles.title)}>Balance</div>
      {state === 'error' && <div className={styles.error}>Error</div>}
      {state === 'loading' && <Spinner />}
      {(state === 'idle' || state === 'withdrawing') && (
        <div
          style={{ display: 'flex', justifyContent: 'space-between', gap: 16 }}
        >
          <div>
            <button
              onClick={() => doWithdrawGold()}
              className={`btn btn--default btn--green ${styles.button}`}
              disabled={
                state === 'withdrawing' || goldBalance.toString() == '0'
              }
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                marginBottom: 8,
                marginTop: 16,
              }}
            >
              Withdraw Gold
            </button>
            <div style={{ marginTop: 8 }}>
              There's{' '}
              <strong style={{ color: '#fbe375' }}>
                {formatGold(goldBalance)} G
              </strong>{' '}
              to be withdrawn from the {props.label} version.
            </div>
          </div>

          <div>
            <button
              onClick={() => doWithdrawJewel()}
              className={`btn btn--default btn--green ${styles.button}`}
              disabled={
                state === 'withdrawing' || jewelBalance.toString() == '0'
              }
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                marginBottom: 8,
                marginTop: 16,
              }}
            >
              Withdraw Jewel
            </button>
            <div style={{ marginTop: 8 }}>
              There's{' '}
              <strong style={{ color: '#fbe375' }}>
                {formatJewel(jewelBalance)} J
              </strong>{' '}
              to be withdrawn from the {props.label} version.
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
