import { faVolumeHigh, faVolumeXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWeb3React } from '@web3-react/core'
import { Howl } from 'howler'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { randomIntFromInterval } from '../lib/randomIntFromInterval'
import audioActions from '../modules/audio/audioActions'
import audioSelectors from '../modules/audio/audioSelectors'
import AudioService from '../modules/audio/audioService'

export default function AudioManager() {
  const router = useRouter()
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const isMusicMuted = useSelector(audioSelectors.isMusicMuted)
  const isEffectMuted = useSelector(audioSelectors.isEffectMuted)
  const loserAddress = useSelector(audioSelectors.loserAddress)
  const battleSounds = useSelector(audioSelectors.battleSounds)

  useEffect(() => {
    if (isEffectMuted) {
      AudioService.duelAudio && AudioService.duelAudio.stop()
      return
    }

    if (battleSounds === null) {
      return
    }

    if (AudioService.getDamagedHitsounds(battleSounds)) {
      AudioService.hurtAudio = new Howl({
        loop: false,
        muted: false,
        autoPlay: true,
        src: AudioService.getDamagedHitsounds(battleSounds),
      })
      AudioService.hurtAudio.play()
    }

    if (AudioService.getBattleSounds(battleSounds)) {
      AudioService.duelAudio = new Howl({
        loop: false,
        muted: false,
        autoPlay: true,
        src: AudioService.getBattleSounds(battleSounds),
      })
      AudioService.duelAudio.play()
    }
  }, [battleSounds, isEffectMuted])

  useEffect(() => {
    if (isEffectMuted) {
      AudioService.victoryFanfare && AudioService.victoryFanfare.stop()
      return
    }

    if (loserAddress == null) {
      return
    }

    AudioService.mainAudio && AudioService.mainAudio.pause()
    AudioService.duelAudio && AudioService.duelAudio.pause()

    const src =
      loserAddress === account
        ? AudioService.defeatSong()
        : AudioService.victorySong()

    AudioService.victoryFanfare = new Howl({
      loop: false,
      muted: false,
      autoPlay: true,
      src,
    })
    AudioService.victoryFanfare.play()
    dispatch(audioActions.doReset())
  }, [loserAddress, isEffectMuted])

  useEffect(() => {
    if (isMusicMuted) {
      if (AudioService.mainAudio) {
        AudioService.mainAudio.pause()
      }
      return
    }

    const isInDuel = Boolean(router.route.includes('/duel'))

    const src = !isInDuel
      ? AudioService.getLobbySong()
      : AudioService?.mainAudio?._src &&
        AudioService?.mainAudio?._src.includes(`Arena`)
      ? AudioService?.mainAudio?._src
      : randomIntFromInterval(1, 10) % 2 === 0
      ? AudioService.getArenaSong()
      : AudioService.getWildernessSong()
    const volume = isInDuel ? 0.1 : 0.3

    if (!isInDuel) {
      AudioService.victoryFanfare && AudioService.victoryFanfare.pause()
      AudioService.duelAudio && AudioService.duelAudio.pause()
    }

    if (AudioService.mainAudio && AudioService.mainAudio._src === src) {
      AudioService.mainAudio.volume = volume
      AudioService.mainAudio.pause()
      AudioService.mainAudio.play()
    } else {
      AudioService.mainAudio && AudioService.mainAudio.stop()
      AudioService.mainAudio = new Howl({
        volume,
        src,
        autoplay: true,
        loop: true,
      })
      AudioService.mainAudio.play()
    }
  }, [router, isMusicMuted])

  const toggleMainAudioMute = () => {
    if (isMusicMuted) {
      dispatch(audioActions.doMusicUnmute())
    } else {
      dispatch(audioActions.doMusicMute())
    }
  }

  const toggleSoundEffectMute = () => {
    if (isEffectMuted) {
      dispatch(audioActions.doEffectUnmute())
    } else {
      dispatch(audioActions.doEffectMute())
    }
  }

  return (
    <>
      <button
        className={`btn btn--default btn--dark btn--square`}
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          marginRight: 4,
          color: 'white',
        }}
        onClick={toggleSoundEffectMute}
      >
        {isEffectMuted ? (
          <FontAwesomeIcon icon={faVolumeXmark} />
        ) : (
          <FontAwesomeIcon icon={faVolumeHigh} />
        )}
      </button>
      <button
        className={`btn btn--default btn--dark btn--square`}
        style={{ paddingLeft: 10, paddingRight: 10 }}
        onClick={toggleMainAudioMute}
      >
        {isMusicMuted ? (
          <Image src={'/musicSlashIcon.svg'} width={24} height={24} />
        ) : (
          <Image src={'/musicIcon.svg'} width={24} height={24} />
        )}
      </button>
    </>
  )
}
