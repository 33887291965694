import { createSelector } from 'reselect'

const selectRaw = (state) => state.adminModeratorMute

const selectState = createSelector([selectRaw], (raw) => raw.state)
const selectFetchId = createSelector([selectRaw], (raw) => raw.fetchId)
const selectData = createSelector([selectRaw], (raw) => {
  return raw.rows
})
const selectRenderedPage = createSelector([selectRaw], (raw) => {
  return raw.renderedPage
})
const selectItemsPerPage = createSelector([selectRaw], (raw) => {
  return raw.itemsPerPage
})
const selectTotal = createSelector([selectRaw], (raw) => {
  return raw.count
})

const adminModeratorMuteSelectors = {
  selectState,
  selectData,
  selectRenderedPage,
  selectItemsPerPage,
  selectRaw,
  selectTotal,
  selectFetchId,
}

export default adminModeratorMuteSelectors
