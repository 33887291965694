const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')

const handleValidationOnChange = (e, onChange) => {
  const { value } = e.target
  if (value === '' || floatRegExp.test(value)) {
    onChange(e)
  }
}

export const InputFloat = (props) => {
  if (typeof props.onChange !== 'function') {
    return <input {...props} />
  }

  const { onChange, ...parentProps } = props

  return (
    <input
      {...parentProps}
      onChange={(e) => handleValidationOnChange(e, onChange)}
    />
  )
}
