import axios from 'axios'
import { capitalize } from 'lodash'
import moment from 'moment'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import duelStatusEnumerator from '../../enumerators/duelStatusEnumerator'
import providerUrlForRetry from '../../lib/providerUrlForRetry'
import { sleep } from '../../lib/sleep'
import { ConfigStore } from '../config/configStore'
import { DuelFightLogic } from '../duel/duelFightLogic'
import HeroService from '../hero/heroService'
import { UserProfileService } from '../users/userProfileService'

export default class CompletedGamesService {
  static async fetchLast(accountAddress) {
    const { rows } = await this.fetch(accountAddress, 0, 1)

    if (rows) {
      return rows[0]
    }

    return null
  }

  static async fetchPairingsStatus(pairId, retry: number) {
    try {
      retry = Number(retry) || 0

      const web3Instance = new Web3(providerUrlForRetry(0))
      const contract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )
      await contract.methods.pairings_status(pairId).call()
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchPairingsStatus(pairId, retry + 1)
      }

      throw error
    }
  }

  static async fetchOpenGameInLast10(accountAddress) {
    const { rows } = await this.fetch(accountAddress, 0, 10)

    for (let row of rows) {
      const pairingStatus = await this.fetchPairingsStatus(row.pairId, 0)
      if (duelStatusEnumerator[pairingStatus] === 'GAMEFINISHED') {
        return row
      }
    }
  }

  static async fetch(accountAddress, page, pageSize) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/battle/completed`,
      {
        params: {
          accountAddress,
          page,
          pageSize,
        },
      },
    )

    let rows = await Promise.all(
      data.rows.map(async (row) => {
        return {
          gameType: 'Stat 1 v 1',
          id: row.pairId,
          pairId: row.pairId,
          completed: moment(row.timestamp).toDate(),
          player1: await UserProfileService.findProfileByAddressFromBlockchain(
            row.player1Address,
          ),
          player1Hero: await HeroService.fetch(
            row.player1Hero?.id,
            row.player1Hero?.stats,
            row.player1Hero?.info,
            row.player1Hero?.state,
          ),
          player2: await UserProfileService.findProfileByAddressFromBlockchain(
            row.player2Address,
          ),
          player2Hero: await HeroService.fetch(
            row.player2Hero?.id,
            row.player2Hero?.stats,
            row.player2Hero?.info,
            row.player2Hero?.state,
          ),
          stat:
            row.fightRounds && row.fightRounds.length
              ? capitalize(row.fightRounds[row.fightRounds.length - 1].skill)
              : '',
          bet: row.goldAmount,
          winnerAddress: row.winnerAddress,
          isDraw: row.isDraw,
        }
      }),
    )

    rows = rows.map(this.convertDuelHpMpStats)

    return { rows, count: data.count }
  }

  static async findByPairId(pairId) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/battle/${pairId}`,
    )

    if (!data) {
      return data
    }

    data.player1Hero = await HeroService.fetch(
      data.player1Hero?.id,
      data.player1Hero?.stats,
      data.player1Hero?.info,
      data.player1Hero?.state,
    )

    data.player2Hero = await HeroService.fetch(
      data.player2Hero?.id,
      data.player2Hero?.stats,
      data.player2Hero?.info,
      data.player2Hero?.state,
    )

    data.player1 = await UserProfileService.findProfileByAddressFromBlockchain(
      data.player1Address,
    )
    data.player2 = await UserProfileService.findProfileByAddressFromBlockchain(
      data.player2Address,
    )

    return this.convertDuelHpMpStats(data)
  }

  static convertDuelHpMpStats(duel) {
    if (!duel?.player1Hero?.stats || !duel?.player2Hero?.stats) {
      return duel
    }

    // After 10300 the new duel logic is in place
    if (Number(duel.pairId) >= 10300) {
      const isHpAndMpTransformed = true

      duel.player1Hero.stats = {
        ...duel?.player1Hero?.stats,
        ...DuelFightLogic.convertStats(1, duel),
        hp: DuelFightLogic.convertHp(1, duel),
        mp: DuelFightLogic.convertMp(1, duel),
        isHpAndMpTransformed,
      }

      duel.player2Hero.stats = {
        ...duel?.player2Hero?.stats,
        ...DuelFightLogic.convertStats(2, duel),
        hp: DuelFightLogic.convertHp(2, duel),
        mp: DuelFightLogic.convertMp(2, duel),
        isHpAndMpTransformed,
      }
    }

    // After 4895 we start summing the HP and MP
    if (Number(duel.pairId) > 4895) {
      duel.player1Hero.stats.hp =
        Number(duel?.player1Hero?.stats?.hp) +
        Number(duel?.player1Hero?.stats?.mp)

      duel.player2Hero.stats.hp =
        Number(duel?.player2Hero?.stats?.hp) +
        Number(duel?.player2Hero?.stats?.mp)
    }

    return duel
  }
}
