import { useEffect } from 'react'

export const useDisableBodyScroll = (open: any) => {
  useEffect(() => {
    if (open) {
      let $body = document.body
      // @ts-ignore
      window.scrollPosition = window.pageYOffset
      // @ts-ignore
      window.tmpScrollPos = -1 * window.scrollPosition
      $body.style.overflow = 'hidden'
      $body.style.position = 'fixed'
      // @ts-ignore
      $body.style.top = window.tmpScrollPos + 'px'
      $body.style.width = '100%'
    } else {
      let $body = document.body
      $body.style.removeProperty('overflow')
      $body.style.removeProperty('position')
      $body.style.removeProperty('top')
      $body.style.removeProperty('width')
      window.scrollTo({
        // @ts-ignore
        top: -1 * window.tmpScrollPos,
        // @ts-ignore
        behavior: 'instant',
      })
    }
  }, [open])
}
