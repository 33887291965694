import Messages from '../../lib/messages'
import { AuthToken } from '../auth/authToken'
import { captureException } from '@sentry/nextjs'

const DEFAULT_ERROR_MESSAGE =
  'Oops, something went wrong. Please try again later.'

export class ApiErrors {
  static handle(error) {
    console.error(error)

    if (selectErrorCode(error) === '401') {
      AuthToken.clear()
    } else {
      captureException(error)
      Messages.error(selectErrorMessage(error))
    }
  }
}

function selectErrorCode(error) {
  if (error && error.response && error.response.status) {
    return String(error.response.status)
  }

  if (error.code) {
    return String(error.code)
  }

  return String(500)
}

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data

    if (data.error && data.error.message) {
      return data.error.message
    }

    return String(data)
  }

  return error.message || DEFAULT_ERROR_MESSAGE
}
