const prefix = 'CHAT'

const chatActions = {
  ONLINE_USERS_CHANGED: `${prefix}_ONLINE_USERS_CHANGED`,

  changeOnlineUsers(onlineUsers) {
    return {
      type: chatActions.ONLINE_USERS_CHANGED,
      payload: onlineUsers,
    }
  },
}

export default chatActions
