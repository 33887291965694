import liveGamesActions from './liveGamesActions'

const initialData = {
  state: 'idle',
  data: [],
  filter: {},
  renderedPage: 0,
  itemsPerPage: 8,
  fetchId: null,
  fetchedAll: false,
}

const liveGamesReducers = (state = initialData, { type, payload }) => {
  if (type === liveGamesActions.PAGINATION_CHANGED) {
    return {
      ...state,
      renderedPage: payload,
    }
  }

  if (type === liveGamesActions.FILTER_CHANGED) {
    return {
      ...state,
      filter: payload,
    }
  }

  if (type === liveGamesActions.FETCH_STARTED) {
    return {
      ...state,
      state: 'loading',
      fetchId: payload,
    }
  }

  if (type === liveGamesActions.FETCH_SUCCESS) {
    return {
      ...state,
      state: payload.data.length ? 'idle' : 'empty',
      data: payload.data,
    }
  }

  if (type === liveGamesActions.FETCH_ERROR) {
    return {
      ...state,
      state: 'error',
    }
  }

  if (type === liveGamesActions.REFRESH_DATA) {
    return {
      ...state,
      state: payload.data.length ? 'idle' : 'empty',
      data: payload.data,
    }
  }

  return state
}

export default liveGamesReducers
