import { AbiItem } from 'web3-utils'

let HeroContractAbi: AbiItem[] = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'approved',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'approved',
        type: 'bool',
      },
    ],
    name: 'ApprovalForAll',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'heroId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'summonerId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'assistantId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'statGenes',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'visualGenes',
        type: 'uint256',
      },
    ],
    name: 'HeroSummoned',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'previousAdminRole',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'newAdminRole',
        type: 'bytes32',
      },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Unpaused',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'HERO_MODERATOR_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MINTER_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MODERATOR_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'PAUSER_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'approve',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'burn',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_statGenes',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_visualGenes',
        type: 'uint256',
      },
      {
        internalType: 'enum IHeroTypes.Rarity',
        name: '_rarity',
        type: 'uint8',
      },
      {
        internalType: 'bool',
        name: '_shiny',
        type: 'bool',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'summonerId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'assistantId',
            type: 'uint256',
          },
          {
            internalType: 'uint16',
            name: 'generation',
            type: 'uint16',
          },
          {
            internalType: 'uint256',
            name: 'createdBlock',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'heroId',
            type: 'uint256',
          },
          {
            internalType: 'uint8',
            name: 'summonerTears',
            type: 'uint8',
          },
          {
            internalType: 'uint8',
            name: 'assistantTears',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'bonusItem',
            type: 'address',
          },
          {
            internalType: 'uint32',
            name: 'maxSummons',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'firstName',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'lastName',
            type: 'uint32',
          },
          {
            internalType: 'uint8',
            name: 'shinyStyle',
            type: 'uint8',
          },
        ],
        internalType: 'struct ICrystalTypes.HeroCrystal',
        name: '_crystal',
        type: 'tuple',
      },
    ],
    name: 'createHero',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'getApproved',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_id',
        type: 'uint256',
      },
    ],
    name: 'getHero',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'summonedTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'nextSummonTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'summonerId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'assistantId',
                type: 'uint256',
              },
              {
                internalType: 'uint32',
                name: 'summons',
                type: 'uint32',
              },
              {
                internalType: 'uint32',
                name: 'maxSummons',
                type: 'uint32',
              },
            ],
            internalType: 'struct IHeroTypes.SummoningInfo',
            name: 'summoningInfo',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'statGenes',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'visualGenes',
                type: 'uint256',
              },
              {
                internalType: 'enum IHeroTypes.Rarity',
                name: 'rarity',
                type: 'uint8',
              },
              {
                internalType: 'bool',
                name: 'shiny',
                type: 'bool',
              },
              {
                internalType: 'uint16',
                name: 'generation',
                type: 'uint16',
              },
              {
                internalType: 'uint32',
                name: 'firstName',
                type: 'uint32',
              },
              {
                internalType: 'uint32',
                name: 'lastName',
                type: 'uint32',
              },
              {
                internalType: 'uint8',
                name: 'shinyStyle',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'class',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'subClass',
                type: 'uint8',
              },
            ],
            internalType: 'struct IHeroTypes.HeroInfo',
            name: 'info',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'staminaFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint16',
                name: 'level',
                type: 'uint16',
              },
              {
                internalType: 'uint64',
                name: 'xp',
                type: 'uint64',
              },
              {
                internalType: 'address',
                name: 'currentQuest',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'sp',
                type: 'uint8',
              },
              {
                internalType: 'enum IHeroTypes.HeroStatus',
                name: 'status',
                type: 'uint8',
              },
            ],
            internalType: 'struct IHeroTypes.HeroState',
            name: 'state',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'strength',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'intelligence',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'wisdom',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'luck',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'agility',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'vitality',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'endurance',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'dexterity',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hp',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mp',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'stamina',
                type: 'uint16',
              },
            ],
            internalType: 'struct IHeroTypes.HeroStats',
            name: 'stats',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'strength',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'intelligence',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'wisdom',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'luck',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'agility',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'vitality',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'endurance',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'dexterity',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpSm',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpRg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpLg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpSm',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpRg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpLg',
                type: 'uint16',
              },
            ],
            internalType: 'struct IHeroTypes.HeroStatGrowth',
            name: 'primaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'strength',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'intelligence',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'wisdom',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'luck',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'agility',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'vitality',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'endurance',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'dexterity',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpSm',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpRg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpLg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpSm',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpRg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpLg',
                type: 'uint16',
              },
            ],
            internalType: 'struct IHeroTypes.HeroStatGrowth',
            name: 'secondaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'mining',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'gardening',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'foraging',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'fishing',
                type: 'uint16',
              },
            ],
            internalType: 'struct IHeroTypes.HeroProfessions',
            name: 'professions',
            type: 'tuple',
          },
        ],
        internalType: 'struct IHeroTypes.Hero',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
    ],
    name: 'getRoleAdmin',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'getRoleMember',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
    ],
    name: 'getRoleMemberCount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_address',
        type: 'address',
      },
    ],
    name: 'getUserHeroes',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: '_name',
        type: 'string',
      },
      {
        internalType: 'string',
        name: '_symbol',
        type: 'string',
      },
      {
        internalType: 'string',
        name: '_url',
        type: 'string',
      },
      {
        internalType: 'address',
        name: '_statScienceAddress',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'name',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'symbol',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'baseTokenURI',
        type: 'string',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
    ],
    name: 'isApprovedForAll',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'ownerOf',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '_data',
        type: 'bytes',
      },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'approved',
        type: 'bool',
      },
    ],
    name: 'setApprovalForAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_statScienceAddress',
        type: 'address',
      },
    ],
    name: 'setStatScienceAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'tokenByIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'tokenURI',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'transferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'summonedTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'nextSummonTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'summonerId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'assistantId',
                type: 'uint256',
              },
              {
                internalType: 'uint32',
                name: 'summons',
                type: 'uint32',
              },
              {
                internalType: 'uint32',
                name: 'maxSummons',
                type: 'uint32',
              },
            ],
            internalType: 'struct IHeroTypes.SummoningInfo',
            name: 'summoningInfo',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'statGenes',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'visualGenes',
                type: 'uint256',
              },
              {
                internalType: 'enum IHeroTypes.Rarity',
                name: 'rarity',
                type: 'uint8',
              },
              {
                internalType: 'bool',
                name: 'shiny',
                type: 'bool',
              },
              {
                internalType: 'uint16',
                name: 'generation',
                type: 'uint16',
              },
              {
                internalType: 'uint32',
                name: 'firstName',
                type: 'uint32',
              },
              {
                internalType: 'uint32',
                name: 'lastName',
                type: 'uint32',
              },
              {
                internalType: 'uint8',
                name: 'shinyStyle',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'class',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'subClass',
                type: 'uint8',
              },
            ],
            internalType: 'struct IHeroTypes.HeroInfo',
            name: 'info',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'staminaFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint16',
                name: 'level',
                type: 'uint16',
              },
              {
                internalType: 'uint64',
                name: 'xp',
                type: 'uint64',
              },
              {
                internalType: 'address',
                name: 'currentQuest',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'sp',
                type: 'uint8',
              },
              {
                internalType: 'enum IHeroTypes.HeroStatus',
                name: 'status',
                type: 'uint8',
              },
            ],
            internalType: 'struct IHeroTypes.HeroState',
            name: 'state',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'strength',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'intelligence',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'wisdom',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'luck',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'agility',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'vitality',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'endurance',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'dexterity',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hp',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mp',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'stamina',
                type: 'uint16',
              },
            ],
            internalType: 'struct IHeroTypes.HeroStats',
            name: 'stats',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'strength',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'intelligence',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'wisdom',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'luck',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'agility',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'vitality',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'endurance',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'dexterity',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpSm',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpRg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpLg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpSm',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpRg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpLg',
                type: 'uint16',
              },
            ],
            internalType: 'struct IHeroTypes.HeroStatGrowth',
            name: 'primaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'strength',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'intelligence',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'wisdom',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'luck',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'agility',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'vitality',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'endurance',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'dexterity',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpSm',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpRg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'hpLg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpSm',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpRg',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'mpLg',
                type: 'uint16',
              },
            ],
            internalType: 'struct IHeroTypes.HeroStatGrowth',
            name: 'secondaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'mining',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'gardening',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'foraging',
                type: 'uint16',
              },
              {
                internalType: 'uint16',
                name: 'fishing',
                type: 'uint16',
              },
            ],
            internalType: 'struct IHeroTypes.HeroProfessions',
            name: 'professions',
            type: 'tuple',
          },
        ],
        internalType: 'struct IHeroTypes.Hero',
        name: '_hero',
        type: 'tuple',
      },
    ],
    name: 'updateHero',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'userHeroes',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

export default HeroContractAbi
