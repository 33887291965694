import { AbiItem } from 'web3-utils'

let AffiliateContractAbi: AbiItem[] = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'affiliate_link',
    outputs: [
      {
        internalType: 'address',
        name: 'referrer',
        type: 'address',
      },
      {
        internalType: 'string',
        name: 'promo_code',
        type: 'string',
      },
      {
        internalType: 'bool',
        name: 'signed_up',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'all_promo_codes_owned_by_user',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'escrowContractLink',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
    ],
    name: 'fetch_all_promo_codes_owned_by_user',
    outputs: [
      {
        internalType: 'string[]',
        name: '',
        type: 'string[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'promo_code',
        type: 'string',
      },
    ],
    name: 'fetch_promo_code_owner',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'signups',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'accumulated_earnings',
            type: 'uint256',
          },
        ],
        internalType: 'struct Affiliate.promo_code_object',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
    ],
    name: 'get_affiliate_owner',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'referrer',
            type: 'address',
          },
          {
            internalType: 'string',
            name: 'promo_code',
            type: 'string',
          },
          {
            internalType: 'bool',
            name: 'signed_up',
            type: 'bool',
          },
        ],
        internalType: 'struct Affiliate.new_account',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'promo_code_used',
        type: 'string',
      },
      {
        internalType: 'uint256',
        name: 'num_gold',
        type: 'uint256',
      },
    ],
    name: 'increment_referral_earnings',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    name: 'promo_code_ownership',
    outputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'signups',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'accumulated_earnings',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'existing_promo_code',
        type: 'string',
      },
    ],
    name: 'remove_promo_code',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'escrowContractAddress',
        type: 'address',
      },
    ],
    name: 'setEscrowContractLink',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'new_promo_code',
        type: 'string',
      },
    ],
    name: 'set_promo_code_ownership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'promo_code_used',
        type: 'string',
      },
    ],
    name: 'sign_up_with_promo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]

export default AffiliateContractAbi
