import { capitalize, toLower } from 'lodash'
import Image from 'next/image'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import OnlineUserProperies from '../enumerators/onlineUserProperties'
import RankEnumerator from '../enumerators/rankEnumerator'
import isAddressEqual from '../lib/isAddressEqual'
import isAdmin from '../lib/isAdmin'
import isModerator from '../lib/isModerator'
import chatSelectors from '../modules/chat/chatSelectors'
import HeroService from '../modules/hero/heroService'
import { UserProfileService } from '../modules/users/userProfileService'
import styles from '../styles/Avatar.module.scss'
import HeroProfile from './heroCard/HeroSprite/HeroProfile'

interface ProfileAvatarProps {
  profile: {
    address?: string
    publicAddress?: string
    isHeroAvatar?: boolean
    nftId?: string
    picUri?: string
    rank?: any
  }
  size: 'small' | 'medium' | 'large'
  lazyLoad?: boolean
  showOnlineStatus?: boolean
  className: string
}

function OnlineStatus(props) {
  const { profile } = props
  const onlineUsers = useSelector(chatSelectors.selectOnlineUsers)

  const isOnline = useMemo(() => {
    return onlineUsers[toLower(profile.publicAddress || profile.address)]
  }, [onlineUsers, profile])

  return (
    <div
      className={styles.label}
      style={{
        background: isOnline ? '#bef264' : '#fca5a5',
      }}
    ></div>
  )
}

export default function ProfileAvatar(props: ProfileAvatarProps) {
  const { size, lazyLoad, showOnlineStatus, className } = props

  const [fetchedProfile, setFetchedProfile] = useState()
  const [hero, setHero] = useState<any>()

  const profile = fetchedProfile || props.profile

  useEffect(() => {
    if (props.profile && lazyLoad) {
      UserProfileService.findProfileByAddressFromBlockchain(
        props.profile.address || props.profile.publicAddress,
      )
        .then((fetchedProfile) => {
          if (fetchedProfile) {
            setFetchedProfile(fetchedProfile)
          }
        })
        .catch((error) => console.error(error))
    }
  }, [props.profile])

  useEffect(() => {
    if (props.profile && lazyLoad && !fetchedProfile) {
      return
    }

    if (!profile?.isHeroAvatar) {
      return
    }

    HeroService.fetch(profile.nftId)
      .then((hero) => {
        setHero(hero)
      })
      .catch((error) => console.error(error))
  }, [profile, fetchedProfile, props.profile, lazyLoad])

  const sizeInPx = {
    small: 32,
    medium: 56,
    large: 92,
  }

  const sizeInScale = {
    small: 0.32,
    medium: 0.56,
    large: 0.92,
  }

  const userTypeColors = {
    user: '#ffffff',
    moderator: '#fdba74',
    admin: '#ef4444',
  }

  const profileAddress = profile?.publicAddress || profile?.address

  let userType = 'user'

  if (profileAddress && isAdmin(profileAddress)) {
    userType = 'admin'
  }

  if (profileAddress && isModerator(profileAddress)) {
    userType = 'moderator'
  }

  if (!profile?.isHeroAvatar) {
    return (
      <>
        <div
          data-tip={`<div><span style="color: ${
            userTypeColors[userType]
          }">${capitalize(userType)}<span></br><span style="color: ${
            RankEnumerator.findRank(profile?.rank).color
          }">${RankEnumerator.findRank(profile?.rank).label}</span></div>`}
          data-html={true}
          className={className}
          style={{
            borderTopColor: RankEnumerator.findRank(profile?.rank).color,
            borderLeftColor: RankEnumerator.findRank(profile?.rank).color,
            borderBottomColor: userTypeColors[userType],
            borderRightColor: userTypeColors[userType],
          }}
        >
          <Image
            alt="Avatar"
            className="avatarImg"
            src={profile?.picUri || '/avatar.png'}
            width={sizeInPx[size]}
            height={sizeInPx[size]}
          />
          {showOnlineStatus ? <OnlineStatus profile={profile} /> : null}
        </div>
        {/* @ts-ignore */}
        <ReactTooltip className="dfkTooltip" effect="solid" />
      </>
    )
  }

  if (hero) {
    return (
      <>
        <div
          data-tip={`<div><span style="color: ${
            userTypeColors[userType]
          }">${capitalize(userType)}<span></br><span style="color: ${
            RankEnumerator.findRank(profile?.rank).color
          }">${RankEnumerator.findRank(profile?.rank).label}</span></div>`}
          data-html={true}
          className={className}
          style={{
            borderTopColor: RankEnumerator.findRank(profile?.rank).color,
            borderLeftColor: RankEnumerator.findRank(profile?.rank).color,
            borderBottomColor: userTypeColors[userType],
            borderRightColor: userTypeColors[userType],
          }}
        >
          <HeroProfile hero={hero} scale={sizeInScale[size]} />
          {showOnlineStatus ? <OnlineStatus profile={profile} /> : null}
        </div>
        {/* @ts-ignore */}
        <ReactTooltip className="dfkTooltip" effect="solid" />
      </>
    )
  }

  return (
    <>
      <div
        className={className}
        data-tip={`<div><span style="color: ${
          userTypeColors[userType]
        }">${capitalize(userType)}<span></br><span style="color: ${
          RankEnumerator.findRank(profile?.rank).color
        }">${RankEnumerator.findRank(profile?.rank).label}</span></div>`}
        data-html={true}
        style={{
          borderTopColor: RankEnumerator.findRank(profile?.rank).color,
          borderLeftColor: RankEnumerator.findRank(profile?.rank).color,
          borderBottomColor: userTypeColors[userType],
          borderRightColor: userTypeColors[userType],
        }}
      >
        <Image
          alt="Avatar"
          className="avatarImg"
          src={'/avatar.png'}
          width={sizeInPx[size]}
          height={sizeInPx[size]}
        />
        {showOnlineStatus ? <OnlineStatus profile={profile} /> : null}
      </div>
      {/* @ts-ignore */}
      <ReactTooltip className="dfkTooltip" effect="solid" />
    </>
  )
}
