import heroFilterActions from './heroFilterActions'
import { HeroOptionsForm } from './heroFilterFormInterface'

const initialData = {
  filterData: {
    class: null,
    element: null,
    gender: null,
    generation: null,
    rarity: null,
    subClass: null,
  },
  filteredHeroes: [],
  count: 0,
  renderedPage: 0,
  itemsPerPage: 12,
}

const heroFilterReducers = (state = initialData, { type, payload }) => {

  if (type === heroFilterActions.PAGE_CHANGED) {
    return {
      ...state,
      renderedPage: payload,
    }
  }

  if (type === heroFilterActions.UPDATE_FILTER) {
    return {
      ...state,
      filterData: payload,
    }
  }

  if (type === heroFilterActions.FILTERED_HEROES) {
    return {
      ...state,
      filteredHeroes: payload,
      count: payload.length,
    }
  }

  return state
}

export default heroFilterReducers