import moment from 'moment'
import React from 'react'
import styles from '../../styles/Chat.module.scss'
import { shortenHex } from '../../util'
import ProfileAvatar from '../ProfileAvatar'

interface ChatPrivateUserProps {
  user: any
  onClick: any
}

export default function ChatUser(props: ChatPrivateUserProps) {
  const { user } = props

  const chatBadges = user?.chatBadges || []

  return (
    <div
      className={styles.chatUser}
      style={{ cursor: 'pointer' }}
      onClick={props.onClick}
    >
      <div className={styles.chatUserContent}>
        <ProfileAvatar
          className={styles.messageAvatar}
          showOnlineStatus
          lazyLoad
          profile={user}
          size={'small'}
        />
        <div className={styles.chatUserName}>
          <div className={styles.messageTop}>
            <span className={styles.messageUsername}>
              {user.name || shortenHex(user.publicAddress)}
              <span className={styles.badges}>
                {chatBadges.map((badge) => {
                  return (
                    <img
                      key={badge.id}
                      alt="icon"
                      src={badge.imageUrl}
                      width={10}
                      height={10}
                      data-tip={`<span>${badge.label}</span>`}
                      data-html={true}
                    />
                  )
                })}
              </span>
            </span>
          </div>
          <div className={styles.messagesLastMessage}>
            Last message was {moment(user?.lastMessage?.createdAt).fromNow()}
          </div>
        </div>
      </div>
    </div>
  )
}
