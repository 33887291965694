import { Web3ReactProvider } from '@web3-react/core'
import { AppProps } from 'next/app'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthModal } from '../components/authModal'
import Layout from '../components/layout'
import PersistentToast from '../components/PersistentToast'
import { PromoCodeModal } from '../components/promoCode/promoCodeModal'
import { UserModal } from '../components/userModal/userModal'
import getLibrary from '../getLibrary'
import { configureStore } from '../modules'
import ConfigService from '../modules/config/configService'
import { ApiErrors } from '../modules/error/ApiErrors'
import '../styles/globals.scss'
import { PromoCodeListModal } from '../components/promoCode/promoCodeListModal'
import { useRouter } from 'next/router'
import { pageview } from '../lib/gtag'
import Analytics from '../components/Analytics'
import Head from 'next/head'

const store = configureStore()

export default function MyApp({ Component, pageProps }: AppProps) {
  const [configLoaded, setConfigLoaded] = useState(false)

  useEffect(() => {
    ConfigService.fetchAndStore()
      .then(() => {
        setConfigLoaded(true)
      })
      .catch((error) => {
        ApiErrors.handle(error)
      })
  }, [])

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  if (!configLoaded) {
    return null
  }

  return (
    <>
      <Head>
        <title>DFK Arena | DefiKingdoms Hero PvP</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ToastContainer />
          <Layout>
            <>
              {/* @ts-ignore */}
              <Component {...pageProps} />
              <Analytics />
              <AuthModal />
              <UserModal />
              <PersistentToast />
              <PromoCodeModal />
              <PromoCodeListModal />
            </>
          </Layout>
        </Web3ReactProvider>
      </Provider>
    </>
  )
}
