import { useWeb3React } from '@web3-react/core'
import Link from 'next/link'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useHeroes from '../hooks/useHeroes'
import authSelectors from '../modules/auth/authSelectors'
import balanceActions from '../modules/balance/balanceActions'
import styles from '../styles/Header.module.scss'
import { shortenHex } from '../util'
import ProfileAvatar from './ProfileAvatar'

const Account = () => {
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const profile = useSelector(authSelectors.selectProfile)

  // This is called in order to cache the heroes on the server at the sign-in
  const { heroes } = useHeroes()

  useEffect(() => {
    dispatch(balanceActions.doFetch(account))

    let pooling = setInterval(() => {
      dispatch(balanceActions.doFetch(account, true))
    }, 30 * 1000)

    return () => {
      if (pooling) {
        clearInterval(pooling)
      }
    }
  }, [account])

  return (
    <>
      <Link href="/profile">
        <a className={styles.profile}>
          <ProfileAvatar
            className={styles.profileAvatar}
            profile={profile}
            size={'small'}
          />
          <div className={styles.profileNickname}>{profile?.name}</div>
          <div className={styles.profileWallet}>{shortenHex(account, 4)}</div>
        </a>
      </Link>
    </>
  )
}

export default Account
