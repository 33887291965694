import { AbiItem } from 'web3-utils'

let BattleContractAbiBeta1: AbiItem[] = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'heroAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'auctionAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'goldAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'jewelAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'escrowAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'goldOracleAddress',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'gamer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'ConFirm_Game',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'player1',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'player2',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'game_winner',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'summonedTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'nextSummonTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'summonerId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'assistantId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'summons',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxSummons',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.SummoningInfo',
            name: 'summoningInfo',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'statGenes',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'visualGenes',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'rarity',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'shiny',
                type: 'bool',
              },
              {
                internalType: 'uint256',
                name: 'lastName',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'shinyStyle',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'class',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'subClass',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Info',
            name: 'info',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'staminaFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'level',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'xp',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'currentQuest',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'sp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'status',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.State',
            name: 'state',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'stamina',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Stats',
            name: 'stats',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpLg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpLg',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.PrimaryStateGrowth',
            name: 'primaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpLg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpLg',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.SecondaryStatGrowth',
            name: 'secondaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'mining',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'gardening',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'foraging',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'fishing',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Professions',
            name: 'professions',
            type: 'tuple',
          },
        ],
        indexed: false,
        internalType: 'struct IHero.HeroObject',
        name: 'player1Hero',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'summonedTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'nextSummonTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'summonerId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'assistantId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'summons',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxSummons',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.SummoningInfo',
            name: 'summoningInfo',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'statGenes',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'visualGenes',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'rarity',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'shiny',
                type: 'bool',
              },
              {
                internalType: 'uint256',
                name: 'lastName',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'shinyStyle',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'class',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'subClass',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Info',
            name: 'info',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'staminaFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'level',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'xp',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'currentQuest',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'sp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'status',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.State',
            name: 'state',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'stamina',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Stats',
            name: 'stats',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpLg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpLg',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.PrimaryStateGrowth',
            name: 'primaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpLg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpLg',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.SecondaryStatGrowth',
            name: 'secondaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'mining',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'gardening',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'foraging',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'fishing',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Professions',
            name: 'professions',
            type: 'tuple',
          },
        ],
        indexed: false,
        internalType: 'struct IHero.HeroObject',
        name: 'player2Hero',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'is_draw',
        type: 'bool',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'attacker',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'defender',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'skill',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'damage',
            type: 'uint256',
          },
        ],
        indexed: false,
        internalType: 'struct BattleContract.Fight_Round[]',
        name: 'fight_rounds',
        type: 'tuple[]',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gold_amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'Finished_Game',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'gamer1',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'gamer2',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'Game_Abandoned_PostPair',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'creator',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'hero_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gold_amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'jewel_amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'Game_Abandoned_PrePair',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'creator',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'hero_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gold_amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'jewel_amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'Game_Created',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'player1',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'player2',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'Post_Game_Updates',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'gamer',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'hero_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gold_amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'jewel_amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'Second_Player_Joined',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'gamer',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'Start_Game',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
    ],
    name: 'abandon_game_post_pairing',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
    ],
    name: 'abandon_game_pre_pairing',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
    ],
    name: 'confirm_game_post_pairing',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_hero_id',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_gold_amount',
        type: 'uint256',
      },
    ],
    name: 'create_game',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'delegate_address',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'escrowLink',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user_address',
        type: 'address',
      },
    ],
    name: 'fetch_battles_of_user',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'pair_id',
            type: 'uint256',
          },
          {
            internalType: 'enum BattleContract.GameStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'gamer_address',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'hero_id',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'gold_staked',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'jewel_staked',
                type: 'uint256',
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'summonedTime',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'nextSummonTime',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'summonerId',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'assistantId',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'summons',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'maxSummons',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.SummoningInfo',
                    name: 'summoningInfo',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'statGenes',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'visualGenes',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'rarity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'bool',
                        name: 'shiny',
                        type: 'bool',
                      },
                      {
                        internalType: 'uint256',
                        name: 'lastName',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'shinyStyle',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'class',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'subClass',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Info',
                    name: 'info',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'staminaFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'level',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'xp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'address',
                        name: 'currentQuest',
                        type: 'address',
                      },
                      {
                        internalType: 'uint256',
                        name: 'sp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'status',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.State',
                    name: 'state',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'stamina',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Stats',
                    name: 'stats',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpLg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpLg',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.PrimaryStateGrowth',
                    name: 'primaryStatGrowth',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpLg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpLg',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.SecondaryStatGrowth',
                    name: 'secondaryStatGrowth',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'mining',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'gardening',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'foraging',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'fishing',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Professions',
                    name: 'professions',
                    type: 'tuple',
                  },
                ],
                internalType: 'struct IHero.HeroObject',
                name: 'selected_hero',
                type: 'tuple',
              },
              {
                internalType: 'bool',
                name: 'is_confirmed',
                type: 'bool',
              },
              {
                internalType: 'bool',
                name: 'did_win',
                type: 'bool',
              },
              {
                internalType: 'uint256',
                name: 'creation_time',
                type: 'uint256',
              },
            ],
            internalType: 'struct BattleContract.individual_gamer[]',
            name: 'gamer_pair',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct BattleContract.Fetched_result[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
    ],
    name: 'fetch_individual_battle',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'pair_id',
            type: 'uint256',
          },
          {
            internalType: 'enum BattleContract.GameStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'gamer_address',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'hero_id',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'gold_staked',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'jewel_staked',
                type: 'uint256',
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'summonedTime',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'nextSummonTime',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'summonerId',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'assistantId',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'summons',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'maxSummons',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.SummoningInfo',
                    name: 'summoningInfo',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'statGenes',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'visualGenes',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'rarity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'bool',
                        name: 'shiny',
                        type: 'bool',
                      },
                      {
                        internalType: 'uint256',
                        name: 'lastName',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'shinyStyle',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'class',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'subClass',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Info',
                    name: 'info',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'staminaFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'level',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'xp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'address',
                        name: 'currentQuest',
                        type: 'address',
                      },
                      {
                        internalType: 'uint256',
                        name: 'sp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'status',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.State',
                    name: 'state',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'stamina',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Stats',
                    name: 'stats',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpLg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpLg',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.PrimaryStateGrowth',
                    name: 'primaryStatGrowth',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpLg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpLg',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.SecondaryStatGrowth',
                    name: 'secondaryStatGrowth',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'mining',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'gardening',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'foraging',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'fishing',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Professions',
                    name: 'professions',
                    type: 'tuple',
                  },
                ],
                internalType: 'struct IHero.HeroObject',
                name: 'selected_hero',
                type: 'tuple',
              },
              {
                internalType: 'bool',
                name: 'is_confirmed',
                type: 'bool',
              },
              {
                internalType: 'bool',
                name: 'did_win',
                type: 'bool',
              },
              {
                internalType: 'uint256',
                name: 'creation_time',
                type: 'uint256',
              },
            ],
            internalType: 'struct BattleContract.individual_gamer[]',
            name: 'gamer_pair',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct BattleContract.Fetched_result',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'fetch_open_battles',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'pair_id',
            type: 'uint256',
          },
          {
            internalType: 'enum BattleContract.GameStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'gamer_address',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'hero_id',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'gold_staked',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'jewel_staked',
                type: 'uint256',
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'summonedTime',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'nextSummonTime',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'summonerId',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'assistantId',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'summons',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'maxSummons',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.SummoningInfo',
                    name: 'summoningInfo',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'statGenes',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'visualGenes',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'rarity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'bool',
                        name: 'shiny',
                        type: 'bool',
                      },
                      {
                        internalType: 'uint256',
                        name: 'lastName',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'shinyStyle',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'class',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'subClass',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Info',
                    name: 'info',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'staminaFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpFullAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'level',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'xp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'address',
                        name: 'currentQuest',
                        type: 'address',
                      },
                      {
                        internalType: 'uint256',
                        name: 'sp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'status',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.State',
                    name: 'state',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hp',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'stamina',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Stats',
                    name: 'stats',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpLg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpLg',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.PrimaryStateGrowth',
                    name: 'primaryStatGrowth',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'strength',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'wisdom',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'agility',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'endurance',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpLg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'intelligence',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'luck',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vitality',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'dexterity',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'hpRg',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpSm',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'mpLg',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.SecondaryStatGrowth',
                    name: 'secondaryStatGrowth',
                    type: 'tuple',
                  },
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'mining',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'gardening',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'foraging',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'fishing',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct IHero.Professions',
                    name: 'professions',
                    type: 'tuple',
                  },
                ],
                internalType: 'struct IHero.HeroObject',
                name: 'selected_hero',
                type: 'tuple',
              },
              {
                internalType: 'bool',
                name: 'is_confirmed',
                type: 'bool',
              },
              {
                internalType: 'bool',
                name: 'did_win',
                type: 'bool',
              },
              {
                internalType: 'uint256',
                name: 'creation_time',
                type: 'uint256',
              },
            ],
            internalType: 'struct BattleContract.individual_gamer[]',
            name: 'gamer_pair',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct BattleContract.Fetched_result[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'goldOracleLink',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'heroCooldownUntil',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'integrateDFKContracts',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'hero_id',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'gold_amount',
        type: 'uint256',
      },
    ],
    name: 'join_existing_game',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'match_expiration_timer',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'open_pairings',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pairings_count',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'pairings_gamers',
    outputs: [
      {
        internalType: 'address',
        name: 'gamer_address',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'hero_id',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'gold_staked',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'jewel_staked',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'summonedTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'nextSummonTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'summonerId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'assistantId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'summons',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'maxSummons',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.SummoningInfo',
            name: 'summoningInfo',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'statGenes',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'visualGenes',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'rarity',
                type: 'uint256',
              },
              {
                internalType: 'bool',
                name: 'shiny',
                type: 'bool',
              },
              {
                internalType: 'uint256',
                name: 'lastName',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'shinyStyle',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'class',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'subClass',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Info',
            name: 'info',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'staminaFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpFullAt',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'level',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'xp',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'currentQuest',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'sp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'status',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.State',
            name: 'state',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'stamina',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Stats',
            name: 'stats',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpLg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpLg',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.PrimaryStateGrowth',
            name: 'primaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'strength',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'wisdom',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'agility',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'endurance',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpLg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'intelligence',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'luck',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'vitality',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'dexterity',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'hpRg',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpSm',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'mpLg',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.SecondaryStatGrowth',
            name: 'secondaryStatGrowth',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'mining',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'gardening',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'foraging',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'fishing',
                type: 'uint256',
              },
            ],
            internalType: 'struct IHero.Professions',
            name: 'professions',
            type: 'tuple',
          },
        ],
        internalType: 'struct IHero.HeroObject',
        name: 'selected_hero',
        type: 'tuple',
      },
      {
        internalType: 'bool',
        name: 'is_confirmed',
        type: 'bool',
      },
      {
        internalType: 'bool',
        name: 'did_win',
        type: 'bool',
      },
      {
        internalType: 'uint256',
        name: 'creation_time',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'pairings_involved',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'pairings_status',
    outputs: [
      {
        internalType: 'enum BattleContract.GameStatus',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'pair_id',
        type: 'uint256',
      },
    ],
    name: 'post_fight_updates',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_contract_address',
        type: 'address',
      },
    ],
    name: 'set_delegate_address',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bool',
        name: 'integrate_or_not',
        type: 'bool',
      },
    ],
    name: 'set_integrateDFKContracts',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]

export default BattleContractAbiBeta1
