import React from 'react'
import styles from '../styles.module.css'

/* FEMALE TORSO */
const femaleArcherBody = '/svgs/female/clothes/archer-Body.svg'
const femaleArcherBehind = '/svgs/female/clothes/archer-Behind.svg'
const femaleKnightBody = '/svgs/female/clothes/knight-Body.svg'
const femaleKnightBehind = '/svgs/female/clothes/knight-Behind.svg'
const femaleMonkBody = '/svgs/female/clothes/monk-Body.svg'
const femaleMonkBehind = '/svgs/female/clothes/monk-Behind.svg'
const femalePirateBody = '/svgs/female/clothes/pirate-Body.svg'
const femalePirateBehind = '/svgs/female/clothes/pirate-Behind.svg'
const femalePriestBody = '/svgs/female/clothes/priest-Body.svg'
const femalePriestBehind = '/svgs/female/clothes/priest-Behind.svg'
const femaleThiefBody = '/svgs/female/clothes/thief-Body.svg'
const femaleThiefBehind = '/svgs/female/clothes/thief-Behind.svg'
const femaleWarriorBody = '/svgs/female/clothes/warrior-Body.svg'
const femaleWarriorBehind = '/svgs/female/clothes/warrior-Behind.svg'
const femaleWizardBody = '/svgs/female/clothes/wizard-Body.svg'
const femaleWizardBehind = '/svgs/female/clothes/wizard-Behind.svg'

const FemaleUndies = '/svgs/female/undies-female.svg'
const FemaleBodyHighlight = '/svgs/female/Body-highlight.svg'
const FemaleBodyShadow = '/svgs/female/Body-shadow.svg'

/* ADVANCED HEROES */
const femalePaladinBody = '/svgs/female/clothes/advanced/paladin-Body.svg'
const femalePaladinBehind = '/svgs/female/clothes/advanced/paladin-Behind.svg'
const femaleDarkKnightBody = '/svgs/female/clothes/advanced/darkKnight-Body.svg'
const femaleDarkKnightBehind =
  '/svgs/female/clothes/advanced/darkKnight-Behind.svg'
const femaleSummonerBody = '/svgs/female/clothes/advanced/summoner-Body.svg'
const femaleSummonerBehind = '/svgs/female/clothes/advanced/summoner-Behind.svg'
const femaleNinjaBody = '/svgs/female/clothes/advanced/ninja-Body.svg'
const femaleNinjaBehind = '/svgs/female/clothes/advanced/ninja-Behind.svg'

/* ELITE HEROES */
const femaleDragoonBody = '/svgs/female/clothes/elite/dragoon-Body.svg'
const femaleDragoonBehind = '/svgs/female/clothes/elite/dragoon-Behind.svg'
const femaleSageBody = '/svgs/female/clothes/elite/sage-Body.svg'
const femaleSageBehind = '/svgs/female/clothes/elite/sage-Behind.svg'

/* LEGENDARY HEROES */
const femaleDreadKnightBody =
  '/svgs/female/clothes/legendary/dreadknight-Body.svg'
const femaleDreadKnightBehind =
  '/svgs/female/clothes/legendary/dreadknight-Behind.svg'

const getBody = (mainClass) => {
  switch (mainClass) {
    case 'archer': {
      return {
        body: femaleArcherBody,
        behind: femaleArcherBehind,
      }
    }
    case 'knight': {
      return {
        body: femaleKnightBody,
        behind: femaleKnightBehind,
      }
    }
    case 'monk': {
      return {
        body: femaleMonkBody,
        behind: femaleMonkBehind,
      }
    }
    case 'pirate': {
      return {
        body: femalePirateBody,
        behind: femalePirateBehind,
      }
    }
    case 'priest': {
      return {
        body: femalePriestBody,
        behind: femalePriestBehind,
      }
    }
    case 'thief': {
      return {
        body: femaleThiefBody,
        behind: femaleThiefBehind,
      }
    }
    case 'warrior': {
      return {
        body: femaleWarriorBody,
        behind: femaleWarriorBehind,
      }
    }
    case 'wizard': {
      return {
        body: femaleWizardBody,
        behind: femaleWizardBehind,
      }
    }
    case 'paladin': {
      return {
        body: femalePaladinBody,
        behind: femalePaladinBehind,
      }
    }
    case 'darkKnight': {
      return {
        body: femaleDarkKnightBody,
        behind: femaleDarkKnightBehind,
      }
    }
    case 'summoner': {
      return {
        body: femaleSummonerBody,
        behind: femaleSummonerBehind,
      }
    }
    case 'ninja': {
      return {
        body: femaleNinjaBody,
        behind: femaleNinjaBehind,
      }
    }
    case 'dragoon': {
      return {
        body: femaleDragoonBody,
        behind: femaleDragoonBehind,
      }
    }
    case 'sage': {
      return {
        body: femaleSageBody,
        behind: femaleSageBehind,
      }
    }
    case 'dreadKnight': {
      return {
        body: femaleDreadKnightBody,
        behind: femaleDreadKnightBehind,
      }
    }
    default: {
      return {}
    }
  }
}

const FemaleBody = ({ mainClass, stroke }) => {
  const config = getBody(mainClass)
  return (
    <>
      <div className={`${styles.heroBody} ${styles.bodyPart}`}>
        <img src={FemaleUndies} className={styles.heroUndies} />
        <img src={config.body} className={styles.clothing} />
        <img src={FemaleBodyHighlight} className={styles.highlight} />
        <img src={FemaleBodyShadow} className={styles.shadow} />

        {/* Body svg in female folder */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -0.5 30 45"
          shapeRendering="crispEdges"
          className={styles.color}
        >
          <path
            stroke={stroke}
            d="M13 20h5M13 21h6M12 22h8M11 23h9M11 24h9M11 25h8M11 26h7M11 27h6M11 28h6M11 29h5"
          />
        </svg>
      </div>
      <div className={`${styles.heroBehind} ${styles.bodyPart}`}>
        <img src={config.behind} className={styles.clothing} />
      </div>
    </>
  )
}

export default FemaleBody
