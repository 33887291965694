import BigNumber from 'bignumber.js'
import numeral from 'numeral'

export default function formatGold(arg) {
  if (!arg) {
    return 0
  }

  const balance = new BigNumber(arg).dividedBy(
    Number(10 ** Number(process.env.NEXT_PUBLIC_GOLD_DECIMALS)),
  )
  return numeral(balance.toString()).format('0,0')
}
