import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { availableClasses } from '../components/duel/animations/characterClasses'
import HeroContractAbi from '../contracts/HeroContractAbi'
import providerUrlForRetry from '../lib/providerUrlForRetry'
import { sleep } from '../lib/sleep'
import { ConfigStore } from '../modules/config/configStore'
import HeroService from '../modules/hero/heroService'

const availableClassesAsKeys = Object.keys(availableClasses)

async function getUserHeroes(account, retry: number) {
  try {
    retry = retry || 0

    const web3Instance = new Web3(providerUrlForRetry(retry))

    const heroContract = new web3Instance.eth.Contract(
      HeroContractAbi,
      ConfigStore.get().HERO_CONTRACT_ADDRESS,
    )

    return await heroContract.methods.getUserHeroes(account).call()
  } catch (error) {
    if (retry <= 3) {
      await sleep(retry * 1000)
      return await getUserHeroes(account, retry + 1)
    }

    throw error
  }
}

async function getHeroesFromOwner(account: string) {
  let heroes = []
  let tokens = []

  if (ConfigStore.get().DFK_ENVIRONMENT !== 'production') {
    const web3Instance = new Web3(providerUrlForRetry(0))

    const heroContract = new web3Instance.eth.Contract(
      HeroContractAbi,
      ConfigStore.get().HERO_CONTRACT_ADDRESS,
    )

    let balance = await heroContract.methods
      .balanceOf(account)
      .call({ from: account })

    for (let i = 0; i < balance; i++) {
      tokens.push(
        await heroContract.methods.tokenOfOwnerByIndex(account, i).call(),
      )
      await sleep(100)
    }

    for (let i = 0; i < tokens.length; i++) {
      heroes.push(await HeroService.fetch(tokens[i]))
    }
  } else {
    tokens = await getUserHeroes(account, 0)

    for (let token of tokens) {
      heroes.push(await HeroService.fetch(token))
    }
  }

  heroes = heroes.filter(
    (hero) =>
      hero?.statGenes?.class &&
      availableClassesAsKeys.includes(hero?.statGenes?.class),
  )

  return heroes
}

export default function useHeroes() {
  const { account } = useWeb3React()
  const [heroes, setHeroes] = useState([])
  const [state, setState] = useState('loading')

  useEffect(() => {
    doRefresh(account)
  }, [account])

  function doRefresh(account) {
    if (account) {
      setState('loading')
      setHeroes([])
      getHeroesFromOwner(account)
        .then((_heroes) => {
          setHeroes(_heroes)
          if (_heroes.length === 0) {
            setState('empty')
          } else {
            setState('idle')
          }
        })
        .catch((error) => {
          console.error(error)
          setState('error')
          setHeroes([])
        })
    } else {
      setState('empty')
      setHeroes([])
    }
  }

  return { status: state, heroes: heroes, doRefresh }
}
