const duelStatusEnumeratorBeforeBeta4 = [
  'EMPTY',
  'FIRSTPLAYERIN',
  'ABANDONPREPAIRING',
  'SECONDPLAYERIN',
  'ABANDONPOSTPAIRING',
  'GAMESTARTED',
  'GAMEFINISHED',
  'REWARDSCLAIMED',
]

export default duelStatusEnumeratorBeforeBeta4
