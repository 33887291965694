export const BattleSoundsRanges = {
  'archerevasion': 5,
  'archerhurt': 4,
  'darkKnightevasion': 5,
  'darkKnighthurt': 3,
  'dragoonevasion': 2,
  'dragoonhurt': 5,
  'dreadKnightevasion': 5,
  'dreadKnighthurt': 5,
  'knightevasion': 5,
  'knighthurt': 15,
  'monkevasion': 5,
  'monkhurt': 12,
  'ninjaevasion': 5,
  'ninjahurt': 4,
  'paladinevasion': 5,
  'paladinhurt': 5,
  'pirateevasion': 5,
  'piratehurt': 6,
  'priestevasion': 3,
  'priesthurt': 3,
  'sageevasion': 5,
  'sagehurt': 2,
  'summonerevasion': 5,
  'summonerhurt': 9,
  'thiefevasion': 7,
  'thiefhurt': 2,
  'warriorevasion': 5,
  'warriorhurt': 4,
  'wizardevasion': 5,
  'wizardhurt': 5,
}