import { createSelector } from 'reselect'

const selectRaw = (state) => state.ranking
const selectState = createSelector([selectRaw], (raw) => raw.state)
const selectOrder = createSelector([selectRaw], (raw) => {
  return raw.order || {}
})
const selectFetchId = createSelector([selectRaw], (raw) => raw.fetchId)
const selectData = createSelector([selectRaw], (raw) => {
  return raw.rows || []
})

const selectRenderedPage = createSelector([selectRaw], (raw) => {
  return raw.renderedPage
})
const selectItemsPerPage = createSelector([selectRaw], (raw) => {
  return raw.itemsPerPage
})

const selectTotal = createSelector([selectRaw], (raw) => {
  return raw.count
})

const selectDirection = createSelector([selectRaw], (raw) => {
  return raw.direction || 'DESC'
})

const rankingSelectors = {
  selectRaw,
  selectState,
  selectOrder,
  selectFetchId,
  selectData,
  selectRenderedPage,
  selectItemsPerPage,
  selectTotal,
  selectDirection,
}

export default rankingSelectors