import axios from 'axios'
import { AuthToken } from '../auth/authToken'

export default class ChatService {
  static async fetchOnlineUsers() {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/chat/online-users`,
    )
    return data
  }

  static async fetchGlobalMessages(createdAtBefore) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/chat/message/global`,
      {
        params: {
          createdAtBefore,
        },
      },
    )
    return data
  }

  static async createGlobalMessage(publicAddress, text) {
    if (!text) {
      return
    }

    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/chat/message/global`,
      { text },
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }

  static async createTip(publicAddress, transactionHash, coin) {
    if (!transactionHash || !coin) {
      return
    }

    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/chat/tip`,
      { transactionHash, coin: coin.toUpperCase() },
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }

  static async deleteGlobalMessage(publicAddress, id) {
    if (!id) {
      return
    }

    await axios.delete(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/chat/message/${id}`,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )
  }

  static async fetchPrivateMessage(
    publicAddress,
    userPrivateId,
    createdAtBefore,
  ) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/chat/message/private/${userPrivateId}`,
      {
        params: {
          createdAtBefore,
        },
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }

  static async createPrivateMessage(publicAddress, userPrivateId, text) {
    if (!text) {
      return
    }

    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/chat/message/private/${userPrivateId}`,
      { text },
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }
}
