import { trim } from 'lodash'

export default function providerUrlForRetry(retry: number) {
  // The env variable can be a comma separated list of
  // provider urls
  const availableUrls = String(process.env.NEXT_PUBLIC_PROVIDER)
    .split(',')
    .map(trim)

  if (availableUrls?.[retry]) {
    return availableUrls?.[retry]
  }

  // If the number of retries are greater than the
  // size of the URL list, return always the first
  return availableUrls?.[0]
}
