import { sortBy, uniqBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import OnlineUserProperies from '../enumerators/onlineUserProperties'
import isAddressEqual from '../lib/isAddressEqual'
import { AuthToken } from '../modules/auth/authToken'
import { ApiErrors } from '../modules/error/ApiErrors'

export default function useChatSocket(account, isSignedIn) {
  const [socket, setSocket] = useState(null)
  const onlineUsersRef = React.useRef([])
  const [onlineUsers, setOnlineUsers] = useState([])
  const [initialPrivateUsers, setInitialPrivateUsers] = useState([])

  function refreshOnlineUsers(onlineUsers) {
    // clear old users
    onlineUsersRef.current = uniqBy(
      sortBy(
        onlineUsers.filter((user) =>
          moment(user[OnlineUserProperies.addedAt])
            .add(1, 'hour')
            .isAfter(moment()),
        ),
        OnlineUserProperies.addedAt,
      ).reverse(),
      OnlineUserProperies.publicAddress,
    )
    setOnlineUsers(onlineUsersRef.current)
  }

  useEffect(() => {
    const socket = io(process.env.NEXT_PUBLIC_BACKEND_URL)
    setSocket(socket)

    const isAuthenticated = Boolean(AuthToken.get(account))
    if (isAuthenticated) {
      socket.emit(
        'subscribe',
        { authToken: AuthToken.get(account) },
        (error, onlineUsers, initialPrivateUsers) => {
          if (error) {
            ApiErrors.handle(error)
            return
          }

          console.log('Socket connected')
          refreshOnlineUsers(onlineUsers)
          setInitialPrivateUsers(initialPrivateUsers)
        },
      )

      socket.io.on('reconnect', () => {
        console.log('reconnected')
        socket.emit(
          'subscribe',
          { authToken: AuthToken.get(account) },
          (error, onlineUsers, initialPrivateUsers) => {
            if (error) {
              ApiErrors.handle(error)
              return
            }

            refreshOnlineUsers(onlineUsers)
            setInitialPrivateUsers(initialPrivateUsers)
          },
        )
      })
    }

    if (!isAuthenticated) {
      socket.emit('subscribe-public', {}, (error, onlineUsers) => {
        if (error) {
          ApiErrors.handle(error)
          return
        }

        console.log('Socket connected')
        refreshOnlineUsers(onlineUsers)
        setInitialPrivateUsers([])
      })

      socket.io.on('reconnect', () => {
        console.log('reconnected')
        socket.emit('subscribe-public', {}, (error, onlineUsers) => {
          if (error) {
            ApiErrors.handle(error)
            return
          }

          refreshOnlineUsers(onlineUsers)
          setInitialPrivateUsers([])
        })
      })
    }

    socket.on('onlineusersadd', (onlineUser) => {
      const _onlineUsers = uniqBy(
        [onlineUser, ...onlineUsersRef.current],
        OnlineUserProperies.publicAddress,
      )
      refreshOnlineUsers(_onlineUsers)
    })

    socket.on('onlineusersremove', (userRemoved) => {
      if (userRemoved) {
        const _onlineUsers = onlineUsersRef.current.filter(
          (ou) =>
            ou[OnlineUserProperies.id] !== userRemoved[OnlineUserProperies.id],
        )
        refreshOnlineUsers(_onlineUsers)
      }
    })

    socket.on('banned', (address) => {
      if (isAddressEqual(address, account)) {
        AuthToken.clear()
      }
    })

    return () => {
      socket && socket.disconnect()
    }
  }, [isSignedIn, account])

  return { socket, onlineUsers, initialPrivateUsers }
}
