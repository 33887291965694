import { createSelector } from 'reselect'

const selectRaw = (state) => state.auth

const selectModalOpen = createSelector([selectRaw], (raw) => raw.modalOpen)
const selectProfile = createSelector([selectRaw], (raw) => raw.profile)

const authSelectors = {
  selectRaw,
  selectModalOpen,
  selectProfile,
}

export default authSelectors
