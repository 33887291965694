import { toast } from 'react-toastify'
export default class Messages {
  static success(title) {
    toast.success(title, { theme: 'dark' })
  }

  static info(title, onClick?) {
    toast.info(title, { theme: 'dark', onClick })
  }

  static error(title, toastId?: string) {
    const options: any = { theme: 'dark' }

    if (toastId) {
      options.toastId = toastId
    }

    toast.error(title, options)
  }
}
