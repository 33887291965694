export default function chatDuelLinkReplacer(text) {
  let transformedText = text

  if (transformedText) {
    transformedText = transformedText.replace(
      `${window.location.origin}/duel/`,
      '@@ADD_SPACE_HERE@@#',
    )
    transformedText = transformedText.replace(
      ` @@ADD_SPACE_HERE@@`,
      '@@ADD_SPACE_HERE@@',
    )
    transformedText = transformedText.replace(`@@ADD_SPACE_HERE@@`, ' ')
  }

  return transformedText
}
