import { ethers } from 'ethers'
import { sortBy } from 'lodash'
import moment from 'moment'
import Web3 from 'web3'
import EscrowContractAbi from '../../contracts/EscrowContractAbi'
import isAddressEqual from '../../lib/isAddressEqual'
import providerUrlForRetry from '../../lib/providerUrlForRetry'
import { sleep } from '../../lib/sleep'
import { ConfigStore } from '../config/configStore'

export interface BalanceOperation {
  tokenAddress: string
  amount: string
  time: Date
  token: 'gold' | 'jewel'
  operation: 'deposit' | 'withdraw'
}

export default class BalanceService {
  static async jewelAmountForDuel(heroLevel) {
    const protocolFee = await this.fetchProtocolFee(0)
    return ethers.BigNumber.from(protocolFee).mul(
      ethers.BigNumber.from(heroLevel),
    )
  }

  static async isJewelAmountEnoughForDuel(currentJewelBalance, heroLevel) {
    const protocolFee = await this.fetchProtocolFee(0)
    return ethers.BigNumber.from(currentJewelBalance).gte(
      ethers.BigNumber.from(protocolFee).mul(ethers.BigNumber.from(heroLevel)),
    )
  }

  static async depositGold(account, amount) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)
    const contract = new web3Instance.eth.Contract(
      EscrowContractAbi,
      ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
    )

    await contract.methods.deposit_gold(String(amount)).send({ from: account })
  }

  static async depositJewel(account, amount) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)
    const contract = new web3Instance.eth.Contract(
      EscrowContractAbi,
      ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
    )

    await contract.methods.deposit_jewel(String(amount)).send({ from: account })
  }

  static async withdrawGold(account, amount) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)
    const contract = new web3Instance.eth.Contract(
      EscrowContractAbi,
      ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
    )

    await contract.methods.withdraw_gold(String(amount)).send({ from: account })
  }

  static async withdrawJewel(account, amount) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)
    const contract = new web3Instance.eth.Contract(
      EscrowContractAbi,
      ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
    )

    await contract.methods
      .withdraw_jewel(String(amount))
      .send({ from: account })
  }

  static async fetchProtocolFee(retry: number) {
    try {
      retry = Number(retry) || 0

      const web3Instance = new Web3(
        new Web3.providers.HttpProvider(providerUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(
        EscrowContractAbi,
        ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
      )

      const protocolFee = await contract.methods.protocol_fee().call()
      return protocolFee
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchProtocolFee(retry + 1)
      }

      throw error
    }
  }

  static async fetchGoldTaxRate(retry: number) {
    try {
      retry = Number(retry) || 0

      const web3Instance = new Web3(
        new Web3.providers.HttpProvider(providerUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(
        EscrowContractAbi,
        ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
      )

      const goldTaxRate = await contract.methods.gold_tax_rate().call()
      return goldTaxRate
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchGoldTaxRate(retry + 1)
      }

      throw error
    }
  }

  static async fetchGoldBalance(account, retry: number) {
    try {
      retry = Number(retry) || 0

      const web3Instance = new Web3(
        new Web3.providers.HttpProvider(providerUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(
        EscrowContractAbi,
        ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
      )

      const goldBalance = await contract.methods.gold_balance(account).call()
      return goldBalance
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchGoldBalance(account, retry + 1)
      }

      throw error
    }
  }

  static async fetchJewelBalance(account, retry: number) {
    try {
      retry = Number(retry) || 0
      const web3Instance = new Web3(
        new Web3.providers.HttpProvider(providerUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(
        EscrowContractAbi,
        ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
      )

      const jewelBalance = await contract.methods.jewel_balance(account).call()
      return jewelBalance
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchJewelBalance(account, retry + 1)
      }

      throw error
    }
  }

  static async fetchDepositHistory(
    account,
    retry: number,
  ): Promise<BalanceOperation[]> {
    try {
      retry = Number(retry) || 0
      const web3Instance = new Web3(
        new Web3.providers.HttpProvider(providerUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(
        EscrowContractAbi,
        ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
      )

      const depositHistory = await contract.methods
        .get_deposit_history(account)
        .call()

      return sortBy(
        depositHistory.map((operation) => {
          return {
            amount: operation.amount,
            time: moment.unix(operation.time),
            token: isAddressEqual(
              operation.tokenAddress,
              ConfigStore.get().GOLD_COIN_ADDRESS,
            )
              ? 'gold'
              : 'jewel',
            operation: 'deposit',
          }
        }),
        'time',
      ).reverse()
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchDepositHistory(account, retry + 1)
      }

      throw error
    }
  }

  static async fetchWithdrawHistory(
    account,
    retry: number,
  ): Promise<BalanceOperation[]> {
    try {
      retry = Number(retry) || 0
      const web3Instance = new Web3(
        new Web3.providers.HttpProvider(providerUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(
        EscrowContractAbi,
        ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
      )

      const withdrawHistory = await contract.methods
        .get_withdrawal_history(account)
        .call()

      return sortBy(
        withdrawHistory.map((operation) => {
          return {
            amount: operation.amount,
            time: moment.unix(operation.time),
            token: isAddressEqual(
              operation.tokenAddress,
              ConfigStore.get().GOLD_COIN_ADDRESS,
            )
              ? 'gold'
              : 'jewel',
            operation: 'withdraw',
          }
        }),
        'time',
      ).reverse()
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchWithdrawHistory(account, retry + 1)
      }

      throw error
    }
  }
}
