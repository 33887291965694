import { AudioState } from './audioState'

const PREFIX = 'AUDIO'

const audioActions = {
  MUSIC_MUTE: `${PREFIX}_MUSIC_MUTE`,
  EFFECT_MUTE: `${PREFIX}_EFFECT_MUTE`,
  MUSIC_UNMUTE: `${PREFIX}_MUSIC_UNMUTE`,
  EFFECT_UNMUTE: `${PREFIX}_EFFECT_UNMUTE`,
  BATTLE_SOUNDS: `${PREFIX}_BATTLE_SOUNDS`,
  END_FIGHT: `${PREFIX}_END_FIGHT`,
  RESET: `${PREFIX}_RESET`,

  doMusicMute: () => {
    return {
      type: audioActions.MUSIC_MUTE,
    }
  },

  doMusicUnmute: () => {
    return {
      type: audioActions.MUSIC_UNMUTE,
    }
  },

  doEffectMute: () => {
    return {
      type: audioActions.EFFECT_MUTE,
    }
  },

  doEffectUnmute: () => {
    return {
      type: audioActions.EFFECT_UNMUTE,
    }
  },

  doEndFight: (loserAddress) => {
    return {
      type: audioActions.END_FIGHT,
      payload: loserAddress,
    }
  },

  doBattleSounds: (player, action: 'hurt' | 'evasion' = 'evasion') => {
    return {
      type: audioActions.BATTLE_SOUNDS,
      payload: { player, action },
    }
  },

  doReset: () => {
    return {
      type: audioActions.RESET,
    }
  },
}

export default audioActions
