import axios from 'axios'

export default class RankingService {
  static async fetch(order, direction, page, pageSize) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/battle/rank`,
      {
        params: {
          order: order,
          orderDirection: direction,
          page: page,
          pageSize: pageSize,
        },
      },
    )

    return { rows: data, count: data.length }
  }
}