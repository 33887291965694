const PREFIX = 'PROMO_CODE'

export const promoCodeActions = {
  MODAL_OPENED: `${PREFIX}_MODAL_OPENED`,
  CLOSE_MODAL: `${PREFIX}_MODAL_CLOSED`,

  doOpenModal: (userAddress) => {
    return {
      type: promoCodeActions.MODAL_OPENED,
      payload: userAddress,
    }
  },

  doCloseModal: () => {
    return {
      type: promoCodeActions.CLOSE_MODAL,
    }
  },
}

export default promoCodeActions