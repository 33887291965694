import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import moment from 'moment'
import Image from 'next/image'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAuthSignIn from '../hooks/useAuthSignIn'
import Messages from '../lib/messages'
import { switchToHarmonyNetwork } from '../lib/switchToHarmonyNetwork'
import authActions from '../modules/auth/authActions'
import authSelectors from '../modules/auth/authSelectors'
import { AuthToken } from '../modules/auth/authToken'
import promoCodeActions from '../modules/promoCodeModal/promoCodeActions'
import { UserProfileService } from '../modules/users/userProfileService'
import { AuthModal } from './authModal'
import { AuthNotDfkUser } from './authNotDfkUser'
import PersistentToast from './PersistentToast'

export default function AuthScreen(props) {
  const { active, account, error, setError, deactivate } = useWeb3React()
  const { doSignIn, isSignedIn, loadingSignIn } = useAuthSignIn(account)
  const isAskedToSwitchNetwork = useRef(false)
  const isSignInRequested = useRef(false)
  const dispatch = useDispatch()

  const [notDfkUserModalOpen, setNotDfkUserModalOpen] = useState(false)
  const profile = useSelector(authSelectors.selectProfile)

  useEffect(() => {
    if (profile?.notRegistered) {
      setNotDfkUserModalOpen(true)
    }
  }, [profile])

  useEffect(() => {
    if (!account || !isSignedIn) {
      dispatch(authActions.doChangeProfile(null))
      return
    }

    UserProfileService.findProfileByAddress(account)
      .then((profile) => {
        if (profile.bannedAt) {
          deactivate()
          AuthToken.clear()
          Messages.error('You are banned from DFK Arena.')
          return
        }

        const isNew = moment(profile.createdAt).isAfter(
          moment().subtract(1, 'minute'),
        )
        if (isNew) {
          dispatch(promoCodeActions.doOpenModal(account))
        }
        dispatch(authActions.doChangeProfile(profile))
      })
      .catch((error) => {
        console.error(error)
        dispatch(authActions.doChangeProfile(null))
      })
  }, [account, isSignedIn])

  useEffect(() => {
    if (
      !isAskedToSwitchNetwork.current &&
      error &&
      error instanceof UnsupportedChainIdError
    ) {
      isAskedToSwitchNetwork.current = true
      switchToHarmonyNetwork(setError)
    }
  }, [error, setError])

  useEffect(() => {
    isAskedToSwitchNetwork.current = false
  }, [account])

  useEffect(() => {
    if (!account) {
      return
    }

    if (isSignInRequested.current) {
      return
    }

    if (!AuthToken.isSignedIn(account)) {
      doSignIn()
    }

    isSignInRequested.current = true
  }, [account, isSignedIn, isSignInRequested])

  function doOpenSignIn() {
    isSignInRequested.current = false
    doSignIn()
  }

  if (!props.triedToEagerConnect) {
    return null
  }

  if (isSignedIn && profile && !profile?.notRegistered) {
    return null
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 36,
      }}
    >
      <Image alt="Logo" src={'/logo.svg'} width={296 * 4} height={62 * 4} />
      <button
        style={{ width: 296, color: 'white' }}
        className="btn btn--large btn--green"
        disabled={loadingSignIn}
        onClick={() =>
          profile?.notRegistered ? setNotDfkUserModalOpen(true) : doOpenSignIn()
        }
      >
        Play
      </button>

      <AuthModal />
      <PersistentToast />
      <AuthNotDfkUser
        isOpen={notDfkUserModalOpen}
        onClose={() => setNotDfkUserModalOpen(false)}
      />
    </div>
  )
}
