import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWeb3React } from '@web3-react/core'
import { sortBy } from 'lodash'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import OnlineUserProperies from '../../enumerators/onlineUserProperties'
import isAddressEqual from '../../lib/isAddressEqual'
import isAdmin from '../../lib/isAdmin'
import isModerator from '../../lib/isModerator'
import userModalActions from '../../modules/users/userModalActions'
import styles from '../../styles/Chat.module.scss'
import { shortenHex } from '../../util'
import ProfileAvatar from '../ProfileAvatar'

interface ChatUsersOnlineProps {
  open: boolean
  onlineUsers: Array<any>
  onClose: any
  setUserPrivate: any
}

function mapOnlineUser(userLowPayload) {
  return {
    id: userLowPayload[OnlineUserProperies.id],
    name: userLowPayload[OnlineUserProperies.name],
    publicAddress: userLowPayload[OnlineUserProperies.publicAddress],
  }
}

export default function ChatUsersOnline(props: ChatUsersOnlineProps) {
  const { account } = useWeb3React()
  const { open, onClose, onlineUsers } = props
  const [maxUserVisible, setMaxUserVisible] = React.useState(20)
  const dispatch = useDispatch()

  const admins = onlineUsers.filter((user) =>
    isAdmin(user[OnlineUserProperies.publicAddress]),
  )
  const moderators = onlineUsers.filter((user) =>
    isModerator(user[OnlineUserProperies.publicAddress]),
  )
  const users = sortBy(
    onlineUsers.filter(
      (user) =>
        !isAdmin(user[OnlineUserProperies.publicAddress]) &&
        !isModerator(user[OnlineUserProperies.publicAddress]),
    ),
    [OnlineUserProperies.addedAt],
  ).reverse()

  function doOpenUserModal(userAddress) {
    dispatch(userModalActions.doOpenModal(userAddress))
  }

  const visibleUsers = useMemo(() => {
    return users.slice(0, maxUserVisible)
  }, [onlineUsers, maxUserVisible])

  return (
    <div className={styles.users} style={{ display: open ? 'flex' : 'none' }}>
      <div className={styles.usersHeading}>
        <div className={styles.usersTitle}>users online</div>
        <button
          className={`btn ${styles.usersClose}`}
          onClick={onClose}
          style={{ color: 'white', fontSize: 24 }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div className={styles.usersScroller}>
        {admins && Boolean(admins.length) && (
          <div className={`${styles.usersList} ${styles.isAdmins}`}>
            <div className={styles.usersListHeading}>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49995 7.91667H1.49984C0.948332 7.91667 0.499825 8.29042 0.499825 8.75V9.16667C0.499825 9.62625 0.948332 10 1.49984 10H8.49995C9.05146 10 9.49997 9.62625 9.49997 9.16667V8.75C9.49997 8.29084 9.05146 7.91667 8.49995 7.91667Z"
                  fill="#FFAFAF"
                />
                <path
                  d="M9.81295 3.00835C9.66045 2.90668 9.45095 2.88793 9.27644 2.96084L6.6904 4.03834L5.42888 2.28585C5.24838 2.03501 4.75189 2.03501 4.57138 2.28585L3.30986 4.03834L0.723817 2.96084C0.549315 2.88793 0.339312 2.90668 0.18731 3.00835C0.0348073 3.11043 -0.0322096 3.27667 0.0147911 3.43417L1.01481 6.76749C1.07031 6.95291 1.27033 7.08335 1.49983 7.08335H8.49994C8.72945 7.08335 8.92943 6.95333 8.98493 6.76749L9.98495 3.43417C10.0325 3.27667 9.96545 3.11043 9.81295 3.00835Z"
                  fill="#FFAFAF"
                />
                <path
                  d="M4.9999 1.66666C5.55219 1.66666 5.99991 1.29357 5.99991 0.833331C5.99991 0.373095 5.55219 0 4.9999 0C4.4476 0 3.99988 0.373095 3.99988 0.833331C3.99988 1.29357 4.4476 1.66666 4.9999 1.66666Z"
                  fill="#FFAFAF"
                />
              </svg>{' '}
              admins
            </div>
            {admins.map(mapOnlineUser).map((admin) => (
              <div key={admin.id} className={styles.usersListItem}>
                <span
                  onClick={() => {
                    doOpenUserModal(admin.publicAddress)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <ProfileAvatar
                    className={`${styles.messageAvatar} ${styles.usersListItemAvatar}`}
                    showOnlineStatus
                    lazyLoad
                    profile={admin}
                    size={'small'}
                  />
                </span>
                <div
                  className={styles.usersListItemName}
                  onClick={() => {
                    doOpenUserModal(admin.publicAddress)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {admin.name || shortenHex(admin.publicAddress)}
                </div>
                {!isAddressEqual(admin.publicAddress, account) && (
                  <button
                    aria-label="Open chat"
                    className={`btn-with-background ${styles.usersListItemBtn}`}
                    onClick={() => {
                      props.setUserPrivate(admin)
                      onClose()
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {moderators && Boolean(moderators.length) && (
          <div className={`${styles.usersList} ${styles.isModerators}`}>
            <div className={styles.usersListHeading}>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.49065 1.64663L1.20627 5.93265L4.05331 8.77917L8.3377 4.49315L5.49065 1.64663Z"
                  fill="#AFE2FF"
                />
                <path
                  d="M9.05155 3.79504L9.41158 3.43548C10.1961 2.65084 10.1961 1.37362 9.41158 0.588478C8.62702 -0.196159 7.34943 -0.196159 6.56487 0.588478L6.20535 0.948541L9.05155 3.79504Z"
                  fill="#AFE2FF"
                />
                <path
                  d="M3.19963 9.34101L0.621431 9.98512C0.450918 10.0276 0.270398 9.97762 0.146389 9.8536C0.0223796 9.72958 -0.0276244 9.54904 0.0148788 9.37901L0.658924 6.80057L3.19963 9.34101Z"
                  fill="#AFE2FF"
                />
              </svg>{' '}
              moderators
            </div>
            {moderators.map(mapOnlineUser).map((moderator) => (
              <div key={moderator.id} className={styles.usersListItem}>
                <span
                  onClick={() => {
                    doOpenUserModal(moderator.publicAddress)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <ProfileAvatar
                    className={`${styles.messageAvatar} ${styles.usersListItemAvatar}`}
                    showOnlineStatus
                    lazyLoad
                    profile={moderator}
                    size={'small'}
                  />
                </span>
                <div
                  className={styles.usersListItemName}
                  onClick={() => {
                    doOpenUserModal(moderator.publicAddress)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {moderator.name || shortenHex(moderator.publicAddress)}
                </div>
                {!isAddressEqual(moderator.publicAddress, account) && (
                  <button
                    aria-label="Open chat"
                    className={`btn-with-background ${styles.usersListItemBtn}`}
                    onClick={() => {
                      props.setUserPrivate(moderator)
                      onClose()
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {visibleUsers && Boolean(visibleUsers.length) && (
          <div className={styles.usersList}>
            <div className={styles.usersListHeading}>users</div>
            {visibleUsers.map(mapOnlineUser).map((user) => (
              <div key={user.id} className={styles.usersListItem}>
                <span
                  onClick={() => {
                    doOpenUserModal(user.publicAddress)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <ProfileAvatar
                    className={`${styles.messageAvatar} ${styles.usersListItemAvatar}`}
                    showOnlineStatus
                    lazyLoad
                    profile={user}
                    size={'small'}
                  />
                </span>
                <div
                  className={styles.usersListItemName}
                  onClick={() => {
                    doOpenUserModal(user.publicAddress)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {user.name || shortenHex(user.publicAddress)}
                </div>
                {!isAddressEqual(user.publicAddress, account) && (
                  <button
                    aria-label="Open chat"
                    className={`btn-with-background ${styles.usersListItemBtn}`}
                    onClick={() => {
                      props.setUserPrivate(user)
                      onClose()
                    }}
                  />
                )}
              </div>
            ))}

            {visibleUsers.length < users.length && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 8,
                }}
              >
                <button
                  onClick={() => setMaxUserVisible(maxUserVisible + 20)}
                  className="btn btn--small btn--dark"
                  style={{ paddingLeft: 8, paddingRight: 8 }}
                >
                  Show More
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
