import React from 'react' // useState
import styles from '../styles.module.css'

const MaleLeftFootHighlight = '/svgs/male/LeftFoot-highlight.svg'
const MaleLeftFootShadow = '/svgs/male/LeftFoot-shadow.svg'
const MaleRightFootHighlight = '/svgs/male/RightFoot-highlight.svg'
const MaleRightFootShadow = '/svgs/male/RightFoot-shadow.svg'

const archerLeftFoot = '/svgs/male/clothes/archer-LeftFoot.svg'
const archerRightFoot = '/svgs/male/clothes/archer-RightFoot.svg'

const knightLeftFoot = '/svgs/male/clothes/knight-LeftFoot.svg'
const knightRightFoot = '/svgs/male/clothes/knight-RightFoot.svg'

const monkLeftFoot = '/svgs/male/clothes/monk-LeftFoot.svg'
const monkRightFoot = '/svgs/male/clothes/monk-RightFoot.svg'

const pirateLeftFoot = '/svgs/male/clothes/pirate-LeftFoot.svg'
const pirateRightFoot = '/svgs/male/clothes/pirate-RightFoot.svg'

const priestLeftFoot = '/svgs/male/clothes/priest-LeftFoot.svg'
const priestRightFoot = '/svgs/male/clothes/priest-RightFoot.svg'

const thiefLeftFoot = '/svgs/male/clothes/thief-LeftFoot.svg'
const thiefRightFoot = '/svgs/male/clothes/thief-RightFoot.svg'

const warriorLeftFoot = '/svgs/male/clothes/warrior-LeftFoot.svg'
const warriorRightFoot = '/svgs/male/clothes/warrior-RightFoot.svg'

const wizardLeftFoot = '/svgs/male/clothes/wizard-LeftFoot.svg'
const wizardRightFoot = '/svgs/male/clothes/wizard-RightFoot.svg'

/* ADVANCED HEROES */
const paladinLeftFoot = '/svgs/male/clothes/advanced/paladin-LeftFoot.svg'
const paladinRightFoot = '/svgs/male/clothes/advanced/paladin-RightFoot.svg'
const darkKnightLeftFoot = '/svgs/male/clothes/advanced/darkKnight-LeftFoot.svg'
const darkKnightRightFoot =
  '/svgs/male/clothes/advanced/darkKnight-RightFoot.svg'
const summonerLeftFoot = '/svgs/male/clothes/advanced/summoner-LeftFoot.svg'
const summonerRightFoot = '/svgs/male/clothes/advanced/summoner-RightFoot.svg'
const ninjaLeftFoot = '/svgs/male/clothes/advanced/ninja-LeftFoot.svg'
const ninjaRightFoot = '/svgs/male/clothes/advanced/ninja-RightFoot.svg'

/* ELITE HEROES */
const dragoonLeftFoot = '/svgs/male/clothes/elite/dragoon-LeftFoot.svg'
const dragoonRightFoot = '/svgs/male/clothes/elite/dragoon-RightFoot.svg'
const sageLeftFoot = '/svgs/male/clothes/elite/sage-LeftFoot.svg'
const sageRightFoot = '/svgs/male/clothes/elite/sage-RightFoot.svg'

/* LEGENDARY HEROES */
const dreadKnightLeftFoot =
  '/svgs/male/clothes/legendary/dreadknight-LeftFoot.svg'
const dreadKnightRightFoot =
  '/svgs/male/clothes/legendary/dreadknight-RightFoot.svg'

const getClassInfo = (mainClass) => {
  switch (mainClass) {
    case 'archer': {
      return {
        leftFoot: archerLeftFoot,
        rightFoot: archerRightFoot,
      }
    }
    case 'knight': {
      return {
        leftFoot: knightLeftFoot,
        rightFoot: knightRightFoot,
      }
    }
    case 'monk': {
      return {
        leftFoot: monkLeftFoot,
        rightFoot: monkRightFoot,
      }
    }
    case 'pirate': {
      return {
        leftFoot: pirateLeftFoot,
        rightFoot: pirateRightFoot,
      }
    }
    case 'priest': {
      return {
        leftFoot: priestLeftFoot,
        rightFoot: priestRightFoot,
      }
    }
    case 'thief': {
      return {
        leftFoot: thiefLeftFoot,
        rightFoot: thiefRightFoot,
      }
    }
    case 'warrior': {
      return {
        leftFoot: warriorLeftFoot,
        rightFoot: warriorRightFoot,
      }
    }
    case 'wizard': {
      return {
        leftFoot: wizardLeftFoot,
        rightFoot: wizardRightFoot,
      }
    }
    case 'paladin': {
      return {
        leftFoot: paladinLeftFoot,
        rightFoot: paladinRightFoot,
      }
    }
    case 'darkKnight': {
      return {
        leftFoot: darkKnightLeftFoot,
        rightFoot: darkKnightRightFoot,
      }
    }
    case 'summoner': {
      return {
        leftFoot: summonerLeftFoot,
        rightFoot: summonerRightFoot,
      }
    }
    case 'ninja': {
      return {
        leftFoot: ninjaLeftFoot,
        rightFoot: ninjaRightFoot,
      }
    }
    case 'dragoon': {
      return {
        leftFoot: dragoonLeftFoot,
        rightFoot: dragoonRightFoot,
      }
    }
    case 'sage': {
      return {
        leftFoot: sageLeftFoot,
        rightFoot: sageRightFoot,
      }
    }
    case 'dreadKnight': {
      return {
        leftFoot: dreadKnightLeftFoot,
        rightFoot: dreadKnightRightFoot,
      }
    }
    default: {
      return {}
    }
  }
}

const LeftFootMale = ({ leftFoot, stroke }) => (
  <React.Fragment>
    <img src={leftFoot} className={styles.clothing} />
    <img src={MaleLeftFootHighlight} className={styles.highlight} />
    <img src={MaleLeftFootShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path stroke={stroke} d="M19 43h4M19 44h5" />
    </svg>
  </React.Fragment>
)

const RightFootMale = ({ rightFoot, stroke }) => (
  <React.Fragment>
    <img src={rightFoot} className={styles.clothing} />
    <img src={MaleRightFootHighlight} className={styles.highlight} />
    <img src={MaleRightFootShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path stroke={stroke} d="M11 43h4M10 44h5" />
    </svg>
  </React.Fragment>
)

const MaleFeet = ({ mainClass, stroke }) => {
  const classInfo = getClassInfo(mainClass)
  return (
    <>
      <div className={`${styles.heroFootRight} ${styles.bodyPart}`}>
        <RightFootMale rightFoot={classInfo.rightFoot} stroke={stroke} />
      </div>
      <div className={`${styles.heroFootLeft} ${styles.bodyPart}`}>
        <LeftFootMale leftFoot={classInfo.leftFoot} stroke={stroke} />
      </div>
    </>
  )
}

export default MaleFeet
