import completedGamesActions from './completedGamesActions'

const initialData = {
  state: 'idle',
  rows: [],
  count: 0,
  renderedPage: 0,
  itemsPerPage: 10,
  fetchId: null,
  accountAddress: null,
  watchedGames: {},
  openGamesWarned: {},
  openGameToWarn: null,
}

const completedGamesReducers = (state = initialData, { type, payload }) => {
  if (type === completedGamesActions.GAME_WATCHED) {
    return {
      ...state,
      watchedGames: { ...state.watchedGames, [payload.pairId]: true },
    }
  }

  if (type === completedGamesActions.OPEN_GAMES_WARNED) {
    return {
      ...state,
      openGamesWarned: { ...state.openGamesWarned, [payload.pairId]: true },
    }
  }

  if (type === completedGamesActions.OPEN_GAME_TO_WARN) {
    return {
      ...state,
      openGameToWarn: payload,
    }
  }

  if (type === completedGamesActions.FETCH_STARTED) {
    return {
      ...state,
      state: payload.hideLoading ? state.state : 'loading',
      fetchId: payload.fetchId,
      renderedPage: payload.renderedPage,
      itemsPerPage: payload.itemsPerPage,
      accountAddress: payload.accountAddress,
    }
  }

  if (type === completedGamesActions.FETCH_SUCCESS) {
    return {
      ...state,
      state: 'idle',
      rows: payload.rows,
      count: payload.count,
    }
  }

  if (type === completedGamesActions.FETCH_ERROR) {
    return {
      ...state,
      state: 'error',
    }
  }

  return state
}

export default completedGamesReducers
