import { createSelector } from 'reselect'

const selectRaw = (state) => state.chat

const selectState = createSelector([selectRaw], (raw) => raw.state)
const selectOnlineUsers = createSelector(
  [selectRaw],
  (raw) => raw.onlineUsers || {},
)

const chatSelectors = {
  selectState,
  selectRaw,
  selectOnlineUsers,
}

export default chatSelectors
