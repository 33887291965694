export const availableClasses = {
  warrior: 0,
  knight: 1,
  thief: 2,
  archer: 3,
  priest: 4,
  wizard: 5,
  monk: 6,
  pirate: 7,
  paladin: 16,
  darkKnight: 17,
  summoner: 18,
  ninja: 19,
  dragoon: 24,
  sage: 25,
  dreadKnight: 28,
} as const

export const notAvailableClasses = {
  berserker: 8,
  seer: 9,
  shapeshifter: 20,
} as const

export const characterClasses = {
  ...availableClasses,
  ...notAvailableClasses,
} as const
