import Messages from '../../lib/messages'
import { captureException } from '@sentry/nextjs'

const mappedMessages = [
  'Can only abandon if no opponent has been found',
  'Mana Not Full',
  'Not enough gold',
  'Not enough to cover protocol fee',
  'Not enough jewel',
  'Match Expired',
  'No permission to abandon',
  'Can only call if 2 players have been paired',
  'Cannot claim rewards',
  'No permission',
  'Hero Currently on Sale',
  'Not enough to cover protocol fee',
  'Not enough jewel',
  'No Waiting Opponent',
  'Hero Currently on Sale',
  'You Cannot Battle Your Own Hero',
  'Unequal Amount of Gold Wagered',
  'Unequal Amount of Jewel Wagered',
  'Both Hero Identical Stats',
  'Not enough gold',
  'Not enough to cover protocol fee',
  'Not enough jewel',
  'Can only call if 2 players have been paired',
  'No permission to confirm',
  'Fight already in progress',
  'Still in Cooldown',
]

export class Web3Errors {
  static handle(error, genericNetworkErrorMessage?: boolean) {
    console.error(error)
    captureException(error)

    const message = error.message
    let formattedMessage = genericNetworkErrorMessage
      ? 'Network error. Please try again later.'
      : 'Oops, something went wrong. Please try again later.'

    const matchedMessage = mappedMessages.find((mappedMessage) =>
      message.includes(mappedMessage),
    )
    if (matchedMessage) {
      formattedMessage = matchedMessage
    }

    Messages.error(
      formattedMessage,
      genericNetworkErrorMessage ? 'genericNetworkErrorMessage' : undefined,
    )
  }
}
