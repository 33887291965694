import axios from 'axios'
import { ConfigStore } from './configStore'

export default class ConfigService {
  static async fetchAndStore() {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/config`,
    )

    ConfigStore.set(data)
  }
}
