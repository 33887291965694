import Image from 'next/image'
import React from 'react'
import Modal from 'react-modal'
import styles from '../styles/Modals.module.scss'

export function AuthNotDfkUser(props) {
  const { isOpen, onClose } = props

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen}
        contentLabel="Not registered yet"
        className={`dfkModal ${styles.authModal}`}
        onRequestClose={onClose}
        closeTimeoutMS={200}
      >
        <Image alt="DFK" src={'/logo.svg'} width={296} height={62} />
        <div className={styles.authModalText}>
          You're not a DFK user. <br />
          Please go to DefiKingdoms and register your account.
        </div>
        <a
          href="https://game.defikingdoms.com/"
          className={`btn btn--large btn--blue ${styles.authModalBtn}`}
        >
          Register on DefiKingdoms
        </a>
      </Modal>
    </>
  )
}
