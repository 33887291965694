import providerUrlForRetry from './providerUrlForRetry'

export async function switchToHarmonyNetwork(setError) {
  try {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId:
            '0x' +
            Number(process.env.NEXT_PUBLIC_CHAIN_IDS.split(',')[0]).toString(
              16,
            ),
        },
      ],
    })

    setError(null)
  } catch (switchError) {
    if (switchError.code === 4902) {
      // @ts-ignore
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId:
              '0x' +
              Number(process.env.NEXT_PUBLIC_CHAIN_IDS.split(',')[0]).toString(
                16,
              ),
            chainName: process.env.NEXT_PUBLIC_CHAIN_NAME,
            nativeCurrency: { name: 'ONE', symbol: 'ONE', decimals: 18 },
            rpcUrls: [providerUrlForRetry(0)],
            blockExplorerUrls: [process.env.NEXT_PUBLIC_CHAIN_EXPLORER_URL],
          },
        ],
      })

      setError(null)
    }
  }
}
