import { createSelector } from 'reselect'

const selectRaw = (state) => state.promoCode
const selectUserAddress = createSelector([selectRaw], (raw) => raw.userAddress)

const promoCodeSelectors = {
  selectRaw,
  selectUserAddress,
}

export default promoCodeSelectors