import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import useAuthSignIn from '../hooks/useAuthSignIn'
import useEagerConnect from '../hooks/useEagerConnect'
import authSelectors from '../modules/auth/authSelectors'
import Chat from './chat/chat'
import Header from './header'
import AuthScreen from './authScreen'
import { BetaDisclaimerModal } from './betaDisclaimerModal'
import DuelPostGameForgottenModal from './duel/DuelPostGameForgottenModal'
import OlderVersionsBanner from './olderVersions/olderVersionsBanner'

export default function Layout({ children }: any) {
  const { account } = useWeb3React()
  const { isSignedIn } = useAuthSignIn(account)
  const { tried: triedToEagerConnect, loading: loadingEagerConnect } =
    useEagerConnect()
  const profile = useSelector(authSelectors.selectProfile)

  if (loadingEagerConnect) {
    return null
  }

  if (!isSignedIn || !profile || profile.notRegistered) {
    return <AuthScreen triedToEagerConnect={triedToEagerConnect} />
  }

  return (
    <div className="wrapper">
      <Header />
      <Chat />
      <main className="wrapper-main">{children}</main>
      <BetaDisclaimerModal />
      <DuelPostGameForgottenModal />
      <OlderVersionsBanner />
    </div>
  )
}
