import React from 'react'
import { useDispatch } from 'react-redux'
import userModalActions from '../../modules/users/userModalActions'
import styles from '../../styles/Chat.module.scss'
import { shortenHex } from '../../util'
import ProfileAvatar from '../ProfileAvatar'

interface ChatUserProps {
  user: any
  actionButtons?: any
}

export default function ChatUser(props: ChatUserProps) {
  const dispatch = useDispatch()
  const { user } = props

  const chatBadges = user.chatBadges || []

  function doOpenUserModal() {
    dispatch(userModalActions.doOpenModal(user.publicAddress))
  }

  return (
    <div className={styles.chatUser}>
      <div className={styles.chatUserContent}>
        <span onClick={doOpenUserModal}>
          <ProfileAvatar
            showOnlineStatus
            lazyLoad
            profile={user}
            size={'small'}
            className={styles.messageAvatar}
          />
        </span>
        <div className={styles.chatUserName}>
          <div className={styles.messageTop}>
            <span onClick={doOpenUserModal} className={styles.messageUsername}>
              {user.name || shortenHex(user.publicAddress)}
              <span className={styles.badges}>
                {chatBadges.map((badge) => {
                  return (
                    <img
                      key={badge.id}
                      alt="icon"
                      src={badge.imageUrl}
                      width={10}
                      height={10}
                      data-tip={`<span>${badge.label}</span>`}
                      data-html={true}
                    />
                  )
                })}
              </span>
            </span>
          </div>
        </div>
      </div>
      {props.actionButtons}
    </div>
  )
}
