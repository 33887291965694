import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import authActions from '../modules/auth/authActions'
import AuthService from '../modules/auth/authService'
import { AuthToken } from '../modules/auth/authToken'
import persistentToastActions from '../modules/persistentToast/persistentToastActions'

export default function useAuthSignIn(account) {
  const dispatch = useDispatch()

  const [isSignedIn, setIsSignedIn] = useState(AuthToken.isSignedIn(account))

  const [loadingSignIn, setLoadingSignIn] = useState(false)

  useEffect(() => {
    let interval = setInterval(() => {
      setIsSignedIn(AuthToken.isSignedIn(account))
    }, 1000)

    return () => clearInterval(interval)
  }, [account])

  async function doSignIn() {
    if (loadingSignIn) {
      return
    }

    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    try {
      dispatch(
        persistentToastActions.doAddToast(
          'useAuthSignIn',
          'Waiting for sign-in approval...',
        ),
      )
      setLoadingSignIn(true)
      setIsSignedIn(await AuthService.signIn(account))
      setLoadingSignIn(false)
    } catch (error) {
      setIsSignedIn(false)
      setLoadingSignIn(false)
    } finally {
      dispatch(persistentToastActions.doRemoveAllToasts())
    }
  }

  return { doSignIn, isSignedIn, loadingSignIn }
}
