import { useWeb3React } from '@web3-react/core'
import Image from 'next/image'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import useAuthSignIn from '../../hooks/useAuthSignIn'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import usePlayerInfo from '../../hooks/usePlayerInfo'
import classNames from '../../lib/classNames'
import isAddressEqual from '../../lib/isAddressEqual'
import isAdmin from '../../lib/isAdmin'
import isModerator from '../../lib/isModerator'
import Messages from '../../lib/messages'
import AdminModeratorService from '../../modules/adminModerator/adminModeratorService'
import adminModeratorBanActions from '../../modules/adminModerator/ban/adminModeratorBanActions'
import adminModeratorMuteActions from '../../modules/adminModerator/mute/adminModeratorMuteActions'
import { ApiErrors } from '../../modules/error/ApiErrors'
import { Web3Errors } from '../../modules/error/Web3Errors'
import userModalActions from '../../modules/users/userModalActions'
import userModalSelectors from '../../modules/users/userModalSelectors'
import { UserProfileService } from '../../modules/users/userProfileService'
import styles from '../../styles/Modals.module.scss'
import { HeroesDropdown } from '../heroes/HeroesDropdown'
import ProfileAvatar from '../ProfileAvatar'
import Spinner from '../spinner'
import UserModalAdminBadges from './userModalAdminBadges'
import UserModalBadges from './userModalBadges'
import UserModalCurrentUserBadges from './userModalCurrentUserBadges'
import UserModalTip from './userModalTip'

export function UserModal() {
  const { account } = useWeb3React()

  const dispatch = useDispatch()
  const userAddress = useSelector(userModalSelectors.selectUserAddress)
  const { state, data, fetch } = usePlayerInfo(userAddress)

  const { loadingSignIn, doSignIn, isSignedIn } = useAuthSignIn(account)

  const [loadingBanUnban, setLoadingBanUnban] = useState(false)
  const [loadingMuteUnmute, setLoadingMuteUnmute] = useState(false)
  const [
    loadingDirectMentionsEnableDisable,
    setLoadingDirectMentionsEnabledDiDisable,
  ] = useState(false)

  const currentUserIsAdmin = isAdmin(account)
  const currentUserIsModerator = isModerator(account)

  function closeModal() {
    dispatch(userModalActions.doCloseModal())
  }

  useDisableBodyScroll(Boolean(userAddress))

  async function doDisableDirectMetions() {
    try {
      setLoadingDirectMentionsEnabledDiDisable(true)
      await AdminModeratorService.disableDirectMentions(account)
      fetch()
    } catch (error) {
      ApiErrors.handle(error)
    } finally {
      setLoadingDirectMentionsEnabledDiDisable(false)
    }
  }

  async function doEnableDirectMentions() {
    try {
      setLoadingDirectMentionsEnabledDiDisable(true)
      await AdminModeratorService.enableDirectMentions(account)
      fetch()
    } catch (error) {
      ApiErrors.handle(error)
    } finally {
      setLoadingDirectMentionsEnabledDiDisable(false)
    }
  }

  async function doBan() {
    if (isAddressEqual(data?.profile?.publicAddress, account)) {
      Messages.error(`You can't ban yourself!`)
      return
    }

    const confirmed = confirm(
      `Ban ${data?.profile?.name || data?.profile?.publicAddress}?`,
    )
    if (!confirmed) {
      return
    }

    try {
      setLoadingBanUnban(true)
      await AdminModeratorService.ban(account, data?.profile?.id)
      fetch()
      dispatch(adminModeratorBanActions.doRefresh(account))
      Messages.success(
        `${
          data?.profile?.name || data?.profile?.publicAddress
        } successfully banned.`,
      )
    } catch (error) {
      ApiErrors.handle(error)
    } finally {
      setLoadingBanUnban(false)
    }
  }

  async function doUnban() {
    const confirmed = confirm(
      `Unban ${data?.profile?.name || data?.profile?.publicAddress}?`,
    )
    if (!confirmed) {
      return
    }

    try {
      setLoadingBanUnban(true)
      await AdminModeratorService.unban(account, data?.profile?.id)
      Messages.success(
        `${
          data?.profile?.name || data?.profile?.publicAddress
        } successfully unbanned.`,
      )
      fetch()
      dispatch(adminModeratorBanActions.doRefresh(account))
    } catch (error) {
      ApiErrors.handle(error)
    } finally {
      setLoadingBanUnban(false)
    }
  }

  async function doMute() {
    const confirmed = confirm(
      `Mute ${data?.profile?.name || data?.profile?.publicAddress}?`,
    )
    if (!confirmed) {
      return
    }

    try {
      setLoadingMuteUnmute(true)
      await AdminModeratorService.mute(account, data?.profile?.id)
      Messages.success(
        `${
          data?.profile?.name || data?.profile?.publicAddress
        } successfully muted.`,
      )
      fetch()
      dispatch(adminModeratorMuteActions.doRefresh(account))
    } catch (error) {
      ApiErrors.handle(error)
    } finally {
      setLoadingMuteUnmute(false)
    }
  }

  async function doUnmute() {
    const confirmed = confirm(
      `Unmute ${data?.profile?.name || data?.profile?.publicAddress}?`,
    )
    if (!confirmed) {
      return
    }

    try {
      setLoadingMuteUnmute(true)
      await AdminModeratorService.unmute(account, data?.profile?.id)
      fetch()
      Messages.success(
        `${
          data?.profile?.name || data?.profile?.publicAddress
        } successfully unmuted.`,
      )
      dispatch(adminModeratorMuteActions.doRefresh(account))
    } catch (error) {
      ApiErrors.handle(error)
    } finally {
      setLoadingMuteUnmute(false)
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={Boolean(userAddress)}
        onRequestClose={closeModal}
        contentLabel="User Modal"
        className={`dfkModal ${styles.userModal}`}
        closeTimeoutMS={200}
      >
        {state === 'loading' && (
          <div style={{ height: 136 }}>
            <Spinner />
          </div>
        )}
        {state === 'success' && (
          <>
            {/* @ts-ignore */}
            <ReactTooltip
              className="dfkTooltip"
              effect="solid"
              id="userModal"
            />
            <ProfileAvatar
              className={styles.userModalAvatar}
              profile={data?.profile}
              size={'large'}
            />
            <div className={styles.userModalNickname}>
              {data?.profile?.name}
            </div>
            <div className={styles.userModalRole}>{data?.currentLevel}</div>
            {isAddressEqual(account, data?.profile?.publicAddress) && (
              <div className={styles.userModalCurrentUserActions}>
                {!data?.profile?.directMentionsDisabled && (
                  <button
                    onClick={doDisableDirectMetions}
                    disabled={loadingDirectMentionsEnableDisable}
                    className={classNames('btn', 'btn--small', 'btn--dark')}
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                  >
                    Disable Direct Mentions
                  </button>
                )}
                {data?.profile?.directMentionsDisabled && (
                  <button
                    onClick={doEnableDirectMentions}
                    disabled={loadingDirectMentionsEnableDisable}
                    className={classNames('btn', 'btn--small', 'btn--blue')}
                  >
                    Enable Direct Mentions
                  </button>
                )}
              </div>
            )}
            {(currentUserIsAdmin || currentUserIsModerator) && (
              <div className={styles.userModalAdminModerator}>
                {!data?.profile?.bannedAt && (
                  <button
                    onClick={doBan}
                    disabled={loadingBanUnban}
                    className={classNames(
                      styles.userModalAdminModeratorBtn,
                      'btn',
                      'btn--small',
                      'btn--red',
                    )}
                  >
                    Ban
                  </button>
                )}
                {data?.profile?.bannedAt && (
                  <button
                    onClick={doUnban}
                    disabled={loadingBanUnban}
                    className={classNames(
                      styles.userModalAdminModeratorBtn,
                      'btn',
                      'btn--small',
                      'btn--dark',
                    )}
                  >
                    Unban
                  </button>
                )}
                {!data?.profile?.mutedAt && (
                  <button
                    onClick={doMute}
                    disabled={loadingMuteUnmute}
                    className={classNames(
                      styles.userModalAdminModeratorBtn,
                      'btn',
                      'btn--small',
                      'btn--blue',
                    )}
                  >
                    Mute
                  </button>
                )}
                {data?.profile?.mutedAt && (
                  <button
                    onClick={doUnmute}
                    disabled={loadingMuteUnmute}
                    className={classNames(
                      styles.userModalAdminModeratorBtn,
                      'btn',
                      'btn--small',
                      'btn--dark',
                    )}
                  >
                    Unmute
                  </button>
                )}
              </div>
            )}
            {isAddressEqual(account, userAddress) ? (
              <UserModalCurrentUserBadges
                address={userAddress}
                badges={data?.badges}
                chatBadges={data?.chatBadges}
              />
            ) : currentUserIsAdmin ? (
              <UserModalAdminBadges
                userName={data?.profile?.name}
                address={userAddress}
                badges={data?.badges}
              />
            ) : (
              <UserModalBadges address={userAddress} badges={data?.badges} />
            )}
            {data?.nextLevel && (
              <div style={{ width: '100%' }}>
                <div className={styles.userModalProgress}>
                  <div className={styles.userModalProgressLvl}>
                    {data?.currentLevel}
                  </div>
                  <div className={styles.userModalProgressLine}>
                    <span
                      className={styles.userModalProgressLineState}
                      style={{ width: `${data?.progress}%` }}
                    />
                  </div>
                  <div className={styles.userModalProgressLvl}>
                    {data?.nextLevel}
                  </div>
                </div>
                <div className={styles.progressText}>
                  {Number(data?.nextLevelCount) - Number(data?.totalWins || 0)}{' '}
                  wins left out of {Number(data?.nextLevelCount)}
                </div>
              </div>
            )}
            {!isAddressEqual(account, userAddress) && data?.profile && (
              <>
                <div
                  className={`heading dfkModalTitle ${styles.userModalSubtitle}`}
                >
                  Tip User
                </div>
                <UserModalTip userProfile={data?.profile} />
              </>
            )}
            <div
              className={`heading dfkModalTitle ${styles.userModalSubtitle}`}
            >
              Deposit / Withdraw History
            </div>
            <div className={styles.userModalStats}>
              <div className={styles.userModalStatsItem}>
                <b>
                  <Image
                    alt="Coin"
                    src={'/gold_coin.png'}
                    width={18}
                    height={18}
                  />
                  {data?.goldTotalDeposited} G
                </b>{' '}
                Total Deposited
              </div>
              <div className={styles.userModalStatsItem}>
                <b>
                  <Image
                    alt="Coin"
                    src={'/gold_coin.png'}
                    width={18}
                    height={18}
                  />
                  {data?.goldTotalWithdraw} G
                </b>{' '}
                Total Withdraw
              </div>
              <div className={styles.userModalStatsItem}>
                <b>{data?.goldTotalDW}</b> Total D/W
              </div>
            </div>
            <div className={styles.userModalStats}>
              <div className={styles.userModalStatsItem}>
                <b>
                  <Image
                    alt="Coin"
                    src={'/jewel_coin.svg'}
                    width={18}
                    height={18}
                  />
                  {data?.jewelTotalDeposited} J
                </b>{' '}
                Total Deposited
              </div>
              <div className={styles.userModalStatsItem}>
                <b>
                  <Image
                    alt="Coin"
                    src={'/jewel_coin.svg'}
                    width={18}
                    height={18}
                  />
                  {data?.jewelTotalWithdraw} J
                </b>{' '}
                Total Withdraw
              </div>
              <div className={styles.userModalStatsItem}>
                <b>{data?.jewelTotalDW}</b> Total D/W
              </div>
            </div>
            <div
              className={`heading dfkModalTitle  ${styles.userModalSubtitle}`}
            >
              game history
            </div>
            <div className={styles.userModalStats}>
              <div className={styles.userModalStatsItem}>
                <b>{data?.totalGames}</b> Total Games
              </div>
              <div className={styles.userModalStatsItem}>
                <b>{data?.totalWins}</b> Total Wins
              </div>
              <div className={styles.userModalStatsItem}>
                <b>{data?.totalDefeats}</b> Total Defeats
              </div>
            </div>
            <div className={styles.userModalStats}>
              <div className={styles.userModalStatsItem}>
                <b>
                  <Image
                    alt="Coin"
                    src={'/gold_coin.png'}
                    width={18}
                    height={18}
                  />
                  {data?.totalEarn} G
                </b>{' '}
                Total Earned
              </div>
              <div className={styles.userModalStatsItem}>
                <b>
                  <Image
                    alt="Coin"
                    src={'/gold_coin.png'}
                    width={18}
                    height={18}
                  />
                  {data?.totalLost} G
                </b>{' '}
                Total Lost
              </div>
              <div className={styles.userModalStatsItem}>
                {userAddress !== account && (
                  <>
                    <b>{data?.favoriteHero || '-'}</b> Favorite Hero
                  </>
                )}
                {userAddress === account && (
                  <div style={{ marginTop: isSignedIn ? 52 : 0 }}>
                    {isSignedIn ? (
                      <HeroesDropdown />
                    ) : (
                      <button
                        className={`btn btn--large btn--dark`}
                        onClick={doSignIn}
                        disabled={loadingSignIn}
                      >
                        Connect
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <button
              className="btn dfkModal__close"
              aria-label="Close modal"
              onClick={closeModal}
            />
          </>
        )}
        {state === 'error' && 'Try again'}
      </Modal>
    </>
  )
}
