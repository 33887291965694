import persistentToastActions from './persistentToastActions'

const DEFAULT_MESSAGE = 'Loading...'

const initialData = {
  toasts: [],
}

const persistentToastReducers = (state = initialData, { type, payload }) => {
  if (type === persistentToastActions.ADD_TOAST) {
    return {
      ...state,
      toasts: [
        ...state.toasts,
        {
          id: payload.id,
          message: payload.message || DEFAULT_MESSAGE,
          timestamp: new Date(),
        },
      ],
    }
  }

  if (type === persistentToastActions.REMOVE_TOAST) {
    return {
      ...state,
      toasts: state.toasts.filter((loader) => loader.id !== payload.id),
    }
  }

  if (type === persistentToastActions.REMOVE_ALL_TOASTS) {
    return {
      ...initialData,
    }
  }

  return state
}

export default persistentToastReducers
