import auth from './auth/authReducers'
import liveGames from './liveGames/liveGamesReducers'
import completedGames from './completedGames/completedGamesReducers'
import heroFilter from './hero/heroFilterReducers'
import userModal from './users/userModalReducers'
import ranking from './ranking/rankingReducers'
import balance from './balance/balanceReducers'
import audio from './audio/audioReducers'
import persistentToast from './persistentToast/persistentToastReducers'
import promoCode from './promoCodeModal/promoCodeReducers'
import { combineReducers } from 'redux'
import promoCodeListReducers from './promoCodeListModal/promoCodeListModalReducers'
import adminModeratorBan from './adminModerator/ban/adminModeratorBanReducers'
import adminModeratorMute from './adminModerator/mute/adminModeratorMuteReducers'
import adminBadge from './admin/badge/adminBadgeReducers'
import chat from './chat/chatReducers'

const reducers = () =>
  combineReducers({
    auth,
    liveGames,
    completedGames,
    heroFilter,
    userModal,
    ranking,
    balance,
    audio,
    persistentToast,
    promoCode,
    promoCodeListReducers,
    adminModeratorBan,
    adminModeratorMute,
    adminBadge,
    chat,
  })

export default reducers
