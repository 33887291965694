import { useWeb3React } from '@web3-react/core'
import moment from 'moment'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import authActions from '../../modules/auth/authActions'
import balanceActions from '../../modules/balance/balanceActions'
import completedGamesActions from '../../modules/completedGames/completedGamesActions'
import completedGamesSelectors from '../../modules/completedGames/completedGamesSelectors'
import { ConfigStore } from '../../modules/config/configStore'
import { Web3Errors } from '../../modules/error/Web3Errors'
import LiveGamesService from '../../modules/liveGames/liveGamesService'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import styles from '../../styles/Modals.module.scss'

export default function DuelPostGameForgottenModal(props) {
  const duel = useSelector(completedGamesSelectors.selectOpenGameToWarn)
  const dispatch = useDispatch()
  const isOpen = Boolean(duel)
  const closeModal = () => {
    dispatch({ type: completedGamesActions.OPEN_GAME_TO_WARN, payload: null })
  }

  const { account } = useWeb3React()
  const router = useRouter()

  const [buttonLoading, setButtonLoading] = useState(false)

  const callContractToPostDuel = async () => {
    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    try {
      setButtonLoading(true)
      // @ts-ignore
      const web3Instance = new Web3(window.ethereum)

      const battleContract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      if (
        !(await LiveGamesService.isPostDuel(
          account,
          duel.pairId,
          battleContract,
        ))
      ) {
        return
      }

      dispatch(
        persistentToastActions.doAddToast(
          'DuelPostGameForgottenModal',
          'Waiting for you to end the game...',
        ),
      )

      await battleContract.methods
        .post_fight_updates(duel.pairId)
        .send({ from: account })

      dispatch(balanceActions.doFetch(account, true))

      router.push('/')

      closeModal()
    } catch (error) {
      Web3Errors.handle(error)
    } finally {
      setButtonLoading(false)
      dispatch(
        persistentToastActions.doRemoveToast('DuelPostGameForgottenModal'),
      )
    }
  }

  return (
    // @ts-ignore
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="End Game"
      className={`dfkModal ${styles.authModal}`}
      closeTimeoutMS={200}
    >
      <>
        <div className={styles.authModalText}>
          Your duel <b>#{duel?.pairId}</b> from{' '}
          <b>{moment(duel?.completed).fromNow()}</b> is still open. <br />
          Please click on End Game to unlock your hero.
        </div>

        <button
          onClick={callContractToPostDuel}
          className={`btn btn--large btn--green ${styles.authModalBtn}`}
          disabled={buttonLoading}
        >
          End Game
        </button>
      </>
    </Modal>
  )
}
