import Modal from 'react-modal'
import OlderVersionsBattles from './olderVersionsBattles'
import styles from '../../styles/OlderVersions.module.scss'
import OlderVersionsBalance from './olderVersionsBalance'
import getOlderVersionsConfig from '../../modules/olderVersions/olderVersionsConfig'
import { useState } from 'react'

interface OlderVersionsModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function OlderVersionsModal(props: OlderVersionsModalProps) {
  const { isOpen, onClose } = props

  // This is a trick to reload the balance after a battle is closed
  // in case there are winnings for the player
  const [key, setKey] = useState(0)

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel={'Select Hero Modal'}
        className={`dfkModal ${styles.heroSelectModal}`}
        closeTimeoutMS={200}
      >
        {getOlderVersionsConfig()
          .filter((oldVersionConfig) => {
            return (
              oldVersionConfig?.battleContractAddress ||
              oldVersionConfig?.escrowContractAddress
            )
          })
          .map((oldVersionConfig) => {
            return (
              <div className={styles.versionRow} key={oldVersionConfig.label}>
                <div className="heading">{oldVersionConfig.label}</div>

                {oldVersionConfig.battleContractAddress && (
                  <OlderVersionsBattles
                    label={oldVersionConfig.label}
                    contractAddress={oldVersionConfig.battleContractAddress}
                    abi={oldVersionConfig.battleContractAbi}
                    duelStatusEnumerator={oldVersionConfig.duelStatusEnumerator}
                    onRefresh={() => setKey(key + 1)}
                  />
                )}

                {oldVersionConfig.escrowContractAddress && (
                  <OlderVersionsBalance
                    label={oldVersionConfig.label}
                    contractAddress={oldVersionConfig.escrowContractAddress}
                    abi={oldVersionConfig.escrowContractAbi}
                    key={key}
                  />
                )}
              </div>
            )
          })}

        <button
          className="btn dfkModal__close"
          aria-label="Close modal"
          onClick={onClose}
        />
      </Modal>
    </>
  )
}
