import axios from 'axios'
import { AuthToken } from '../auth/authToken'

export default class AdminModeratorService {
  static async fetchBanned(publicAddress, page, pageSize) {
    return this.fetchUsers(publicAddress, { banned: true }, page, pageSize)
  }

  static async fetchMuted(publicAddress, page, pageSize) {
    return this.fetchUsers(publicAddress, { muted: true }, page, pageSize)
  }

  static async fetchUsers(publicAddress, filter, page, pageSize) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile`,
      {
        params: {
          filter,
          page,
          pageSize,
        },
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }

  static async fetchBadges(publicAddress, page, pageSize) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/badge`,
      {
        params: {
          page,
          pageSize,
        },
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }

  static async createBadge(publicAddress, label, file) {
    const formData = new FormData()
    formData.append('label', label)
    formData.append('file', file)
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/badge`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }

  static async updateBadge(publicAddress, id, label, file) {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('label', label)
    formData.append('file', file)
    const { data } = await axios.put(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/badge/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }

  static async deleteBadge(publicAddress, id) {
    const { data } = await axios.delete(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/badge/${id}`,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data
  }

  static async search(search) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/search`,
      {
        params: {
          search,
        },
      },
    )

    return data
  }

  static async enableDirectMentions(publicAddress) {
    await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/direct-mentions`,
      null,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )
  }

  static async disableDirectMentions(publicAddress) {
    await axios.delete(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/direct-mentions`,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )
  }

  static async ban(publicAddress, id) {
    await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${id}/ban`,
      null,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )
  }

  static async unban(publicAddress, id) {
    await axios.delete(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${id}/ban`,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )
  }

  static async mute(publicAddress, id) {
    await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${id}/mute`,
      null,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )
  }

  static async unmute(publicAddress, id) {
    await axios.delete(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${id}/mute`,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )
  }
}
