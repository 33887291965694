import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useSelector } from 'react-redux'
import formatGold from '../lib/formatGold'
import formatJewel from '../lib/formatJewel'
import balanceSelectors from '../modules/balance/balanceSelectors'
import styles from '../styles/Header.module.scss'

export default function HeaderWallet() {
  const goldBalance = useSelector(balanceSelectors.selectGoldBalance)
  const jewelBalance = useSelector(balanceSelectors.selectJewelBalance)
  const balanceState = useSelector(balanceSelectors.selectState)

  return (
    <>
      {balanceState === 'idle' && (
        <div className={styles.wallet}>
          <div className={styles.walletAmount}>
            <Image alt="Jewel" src={'/jewel_coin.svg'} width={18} height={18} />
            {formatJewel(jewelBalance)} J
          </div>
          <div className={styles.walletAmount}>
            <Image alt="Gold" src={'/gold_coin.png'} width={18} height={18} />
            {formatGold(goldBalance)} G
          </div>
          <Link href="/wallet">
            <a
              className={`${styles.walletBtn} btn btn--default btn--square btn--green`}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7384 1.18005C12.4957 0.316346 11.6101 -0.183774 10.7604 0.062998L1.16045 2.85119C0.473564 3.05069 0 3.68888 0 4.41506V14.1087C0 14.1975 0.0071614 14.2861 0.0213627 14.3736H0C0 15.2718 0.716344 16 1.6 16H14.4C15.2837 16 16 15.2718 16 14.3736V4.61487C16 3.71661 15.2837 2.98842 14.4 2.98842H12.8V1.62687C12.8 1.47574 12.7793 1.32536 12.7384 1.18005ZM14.4 14.3736H6.51204L11.6396 12.8843C12.3264 12.6848 12.8 12.0466 12.8 11.3205V7.86776H14.4V14.3736ZM14.4 6.24132V4.61487H12.8V6.24132H14.4ZM9.6 6.24132C9.6 6.69045 9.24183 7.05454 8.8 7.05454C8.35817 7.05454 8 6.69045 8 6.24132C8 5.79219 8.35817 5.42809 8.8 5.42809C9.24183 5.42809 9.6 5.79219 9.6 6.24132Z"
                  fill="white"
                />
              </svg>
            </a>
          </Link>
        </div>
      )}
    </>
  )
}
