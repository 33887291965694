import { v4 as uuid } from 'uuid'
import CompletedGamesService from './completedGamesService'
import completedGamesSelectors from './completedGamesSelectors'
import moment from 'moment'
import isAddressEqual from '../../lib/isAddressEqual'

const prefix = 'COMPLETED_GAMES'

function isGameShouldBeWatched(account, game, router, state) {
  if (
    !isAddressEqual(game?.player1?.address, account) &&
    !isAddressEqual(game?.player2?.address, account)
  ) {
    return false
  }

  const isRecentlyCompleted = moment(game.completed).isAfter(
    moment().subtract(5, 'minute'),
  )

  const watchedGames = completedGamesSelectors.selectWatchedGames(state)

  return (
    !watchedGames[game.pairId] &&
    isRecentlyCompleted &&
    !router.route.includes('/duel')
  )
}

const completedGamesActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  REFRESH_DATA: `${prefix}_REFRESH_DATA`,

  GAME_WATCHED: `${prefix}_GAME_WATCHED`,
  OPEN_GAMES_WARNED: `${prefix}_OPEN_GAMES_WARNED`,
  OPEN_GAME_TO_WARN: `${prefix}_OPEN_GAME_TO_WARN`,

  doFindAndWatchCurrentGame:
    (address, router) => async (dispatch, getState) => {
      try {
        const game = await CompletedGamesService.fetchLast(address)

        if (!game) {
          return
        }

        if (isGameShouldBeWatched(address, game, router, getState())) {
          router.push(`/duel/${game.pairId}`)
          dispatch({
            type: completedGamesActions.GAME_WATCHED,
            payload: game,
          })
        }
      } catch (error) {
        console.error(error)
      }
    },

  doFindAndWarnOpenGame: (address, router) => async (dispatch, getState) => {
    try {
      const game = await CompletedGamesService.fetchOpenGameInLast10(address)

      if (!game) {
        return
      }

      let warnOffsetInMinutes = 10
      if (isAddressEqual(game.winnerAddress, address)) {
        warnOffsetInMinutes = 5
      }

      const isOldGameNotFinished = moment(game.completed).isBefore(
        moment().subtract(warnOffsetInMinutes, 'minute'),
      )

      const openGamesWarned = completedGamesSelectors.selectOpenGamesWarned(
        getState(),
      )

      if (
        isOldGameNotFinished &&
        !router.route.includes('/duel') &&
        !openGamesWarned[game.pairId]
      ) {
        dispatch({
          type: completedGamesActions.OPEN_GAME_TO_WARN,
          payload: game,
        })
      }
    } catch (error) {
      console.error(error)
    }
  },

  doChangePage:
    (address, router, renderedPage) => async (dispatch, getState) => {
      const itemsPerPage = completedGamesSelectors.selectItemsPerPage(
        getState(),
      )
      const accountAddress = completedGamesSelectors.selectAccountAddress(
        getState(),
      )
      dispatch(
        completedGamesActions.doFetch(
          address,
          router,
          renderedPage,
          itemsPerPage,
          accountAddress,
        ),
      )
    },

  doFetch:
    (
      address,
      router,
      renderedPage,
      itemsPerPage,
      accountAddress,
      hideLoading = false,
    ) =>
    async (dispatch, getState) => {
      try {
        const fetchId = uuid()

        dispatch({
          type: completedGamesActions.FETCH_STARTED,
          payload: {
            accountAddress,
            fetchId,
            renderedPage,
            itemsPerPage,
            hideLoading,
          },
        })

        const data = await CompletedGamesService.fetch(
          accountAddress,
          renderedPage,
          itemsPerPage,
        )

        if (fetchId === completedGamesSelectors.selectFetchId(getState())) {
          for (let game of data.rows) {
            if (isGameShouldBeWatched(address, game, router, getState())) {
              router.push(`/duel/${game.pairId}`)
              dispatch({
                type: completedGamesActions.GAME_WATCHED,
                payload: game,
              })
              return
            }
          }

          dispatch({
            type: completedGamesActions.FETCH_SUCCESS,
            payload: { rows: data.rows, count: data.count },
          })
        }
      } catch (error) {
        console.error(error)
        dispatch({
          type: completedGamesActions.FETCH_ERROR,
        })
      }
    },
}

export default completedGamesActions
