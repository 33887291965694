import BigNumber from 'bignumber.js'
import RankEnumerator from '../../enumerators/rankEnumerator'
import calculatePercentage from '../../lib/calculatePercentage'
import formatGold from '../../lib/formatGold'
import formatJewel from '../../lib/formatJewel'
import BalanceService from '../balance/balanceService'
import HeroService from '../hero/heroService'
import { UserProfileService } from './userProfileService'

export default class UserModalService {
  static async fetch(userAddress) {
    const profile = await UserProfileService.findProfileByAddress(userAddress)
    const stats = await UserProfileService.findProfileStats(userAddress)

    const rank = RankEnumerator.findRank(profile.rank)
    const { all: badges, chat: chatBadges } =
      await UserProfileService.findBadges(userAddress)

    const withdrawHistory = await BalanceService.fetchWithdrawHistory(
      userAddress,
      0,
    )
    const depositHistory = await BalanceService.fetchDepositHistory(
      userAddress,
      0,
    )

    const goldTotalDeposit = depositHistory
      .filter((dh) => dh.token === 'gold')
      .reduce((total, current) => {
        return new BigNumber(current.amount).plus(total)
      }, new BigNumber(0))
      .toNumber()

    const goldTotalWithdraw = withdrawHistory
      .filter((dh) => dh.token === 'gold')
      .reduce((total, current) => {
        return new BigNumber(current.amount).plus(total)
      }, new BigNumber(0))
      .toNumber()

    const jewelTotalDeposit = depositHistory
      .filter((dh) => dh.token === 'jewel')
      .reduce((total, current) => {
        return new BigNumber(current.amount).plus(total)
      }, new BigNumber(0))
      .toNumber()

    const jewelTotalWithdraw = withdrawHistory
      .filter((dh) => dh.token === 'jewel')
      .reduce((total, current) => {
        return new BigNumber(current.amount).plus(total)
      }, new BigNumber(0))
      .toNumber()

    const goldOperationsCount =
      depositHistory.filter((dh) => dh.token === 'gold').length +
      withdrawHistory.filter((dh) => dh.token === 'gold').length

    const jewelOperationsCount =
      depositHistory.filter((dh) => dh.token === 'jewel').length +
      withdrawHistory.filter((dh) => dh.token === 'jewel').length

    const presenterFormat = '0,0.[000]'

    const favoriteHero = stats.favoriteHeroId
      ? await HeroService.fetch(stats.favoriteHeroId)
      : null

    var returnInfo = {
      profile: profile,
      badges,
      chatBadges: chatBadges,
      currentLevel: rank.label,
      progress: rank.nextCount
        ? calculatePercentage(
            Number(stats.totalWins),
            Number(rank.nextCount) - Number(rank.minCount || 0),
          )
        : 100,
      nextLevelCount: rank.nextCount,
      nextLevel: rank.nextLabel,
      goldTotalDeposited: formatGold(goldTotalDeposit),
      goldTotalWithdraw: formatGold(goldTotalWithdraw),
      goldTotalDW: goldOperationsCount,
      jewelTotalDeposited: formatJewel(jewelTotalDeposit),
      jewelTotalWithdraw: formatJewel(jewelTotalWithdraw),
      jewelTotalDW: jewelOperationsCount,
      totalGames: stats.totalGames,
      totalWins: stats.totalWins,
      totalDefeats: stats.totalDefeats,
      totalEarn: formatGold(stats.totalEarn),
      totalLost: formatGold(stats.totalLost),
      favoriteHero: favoriteHero?.name || '',
    }

    return returnInfo
  }
}
