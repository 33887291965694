import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import classNames from '../../lib/classNames'
import styles from '../../styles/Modals.module.scss'

export default function UserModalBadges(props) {
  const { badges } = props
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [badges])

  return (
    <>
      {badges && Boolean(badges.length) && (
        <div className={classNames(styles.userModalBadges)}>
          {badges.map((badge) => {
            return (
              <div
                key={badge.id}
                data-tip={`<span>${badge.label}</span>`}
                data-html={true}
                className={classNames(styles.userModalBadge, styles.badge)}
              >
                <img alt="icon" src={badge.imageUrl} width={26} height={26} />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
