import { maxBy } from 'lodash'
import { createSelector } from 'reselect'

const selectRaw = (state) => state.persistentToast

const selectCurrent = createSelector([selectRaw], (raw) =>
  maxBy<any>(raw.toasts, 'timestamp'),
)

const persistentToastSelectors = {
  selectCurrent,
}

export default persistentToastSelectors
