const values = {
  white: {
    minCount: 0,
    nextCount: 100,
    label: 'Neophyte',
    nextLabel: 'Apprentice',
    color: '#fff',
  },
  bronze: {
    minCount: 100,
    nextCount: 1000,
    label: 'Apprentice',
    nextLabel: 'Journeyman',
    color: '#ffc107',
  },
  silver: {
    minCount: 1000,
    nextCount: 10000,
    label: 'Journeyman',
    nextLabel: 'Master',
    color: '#c2c2c2',
  },
  gold: {
    minCount: 10000,
    nextCount: 100000,
    label: 'Master',
    nextLabel: 'GrandMaster',
    colro: '#ff9800',
  },
  platinum: {
    minCount: 100000,
    label: 'GrandMaster',
    color: '#a0b2c6',
  },
}

export default class RankEnumerator {
  static findRank(rankId) {
    return values[rankId || 'white']
  }
}
