import { createSelector } from 'reselect'
import isAddressEqual from '../../lib/isAddressEqual'
import liveGamesSelectors from '../liveGames/liveGamesSelectors'

const selectRaw = (state) => state.completedGames

const selectState = createSelector([selectRaw], (raw) => raw.state)
const selectAccountAddress = createSelector(
  [selectRaw],
  (raw) => raw.accountAddress,
)
const selectFetchId = createSelector([selectRaw], (raw) => raw.fetchId)
const selectWatchedGames = createSelector(
  [selectRaw],
  (raw) => raw.watchedGames || {},
)
const selectOpenGamesWarned = createSelector(
  [selectRaw],
  (raw) => raw.openGamesWarned || {},
)
const selectOpenGameToWarn = createSelector(
  [selectRaw],
  (raw) => raw.openGameToWarn,
)

const selectData = createSelector(
  [selectRaw, liveGamesSelectors.selectData],
  (raw, liveGames) => {
    return (raw.rows || []).filter(
      (row) => !liveGames.some((lg) => lg.pairId === row.pairId),
    )
  },
)

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter || {}
})

const selectRenderedPage = createSelector([selectRaw], (raw) => {
  return raw.renderedPage
})

const selectItemsPerPage = createSelector([selectRaw], (raw) => {
  return raw.itemsPerPage
})

const selectTotal = createSelector([selectRaw], (raw) => {
  return raw.count
})

const completedGamesSelectors = {
  selectState,
  selectData,
  selectRenderedPage,
  selectItemsPerPage,
  selectFilter,
  selectRaw,
  selectTotal,
  selectFetchId,
  selectAccountAddress,
  selectWatchedGames,
  selectOpenGamesWarned,
  selectOpenGameToWarn,
}

export default completedGamesSelectors
