import { sortBy, uniqBy } from 'lodash'
import { v4 as uuid } from 'uuid'
import duelStatusEnumerator from '../../enumerators/duelStatusEnumerator'
import { shuffleArray } from '../../lib/shuffleArray'
import liveGamesSelectors from './liveGamesSelectors'
import LiveGamesService from './liveGamesService'

const prefix = 'LIVE_GAMES'

const liveGamesActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  REFRESH_DATA: `${prefix}_REFRESH_DATA`,

  FILTER_CHANGED: `${prefix}_FILTER_CHANGED`,
  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  doChangeFilter:
    (filter: { betAmount: string }) => async (dispatch, getState) => {
      dispatch(liveGamesActions.doChangePage(0))
      dispatch({
        type: liveGamesActions.FILTER_CHANGED,
        payload: filter,
      })
    },

  doChangePage: (page) => {
    return {
      type: liveGamesActions.PAGINATION_CHANGED,
      payload: page,
    }
  },

  doFetch: (account) => async (dispatch, getState) => {
    try {
      const fetchId = uuid()

      dispatch({
        type: liveGamesActions.FETCH_STARTED,
        payload: fetchId,
      })

      let data = await LiveGamesService.fetch(account)

      data = shuffleArray(uniqBy(data, 'pairId'))

      // Ready to start games must be first
      data = sortBy(data, [(row) => row.status === 'BOTHCONFIRMED']).reverse()

      if (fetchId === liveGamesSelectors.selectFetchId(getState())) {
        dispatch({
          type: liveGamesActions.FETCH_SUCCESS,
          payload: { data },
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: liveGamesActions.FETCH_ERROR,
      })
    }
  },

  doRefreshData: (account, router) => async (dispatch, getState) => {
    try {
      let oldData = [...liveGamesSelectors.selectData(getState())]
      let newData = await LiveGamesService.fetch(account)

      let data = []

      for (let oldDataItem of oldData) {
        const newDataItem = newData.find(
          (newBattle) => oldDataItem.pairId === newBattle.pairId,
        )
        if (newDataItem) {
          const isDuelChangedToFinished =
            oldDataItem.status !== newDataItem.status &&
            newDataItem.status === 'GAMEFINISHED'

          if (isDuelChangedToFinished) {
            if (!router.route.includes('/duel')) {
              router.push(`/duel/${newDataItem.pairId}`)
            }
            return
          }
        }
      }

      for (let oldDataItem of oldData) {
        const newDataItem = newData.find(
          (item) => oldDataItem.pairId === item.pairId,
        )

        if (newDataItem) {
          data.push(newDataItem)
        }
      }

      let uniqueNewItems = []

      for (let newDataItem of newData) {
        let existingIndex = data.findIndex(
          (item) => item.pairId === newDataItem.pairId,
        )

        if (existingIndex > -1) {
          if (newDataItem.status === 'BOTHCONFIRMED') {
            data.splice(existingIndex, 1)
            data.unshift(newDataItem)
          }
        } else {
          uniqueNewItems.push(newDataItem)
        }
      }

      // Randomize ONLY new data (keep order of existing ones)
      uniqueNewItems = shuffleArray(uniqueNewItems)

      data = [...data, ...uniqueNewItems]

      dispatch({
        type: liveGamesActions.REFRESH_DATA,
        payload: {
          data,
        },
      })
    } catch (error) {
      console.error(error)
    }
  },
}

export default liveGamesActions
