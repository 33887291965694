import { useWeb3React } from '@web3-react/core'
import { minBy, sortBy, toLower } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import OnlineUserProperies from '../../enumerators/onlineUserProperties'
import useAuthSignIn from '../../hooks/useAuthSignIn'
import useChatSocket from '../../hooks/useChatSocket'
import classNames from '../../lib/classNames'
import isAddressEqual from '../../lib/isAddressEqual'
import Messages from '../../lib/messages'
import authSelectors from '../../modules/auth/authSelectors'
import chatActions from '../../modules/chat/chatActions'
import ChatService from '../../modules/chat/chatService'
import styles from '../../styles/Chat.module.scss'
import ChatGlobal from './chatGlobal'
import ChatPrivateMessages from './chatPrivateMessages'
import ChatPrivateUsers from './chatPrivateUsers'

export default function Chat() {
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const { loadingSignIn, doSignIn, isSignedIn } = useAuthSignIn(account)
  const [hasNewPrivateMessage, setHasNewPrivateMessage] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const [globalMessages, setGlobalMessages] = useState<any>([])
  const globalMessagesRef = useRef([])

  const [privateMessages, setPrivateMessages] = useState<any>([])
  const privateMessagesRef = useRef([])

  const [privateUsers, setPrivateUsers] = useState<any>([])
  const privateUsersRef = useRef([])

  const [userPrivate, setUserPrivate] = useState(null)

  const profile = useSelector(authSelectors.selectProfile)

  useEffect(() => {
    if (userPrivate) {
      setSelectedTabIndex(1)
    }
  }, [userPrivate])

  useEffect(() => {
    if (selectedTabIndex === 1) {
      setHasNewPrivateMessage(false)
    }
  }, [selectedTabIndex])

  useEffect(() => {
    if (!isSignedIn) {
      setUserPrivate(null)
      setSelectedTabIndex(0)
    }
  }, [isSignedIn])

  useEffect(() => {
    const interval = setInterval(() => {
      if (globalMessagesRef.current && globalMessagesRef.current.length > 300) {
        globalMessagesRef.current = sortBy(
          globalMessagesRef.current,
          'createdAt',
        )
          .reverse()
          .slice(0, 100)
        setGlobalMessages(globalMessagesRef.current)
      }
    }, 60000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [setGlobalMessages])

  const onNewGlobalMessage = useCallback((message) => {
    if (
      message &&
      message.usersMentions &&
      message.usersMentions.some((um) => um.id === profile?.id)
    ) {
      if (message?.user?.id !== profile?.id) {
        Messages.info(
          message.isTip
            ? `${message?.user?.name} sent you a tip.`
            : `${message?.user?.name} mentioned you in the chat.`,
          () => {
            setSelectedTabIndex(0)
            setUserPrivate(null)
            if (document.getElementById(message.id)) {
              document.getElementById(message.id).scrollIntoView()
            }
          },
        )
      }
    }

    const globalMessages = [message, ...globalMessagesRef.current]
    globalMessagesRef.current = globalMessages
    setGlobalMessages(globalMessagesRef.current)
  }, [])

  const onDeleteGlobalMessage = useCallback((id) => {
    const globalMessages = globalMessagesRef.current.filter((m) => m.id !== id)
    globalMessagesRef.current = globalMessages
    setGlobalMessages(globalMessagesRef.current)
  }, [])

  const onNewPrivateMessage = useCallback(
    (privateMessage) => {
      // Add private message to list of private messages
      const privateMessages = [privateMessage, ...privateMessagesRef.current]

      privateMessagesRef.current = privateMessages
      setPrivateMessages(privateMessagesRef.current)

      const otherUser = isAddressEqual(
        privateMessage?.userFrom?.publicAddress,
        account,
      )
        ? privateMessage?.userTo
        : privateMessage?.userFrom

      // Mark new message received
      if (
        !userPrivate ||
        (toLower(userPrivate.id) !== toLower(privateMessage.userFrom.id) &&
          toLower(userPrivate.id) !== toLower(privateMessage.userTo.id))
      ) {
        Messages.info(
          `${privateMessage?.userFrom?.name} sent you a message.`,
          () => {
            setSelectedTabIndex(1)
            setUserPrivate(otherUser)
          },
        )
        setHasNewPrivateMessage(true)
      }

      // Add to list of private users
      if (
        privateUsers &&
        !privateUsers.some((privateUser) => privateUser.id === otherUser.id)
      ) {
        otherUser.lastMessage = privateMessage
        const privateUsers = [...privateUsersRef.current, otherUser]
        privateUsersRef.current = privateUsers
        setPrivateUsers(privateUsersRef.current)
      }
    },
    [account, userPrivate],
  )

  const { socket, onlineUsers, initialPrivateUsers } = useChatSocket(
    account,
    isSignedIn,
  )

  useEffect(() => {
    const onlineUsersMap = {}
    for (let onlineUser of onlineUsers) {
      onlineUsersMap[toLower(onlineUser[OnlineUserProperies.publicAddress])] =
        true
    }
    dispatch(chatActions.changeOnlineUsers(onlineUsersMap))
  }, [onlineUsers])

  useEffect(() => {
    privateUsersRef.current = initialPrivateUsers
    setPrivateUsers(privateUsersRef.current)
  }, [initialPrivateUsers])

  useEffect(() => {
    if (socket) {
      socket.on('message', (globalMessage) => {
        onNewGlobalMessage && onNewGlobalMessage(globalMessage)
      })

      socket.on('message-deleted', (id) => {
        onDeleteGlobalMessage && onDeleteGlobalMessage(id)
      })

      return () => {
        socket.off('message')
      }
    }
  }, [socket, onNewGlobalMessage])

  useEffect(() => {
    if (socket) {
      socket.on('message-private', (privateMessage) => {
        onNewPrivateMessage && onNewPrivateMessage(privateMessage)
      })

      return () => {
        socket.off('message-private')
      }
    }
  }, [onNewPrivateMessage, socket, account, userPrivate])

  function onSelect(index: number, lastIndex: number, event) {
    if (loadingSignIn) {
      return false
    }

    if (index === 1 && !isSignedIn) {
      doSignIn()
      return false
    }

    if (index === 1) {
      setHasNewPrivateMessage(false)
    }

    setUserPrivate(null)
    setSelectedTabIndex(index)
  }

  useEffect(() => {
    doFetch()
  }, [])

  async function doFetch() {
    try {
      globalMessagesRef.current = await ChatService.fetchGlobalMessages(null)
      setGlobalMessages(globalMessagesRef.current)
    } catch (error) {
      console.error(error)
    }
  }

  async function doFetchMoreMessages() {
    try {
      const lastMessage = minBy(globalMessagesRef.current, 'createdAt')
      globalMessagesRef.current = [
        ...globalMessagesRef.current,
        ...(await ChatService.fetchGlobalMessages(lastMessage.createdAt)),
      ]
      setGlobalMessages(globalMessagesRef.current)
      return globalMessagesRef.current
    } catch (error) {
      console.error(error)
    }
  }

  async function doFetchPrivateMessages(userPrivate) {
    try {
      privateMessagesRef.current = await ChatService.fetchPrivateMessage(
        account,
        userPrivate.id,
        null,
      )
      setPrivateMessages(privateMessagesRef.current)
    } catch (error) {
      console.error(error)
    }
  }

  async function doFetchMorePrivateMessages() {
    try {
      const lastMessage = minBy(privateMessagesRef.current, 'createdAt')
      privateMessagesRef.current = [
        ...privateMessagesRef.current,
        ...(await ChatService.fetchPrivateMessage(
          account,
          userPrivate.id,
          lastMessage.createdAt,
        )),
      ]
      setPrivateMessages(privateMessagesRef.current)
      return privateMessagesRef.current
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <aside className={styles.chat}>
      {/* @ts-ignore */}
      <Tabs
        className={styles.tabs}
        selectedTabPanelClassName={styles.panelActive}
        onSelect={onSelect}
        selectedIndex={selectedTabIndex}
      >
        {/* @ts-ignore */}
        <TabList
          className={classNames(
            styles.triggers,
            !isSignedIn && styles.triggersInvisible,
          )}
        >
          {/* @ts-ignore */}
          <Tab className={`${styles.triggersBtn} btn`}>Global chat</Tab>
          {/* @ts-ignore */}
          <Tab
            className={classNames(
              `${styles.triggersBtn} btn`,
              hasNewPrivateMessage ? styles.triggersBtnNotify : null,
            )}
          >
            private messages
          </Tab>
        </TabList>

        {/* @ts-ignore */}
        <TabPanel className={styles.panel}>
          {selectedTabIndex === 0 && (
            <ChatGlobal
              messages={globalMessages}
              onlineUsers={onlineUsers}
              fetchMoreMessages={doFetchMoreMessages}
              setUserPrivate={setUserPrivate}
              key={`global-${selectedTabIndex}`}
            />
          )}
        </TabPanel>

        {/* @ts-ignore */}
        <TabPanel className={styles.panel}>
          {selectedTabIndex === 1 && (
            <>
              {userPrivate ? (
                <ChatPrivateMessages
                  allPrivateMessages={privateMessages}
                  fetchPrivateMessages={doFetchPrivateMessages}
                  fetchMorePrivateMessages={doFetchMorePrivateMessages}
                  otherUser={userPrivate}
                  doClose={() => setUserPrivate(null)}
                  key={`private-${selectedTabIndex}`}
                />
              ) : (
                <ChatPrivateUsers
                  privateUsers={privateUsers}
                  setPrivateUser={setUserPrivate}
                  key={`users-${selectedTabIndex}`}
                />
              )}
            </>
          )}
        </TabPanel>
      </Tabs>
    </aside>
  )
}
