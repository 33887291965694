import { useWeb3React } from '@web3-react/core'
import { uniqBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import useAuthSignIn from '../../hooks/useAuthSignIn'
import classNames from '../../lib/classNames'
import Messages from '../../lib/messages'
import { ApiErrors } from '../../modules/error/ApiErrors'
import { UserProfileService } from '../../modules/users/userProfileService'
import styles from '../../styles/Modals.module.scss'

export default function UserModalCurrentUserBadges(props) {
  const { account } = useWeb3React()
  const { loadingSignIn, doSignIn, isSignedIn } = useAuthSignIn(account)

  const { badges } = props

  const [chatBadges, setChatBadges] = useState(props.chatBadges || [])

  useEffect(() => {
    setChatBadges(props.chatBadges || [])
  }, [props.chatBadges])

  function isIncluded(currentBadge) {
    const _isIncluded = (chatBadges || []).some(
      (badge) => currentBadge.id === badge.id,
    )
    return _isIncluded
  }

  async function addBadge(badge) {
    if (!isSignedIn) {
      if (!loadingSignIn) {
        doSignIn()
      }
      return
    }

    setChatBadges(uniqBy([...chatBadges, badge], 'id'))

    try {
      await UserProfileService.updateChatBadge(account, 'add', badge.id)
      Messages.success(`${badge.label} badge successfully added to chat`)
    } catch (error) {
      setChatBadges(chatBadges.filter((current) => current.id !== badge.id))
      ApiErrors.handle(error)
    }
  }

  async function removeBadge(badge) {
    if (!isSignedIn) {
      if (!loadingSignIn) {
        doSignIn()
      }
      return
    }
    setChatBadges(chatBadges.filter((current) => current.id !== badge.id))

    try {
      await UserProfileService.updateChatBadge(account, 'remove', badge.id)
      Messages.success(`${badge.label} badge successfully removed from chat`)
    } catch (error) {
      setChatBadges(uniqBy([...chatBadges, badge], 'id'))
      ApiErrors.handle(error)
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [chatBadges])

  if (!badges || !badges.length) {
    return null
  }

  return (
    <>
      {badges && Boolean(badges.length) && (
        <div className={classNames(styles.userModalBadges)}>
          {badges.map((badge, index) => {
            return (
              <div
                key={badge.id}
                onClick={() =>
                  isIncluded(badge) ? removeBadge(badge) : addBadge(badge)
                }
                data-tip={`<span>${badge.label}</span>`}
                data-html={true}
                data-testid={`badge-${badge.id}`}
                className={classNames(
                  styles.userModalBadge,
                  styles.badge,
                  styles.badgeAdmin,
                  !isIncluded(badge) ? styles.badgeNotIncluded : '',
                )}
              >
                <img alt="icon" src={badge.imageUrl} width={26} height={26} />
              </div>
            )
          })}
        </div>
      )}
      <div className={classNames(styles.userModalBadgesChat)}>
        *Selected badges will be shown on chat.
      </div>
    </>
  )
}
