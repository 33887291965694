export class ConfigStore {
  static get(): {
    BATTLE_CONTRACT_ADDRESS: string
    HERO_CONTRACT_ADDRESS: string
    GOLD_ORACLE_CONTRACT_ADDRESS: string
    ESCROW_CONTRACT_ADDRESS: string
    AFFILIATE_CONTRACT_ADDRESS: string
    GOLD_COIN_ADDRESS: string
    JEWEL_COIN_ADDRESS: string
    ADMIN_ADDRESSES: string
    MODERATOR_ADDRESSES: string
    PROFILES_CONTRACT_ADDRESS: string
    HERO_CONTRACT_ADDRESS_MAINNET: string
    BATTLE_TIMEOUT_IN_SECONDS: string
    DFK_ENVIRONMENT: string

    BATTLE_CONTRACT_BETA5_ADDRESS: string
    BATTLE_CONTRACT_BETA4_ADDRESS: string
    BATTLE_CONTRACT_BETA3_ADDRESS: string
    BATTLE_CONTRACT_BETA2_ADDRESS: string
    BATTLE_CONTRACT_BETA1_ADDRESS: string

    ESCROW_CONTRACT_BETA3_ADDRESS: string
    ESCROW_CONTRACT_BETA2_ADDRESS: string
    ESCROW_CONTRACT_BETA1_ADDRESS: string
  } | null {
    if (!process.browser) {
      return null
    }

    try {
      return JSON.parse(localStorage.getItem('config'))
    } catch (error) {
      return null
    }
  }

  static set(config) {
    if (!process.browser) {
      return
    }

    localStorage.setItem('config', JSON.stringify(config))
  }

  static clear() {
    if (!process.browser) {
      return
    }

    localStorage.removeItem('config')
  }
}
