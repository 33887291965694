import React, { useEffect } from 'react'
import styles from '../../styles/Profile.module.scss'
import Spinner from '../spinner'
import { useWeb3React } from '@web3-react/core'
import useHeroes from '../../hooks/useHeroes'
import { UserProfileService } from '../../modules/users/userProfileService'
import Select from 'react-select'
import usePlayerInfo from '../../hooks/usePlayerInfo'
import { useSelector } from 'react-redux'
import userModalSelectors from '../../modules/users/userModalSelectors'
import Messages from '../../lib/messages'

export function HeroesDropdown() {
  const { account } = useWeb3React()
  const { status, heroes } = useHeroes()
  const userAddress = useSelector(userModalSelectors.selectUserAddress)
  const { data } = usePlayerInfo(userAddress)
  var options = []

  useEffect(() => {
    if (status === 'idle') {
      heroes.forEach((hero) => {
        options.push({
          value: hero.id,
          label: hero.firstName + ' ' + hero.lastName,
        })
      })
    }
  })

  const handleClick = (hero) => {
    UserProfileService.updateFavoriteHero(account, hero)
      .then((result) => {
        Messages.success('Hero Changed Succesfully')
      })
      .catch((error) => {
        console.error(error)
        Messages.error('Unable to Change Favorite Hero')
      })
  }
  return (
    <div className={styles.tokens}>
      {status === 'error' && (
        <div className={`heading ${styles.title}`}>
          Network Error. Please try again later.
        </div>
      )}
      {status === 'loading' && (
        <div className={styles.filter}>
          <Select
            className={`custom-select h40 ${styles.filterSelect}`}
            classNamePrefix="custom-select"
            options={[{ value: '', label: data?.favoriteHero }]}
            placeholder={data?.favoriteHero ? data.favoriteHero : 'Select Hero'}
          />
        </div>
      )}
      {status === 'empty' && (
        <>
          <b>{data?.favoriteHero || '-'}</b> Favorite Hero
        </>
      )}
      {status === 'idle' && (
        <div className={styles.filter}>
          <Select
            className={`custom-select h40 ${styles.filterSelect}`}
            classNamePrefix="custom-select"
            options={options}
            onChange={(hero) => handleClick(hero)}
            placeholder={data?.favoriteHero ? data.favoriteHero : 'Select Hero'}
          />
        </div>
      )}
    </div>
  )
}
