import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AbiItem } from 'web3-utils'
import classNames from '../../lib/classNames'
import Messages from '../../lib/messages'
import { Web3Errors } from '../../modules/error/Web3Errors'
import { OlderVersionsBattleService } from '../../modules/olderVersions/olderVersionsBattleService'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import styles from '../../styles/OlderVersions.module.scss'
import Spinner from '../spinner'

interface OlderVersionsBattlesProps {
  label: string
  contractAddress: string
  abi: AbiItem[]
  duelStatusEnumerator: Array<string>
  onRefresh: () => any
}

export default function OlderVersionsBattles(props: OlderVersionsBattlesProps) {
  const { account } = useWeb3React()
  const [state, setState] = useState('loading')
  const [pairs, setPairs] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    doFetch().then(() => {})
  }, [])

  async function doFetch() {
    try {
      setState('loading')
      const pairs = await OlderVersionsBattleService.fetchOpenBattlesOfUser(
        account,
        props.contractAddress,
        props.abi,
        props.duelStatusEnumerator,
      )
      setPairs(pairs)
      setState('idle')
    } catch (error) {
      console.error(error)
      setState('error')
    }
  }

  async function doClose(pair) {
    try {
      setState('closing')
      dispatch(
        persistentToastActions.doAddToast(
          'olderVersionsBattles',
          'Waiting for confirmation...',
        ),
      )

      if (pair.status === 'EMPTY' || pair.status === 'FIRSTPLAYERIN') {
        await OlderVersionsBattleService.abandonPrePairing(
          account,
          props.contractAddress,
          props.abi,
          pair.pairId,
        )
      }

      if (pair.status === 'SECONDPLAYERIN' || pair.status === 'BOTHCONFIRMED') {
        await OlderVersionsBattleService.abandonPostPairing(
          account,
          props.contractAddress,
          props.abi,
          pair.pairId,
        )
      }

      if (pair.status === 'GAMEFINISHED') {
        await OlderVersionsBattleService.postGame(
          account,
          props.contractAddress,
          props.abi,
          pair.pairId,
        )
      }

      Messages.success('Duel sucessfully closed!')
      props.onRefresh()
      return doFetch()
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('olderVersionsBattles'))
    }
  }

  return (
    <div className={styles.section}>
      <div className={classNames('heading', styles.title)}>Duels</div>
      {state === 'error' && <div className={styles.error}>Error</div>}
      {state === 'loading' && <Spinner />}
      {state === 'idle' && !pairs.length && (
        <div>No open duels found on {props.label} version.</div>
      )}
      {(state === 'idle' || state === 'closing') && Boolean(pairs.length) && (
        <div>
          <button
            onClick={() => doClose(pairs[0])}
            className={`btn btn--default btn--green ${styles.button}`}
            disabled={state === 'closing'}
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              marginBottom: 8,
              marginTop: 16,
            }}
          >
            Close Duel #{pairs[0].pairId}
          </button>
          <div style={{ marginTop: 8 }}>
            There's <strong style={{ color: '#fbe375' }}>{pairs.length}</strong>{' '}
            open {pairs.length === 1 ? 'duel' : 'duels'} left to be closed on
            the {props.label} version.
          </div>
        </div>
      )}
    </div>
  )
}
