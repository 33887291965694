import React from 'react'
import styles from '../styles.module.css'

/* FEMALE ARMS */
const femaleLeftarmbtmhighlight = '/svgs/female/LeftArmBtm-highlight.svg'
const femaleLeftarmbtmshadow = '/svgs/female/LeftArmBtm-shadow.svg'
const femaleLeftarmbtm = '/svgs/female/LeftArmBtm.svg'
const femaleLeftarmtophighlight = '/svgs/female/LeftArmTop-highlight.svg'
const femaleLeftarmtopshadow = '/svgs/female/LeftArmTop-shadow.svg'
const femaleLeftarmtop = '/svgs/female/LeftArmTop.svg'
const femaleRightarmbtmhighlight = '/svgs/female/RightArmBtm-highlight.svg'
const femaleRightarmbtmshadow = '/svgs/female/RightArmBtm-shadow.svg'
const femaleRightarmbtm = '/svgs/female/RightArmBtm.svg'
const femaleRightarmtophighlight = '/svgs/female/RightArmTop-highlight.svg'
const femaleRightarmtopshadow = '/svgs/female/RightArmTop-shadow.svg'
const femaleRightarmtop = '/svgs/female/RightArmTop.svg'
const archerLeftArmTop = '/svgs/female/clothes/archer-LeftArmTop.svg'
const archerLeftArmBottom = '/svgs/female/clothes/archer-LeftArmBtm.svg'
const archerRightArmTop = '/svgs/female/clothes/archer-RightArmTop.svg'
const archerRightArmBottom = '/svgs/female/clothes/archer-RightArmBtm.svg'
const knightLeftArmTop = '/svgs/female/clothes/knight-LeftArmTop.svg'
const knightLeftArmBottom = '/svgs/female/clothes/knight-LeftArmBtm.svg'
const knightRightArmTop = '/svgs/female/clothes/knight-RightArmTop.svg'
const knightRightArmBottom = '/svgs/female/clothes/knight-RightArmBtm.svg'
const monkLeftArmTop = '/svgs/female/clothes/monk-LeftArmTop.svg'
const monkLeftArmBottom = '/svgs/female/clothes/monk-LeftArmBtm.svg'
const monkRightArmTop = '/svgs/female/clothes/monk-RightArmTop.svg'
const monkRightArmBottom = '/svgs/female/clothes/monk-RightArmBtm.svg'
const pirateLeftArmTop = '/svgs/female/clothes/pirate-LeftArmTop.svg'
const pirateLeftArmBottom = '/svgs/female/clothes/pirate-LeftArmBtm.svg'
const pirateRightArmTop = '/svgs/female/clothes/pirate-RightArmTop.svg'
const pirateRightArmBottom = '/svgs/female/clothes/pirate-RightArmBtm.svg'
const priestLeftArmTop = '/svgs/female/clothes/priest-LeftArmTop.svg'
const priestLeftArmBottom = '/svgs/female/clothes/priest-LeftArmBtm.svg'
const priestRightArmTop = '/svgs/female/clothes/priest-RightArmTop.svg'
const priestRightArmBottom = '/svgs/female/clothes/priest-RightArmBtm.svg'
const thiefLeftArmTop = '/svgs/female/clothes/thief-LeftArmTop.svg'
const thiefLeftArmBottom = '/svgs/female/clothes/thief-LeftArmBtm.svg'
const thiefRightArmTop = '/svgs/female/clothes/thief-RightArmTop.svg'
const thiefRightArmBottom = '/svgs/female/clothes/thief-RightArmBtm.svg'
const warriorLeftArmTop = '/svgs/female/clothes/warrior-LeftArmTop.svg'
const warriorLeftArmBottom = '/svgs/female/clothes/warrior-LeftArmBtm.svg'
const warriorRightArmTop = '/svgs/female/clothes/warrior-RightArmTop.svg'
const warriorRightArmBottom = '/svgs/female/clothes/warrior-RightArmBtm.svg'
const wizardLeftArmTop = '/svgs/female/clothes/wizard-LeftArmTop.svg'
const wizardLeftArmBottom = '/svgs/female/clothes/wizard-LeftArmBtm.svg'
const wizardRightArmTop = '/svgs/female/clothes/wizard-RightArmTop.svg'
const wizardRightArmBottom = '/svgs/female/clothes/wizard-RightArmBtm.svg'
const archerWeapon = '/svgs/female/weapons/archer-weapon.svg'
const knightWeapon = '/svgs/female/weapons/knight-weapon.svg'
const monkWeapon = '/svgs/female/weapons/monk-weapon.svg'
const pirateWeapon = '/svgs/female/weapons/pirate-weapon.svg'
const priestWeapon = '/svgs/female/weapons/priest-weapon.svg'
const thiefWeapon = '/svgs/female/weapons/thief-weapon.svg'
const warriorWeapon = '/svgs/female/weapons/warrior-weapon.svg'
const wizardWeapon = '/svgs/female/weapons/wizard-weapon.svg'

/* ADVANCED HEROES */
const paladinLeftArmTop = '/svgs/female/clothes/advanced/paladin-LeftArmTop.svg'
const paladinLeftArmBottom =
  '/svgs/female/clothes/advanced/paladin-LeftArmBtm.svg'
const paladinRightArmTop =
  '/svgs/female/clothes/advanced/paladin-RightArmTop.svg'
const paladinRightArmBottom =
  '/svgs/female/clothes/advanced/paladin-RightArmBtm.svg'
const darkKnightLeftArmTop =
  '/svgs/female/clothes/advanced/darkKnight-LeftArmTop.svg'
const darkKnightLeftArmBottom =
  '/svgs/female/clothes/advanced/darkKnight-LeftArmBtm.svg'
const darkKnightRightArmTop =
  '/svgs/female/clothes/advanced/darkKnight-RightArmTop.svg'
const darkKnightRightArmBottom =
  '/svgs/female/clothes/advanced/darkKnight-RightArmBtm.svg'
const summonerLeftArmTop =
  '/svgs/female/clothes/advanced/summoner-LeftArmTop.svg'
const summonerLeftArmBottom =
  '/svgs/female/clothes/advanced/summoner-LeftArmBtm.svg'
const summonerRightArmTop =
  '/svgs/female/clothes/advanced/summoner-RightArmTop.svg'
const summonerRightArmBottom =
  '/svgs/female/clothes/advanced/summoner-RightArmBtm.svg'
const ninjaLeftArmTop = '/svgs/female/clothes/advanced/ninja-LeftArmTop.svg'
const ninjaLeftArmBottom = '/svgs/female/clothes/advanced/ninja-LeftArmBtm.svg'
const ninjaRightArmTop = '/svgs/female/clothes/advanced/ninja-RightArmTop.svg'
const ninjaRightArmBottom =
  '/svgs/female/clothes/advanced/ninja-RightArmBtm.svg'

/* ELITE HEROES */
const dragoonLeftArmTop = '/svgs/female/clothes/elite/dragoon-LeftArmTop.svg'
const dragoonLeftArmBottom = '/svgs/female/clothes/elite/dragoon-LeftArmBtm.svg'
const dragoonRightArmTop = '/svgs/female/clothes/elite/dragoon-RightArmTop.svg'
const dragoonRightArmBottom =
  '/svgs/female/clothes/elite/dragoon-RightArmBtm.svg'
const sageLeftArmTop = '/svgs/female/clothes/elite/sage-LeftArmTop.svg'
const sageLeftArmBottom = '/svgs/female/clothes/elite/sage-LeftArmBtm.svg'
const sageRightArmTop = '/svgs/female/clothes/elite/sage-RightArmTop.svg'
const sageRightArmBottom = '/svgs/female/clothes/elite/sage-RightArmBtm.svg'

/* LEGENDARY HEROES */
const dreadKnightLeftArmTop =
  '/svgs/female/clothes/legendary/dreadknight-LeftArmTop.svg'
const dreadKnightLeftArmBottom =
  '/svgs/female/clothes/legendary/dreadknight-LeftArmBtm.svg'
const dreadKnightRightArmTop =
  '/svgs/female/clothes/legendary/dreadknight-RightArmTop.svg'
const dreadKnightRightArmBottom =
  '/svgs/female/clothes/legendary/dreadknight-RightArmBtm.svg'
const paladinWeapon = '/svgs/female/weapons/paladin-weapon.svg'
const darkKnightWeapon = '/svgs/female/weapons/darkKnight-weapon.svg'
const summonerWeapon = '/svgs/female/weapons/summoner-weapon.svg'
const ninjaWeapon = '/svgs/female/weapons/ninja-weapon.svg'
const dragoonWeapon = '/svgs/female/weapons/dragoon-weapon.svg'
const sageWeapon = '/svgs/female/weapons/sage-weapon.svg'
const dreadKnightWeapon = '/svgs/female/weapons/dreadknight-weapon.svg'

const getClassInfo = (mainClass) => {
  switch (mainClass) {
    case 'archer': {
      return {
        classWeapon: archerWeapon,
        rightArmTop: archerRightArmTop,
        rightArmBottom: archerRightArmBottom,
        leftArmTop: archerLeftArmTop,
        leftArmBotttom: archerLeftArmBottom,
      }
    }
    case 'knight': {
      return {
        classWeapon: knightWeapon,
        rightArmTop: knightRightArmTop,
        rightArmBottom: knightRightArmBottom,
        leftArmTop: knightLeftArmTop,
        leftArmBotttom: knightLeftArmBottom,
      }
    }
    case 'monk': {
      return {
        classWeapon: monkWeapon,
        rightArmTop: monkRightArmTop,
        rightArmBottom: monkRightArmBottom,
        leftArmTop: monkLeftArmTop,
        leftArmBotttom: monkLeftArmBottom,
      }
    }
    case 'pirate': {
      return {
        classWeapon: pirateWeapon,
        rightArmTop: pirateRightArmTop,
        rightArmBottom: pirateRightArmBottom,
        leftArmTop: pirateLeftArmTop,
        leftArmBotttom: pirateLeftArmBottom,
      }
    }
    case 'priest': {
      return {
        classWeapon: priestWeapon,
        rightArmTop: priestRightArmTop,
        rightArmBottom: priestRightArmBottom,
        leftArmTop: priestLeftArmTop,
        leftArmBotttom: priestLeftArmBottom,
      }
    }
    case 'thief': {
      return {
        classWeapon: thiefWeapon,
        rightArmTop: thiefRightArmTop,
        rightArmBottom: thiefRightArmBottom,
        leftArmTop: thiefLeftArmTop,
        leftArmBotttom: thiefLeftArmBottom,
      }
    }
    case 'warrior': {
      return {
        classWeapon: warriorWeapon,
        rightArmTop: warriorRightArmTop,
        rightArmBottom: warriorRightArmBottom,
        leftArmTop: warriorLeftArmTop,
        leftArmBotttom: warriorLeftArmBottom,
      }
    }
    case 'wizard': {
      return {
        classWeapon: wizardWeapon,
        rightArmTop: wizardRightArmTop,
        rightArmBottom: wizardRightArmBottom,
        leftArmTop: wizardLeftArmTop,
        leftArmBotttom: wizardLeftArmBottom,
      }
    }
    case 'paladin': {
      return {
        classWeapon: paladinWeapon,
        rightArmTop: paladinRightArmTop,
        rightArmBottom: paladinRightArmBottom,
        leftArmTop: paladinLeftArmTop,
        leftArmBotttom: paladinLeftArmBottom,
      }
    }
    case 'darkKnight': {
      return {
        classWeapon: darkKnightWeapon,
        rightArmTop: darkKnightRightArmTop,
        rightArmBottom: darkKnightRightArmBottom,
        leftArmTop: darkKnightLeftArmTop,
        leftArmBotttom: darkKnightLeftArmBottom,
      }
    }
    case 'summoner': {
      return {
        classWeapon: summonerWeapon,
        rightArmTop: summonerRightArmTop,
        rightArmBottom: summonerRightArmBottom,
        leftArmTop: summonerLeftArmTop,
        leftArmBotttom: summonerLeftArmBottom,
      }
    }
    case 'ninja': {
      return {
        classWeapon: ninjaWeapon,
        rightArmTop: ninjaRightArmTop,
        rightArmBottom: ninjaRightArmBottom,
        leftArmTop: ninjaLeftArmTop,
        leftArmBotttom: ninjaLeftArmBottom,
      }
    }
    case 'dragoon': {
      return {
        classWeapon: dragoonWeapon,
        rightArmTop: dragoonRightArmTop,
        rightArmBottom: dragoonRightArmBottom,
        leftArmTop: dragoonLeftArmTop,
        leftArmBotttom: dragoonLeftArmBottom,
      }
    }
    case 'sage': {
      return {
        classWeapon: sageWeapon,
        rightArmTop: sageRightArmTop,
        rightArmBottom: sageRightArmBottom,
        leftArmTop: sageLeftArmTop,
        leftArmBotttom: sageLeftArmBottom,
      }
    }
    case 'dreadKnight': {
      return {
        classWeapon: dreadKnightWeapon,
        rightArmTop: dreadKnightRightArmTop,
        rightArmBottom: dreadKnightRightArmBottom,
        leftArmTop: dreadKnightLeftArmTop,
        leftArmBotttom: dreadKnightLeftArmBottom,
      }
    }
    default: {
      return {}
    }
  }
}

const RightArmTopFemale = ({
  armTop,
  armTopHighlight,
  armTopShadow,
  stroke,
}) => (
  <React.Fragment>
    <img src={armTop} className={styles.clothing} />
    <img src={armTopHighlight} className={styles.highlight} />
    <img src={armTopShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path
        stroke={stroke}
        d="M12 21h2M11 22h3M10 23h4M10 24h4M10 25h3M9 26h4M9 27h4M10 28h2"
      />
    </svg>
  </React.Fragment>
)

const RightArmBtmFemale = ({
  armBtm,
  armBtmHighlight,
  armBtmShadow,
  stroke,
}) => (
  <React.Fragment>
    <img src={armBtm} className={styles.clothing} />
    <img src={armBtmHighlight} className={styles.highlight} />
    <img src={armBtmShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path
        stroke={stroke}
        d="M9 26h3M9 27h3M8 28h4M8 29h4M7 30h4M6 31h5M6 32h4M7 33h2"
      />
    </svg>
  </React.Fragment>
)

const LeftArmTopFemale = ({
  armTop,
  armTopHighlight,
  armTopShadow,
  stroke,
}) => (
  <React.Fragment>
    <img src={armTop} className={styles.clothing} />
    <img src={armTopHighlight} className={styles.highlight} />
    <img src={armTopShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path
        stroke={stroke}
        d="M18 21h2M18 22h3M18 23h4M18 24h4M19 25h4M19 26h4M19 27h4M19 28h4"
      />
    </svg>
  </React.Fragment>
)

const LeftArmBtmFemale = ({
  armBtm,
  armBtmHighlight,
  armBtmShadow,
  stroke,
}) => (
  <React.Fragment>
    <img src={armBtm} className={styles.clothing} />
    <img src={armBtmHighlight} className={styles.highlight} />
    <img src={armBtmShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path
        stroke={stroke}
        d="M19 28h4M19 29h4M19 30h4M18 31h5M18 32h5M18 33h4M18 34h3"
      />
    </svg>
  </React.Fragment>
)

const FemaleArms = ({ mainClass, stroke }) => {
  const classInfo = getClassInfo(mainClass)

  return (
    <>
      <div className={`${styles.heroRightArm} ${styles.bodyPart}`}>
        <div className={`${styles.heroArmRightTop} ${styles.bodyPart}`}>
          <RightArmTopFemale
            armTop={classInfo.rightArmTop}
            armTopShadow={femaleRightarmtopshadow}
            armTopHighlight={femaleRightarmtophighlight}
            stroke={stroke}
          />
        </div>
        <div className={`${styles.heroArmRightBtm} ${styles.bodyPart}`}>
          <RightArmBtmFemale
            armBtm={classInfo.rightArmBottom}
            armBtmShadow={femaleRightarmbtmshadow}
            armBtmHighlight={femaleRightarmbtmhighlight}
            stroke={stroke}
          />
          {mainClass != 'monk' && (
            <div className={styles.heroWeapon}>
              <img src={classInfo.classWeapon} className={styles.clothing} />
            </div>
          )}
        </div>
      </div>
      <div className={`${styles.heroLeftArm} ${styles.bodyPart}`}>
        <div className={`${styles.heroArmLeftTop} ${styles.bodyPart}`}>
          <LeftArmTopFemale
            armTop={classInfo.leftArmTop}
            armTopShadow={femaleLeftarmtopshadow}
            armTopHighlight={femaleLeftarmtophighlight}
            stroke={stroke}
          />
        </div>
        <div className={`${styles.heroArmLeftBtm} ${styles.bodyPart}`}>
          <LeftArmBtmFemale
            armBtm={classInfo.leftArmBotttom}
            armBtmShadow={femaleLeftarmbtmshadow}
            armBtmHighlight={femaleLeftarmbtmhighlight}
            stroke={stroke}
          />
          {mainClass == 'monk' && (
            <div className={styles.heroWeapon}>
              <img src={classInfo.classWeapon} className={styles.clothing} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default FemaleArms
