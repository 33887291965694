const prefix = 'USER_MODAL'

const userModalActions = {
  MODAL_OPENED: `${prefix}_MODAL_OPENED`,
  MODAL_CLOSED: `${prefix}_MODAL_CLOSED`,
  CHANGE_FAVORITE_HERO: `${prefix}_HERO_CHANGED`,

  doOpenModal: (userAddress) => {
    return {
      type: userModalActions.MODAL_OPENED,
      payload: userAddress,
    }
  },

  doCloseModal: () => {
    return {
      type: userModalActions.MODAL_CLOSED,
    }
  },
}

export default userModalActions
