import Messages from '../../lib/messages'
import useContract from '../../hooks/useContract'
import { ConfigStore } from '../../modules/config/configStore'
import AffiliateContractAbi from '../../contracts/AffiliateContractAbi'
import styles from '../../styles/Profile.module.scss'
import modalStyles from '../../styles/Modals.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import promoCodeListModalActions from '../../modules/promoCodeListModal/promoCodeListModalActions'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import promoCodeListModalSelectors from '../../modules/promoCodeListModal/promoCodeListModalSelectors'
import Modal from 'react-modal'
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import Pagination from '../pagination'
import { BigNumber } from 'ethers'
import formatGold from '../../lib/formatGold'

export function PromoCodeListModal() {
  const [currentPage, setCurrentPage] = useState(0)
  const ITEMSPERPAGE = 10

  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const [promoCodeObjects, setPromoCodeObjects] = useState([])
  const [paginatedPromoCodes, setPaginatedPromoCodes] = useState([])
  const userAddress = useSelector(promoCodeListModalSelectors.selectUserAddress)
  const affiliateContract = useContract(
    ConfigStore.get().AFFILIATE_CONTRACT_ADDRESS,
    AffiliateContractAbi,
  )

  const fetchAllPromoCodes = async () => {
    try {
      const allCodesByUser =
        await affiliateContract.functions.fetch_all_promo_codes_owned_by_user(
          account,
        )
      const promoCodeObjects = []
      for (const promoCode of allCodesByUser[0]) {
        const code = await affiliateContract.functions.fetch_promo_code_owner(
          promoCode,
        )
        promoCodeObjects.push({ ...code[0], promoCode })
      }
      setPromoCodeObjects(promoCodeObjects)
    } catch (error) {
      console.error(error)
      closeModal()
    }
  }

  useEffect(() => {
    fetchAllPromoCodes().then()
  }, [account, Boolean(userAddress)])

  useEffect(() => {
    setPaginatedPromoCodes(
      promoCodeObjects.slice(
        ITEMSPERPAGE * currentPage,
        ITEMSPERPAGE * currentPage + ITEMSPERPAGE,
      ),
    )
  }, [currentPage, promoCodeObjects])

  const pageChange = (page) => {
    setCurrentPage(page)
  }

  const removePromoCode = async (promoCode) => {
    try {
      await affiliateContract.functions.remove_promo_code(promoCode)
      Messages.success(`Removed Promo Code for ${promoCode}`)
    } catch (error) {
      Messages.error(`No promo code to remove for ${promoCode}`)
      console.error(error)
    } finally {
      fetchAllPromoCodes().then()
      closeModal()
    }
  }

  function closeModal() {
    dispatch(promoCodeListModalActions.doCloseModal())
  }

  useDisableBodyScroll(Boolean(userAddress))

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={Boolean(userAddress)}
        onRequestClose={closeModal}
        contentLabel={'Promo Code List Modal'}
        className={`dfkModal ${modalStyles.userModal}`}
        closeTimeoutMS={200}
      >
        <div className={`heading dfkModalTitle ${modalStyles.rulesModalTitle}`}>
          {promoCodeObjects.length >= 1 ? 'Your Promo Codes' : 'No Promo Codes'}
        </div>
        {promoCodeObjects.length >= 1 && (
          <div className={styles.affiliateWrapper} style={{ width: '100%' }}>
            <div style={{ width: 150 }} className={modalStyles.rulesModalText}>
              Code
            </div>
            <div className={modalStyles.rulesModalText}>Users</div>
            <div className={modalStyles.rulesModalText}>Revenue</div>
            <div className={modalStyles.rulesModalText}>Action</div>
          </div>
        )}
        {paginatedPromoCodes.map((promoCodeObject, index) => (
          <div
            key={index}
            className={styles.affiliateWrapper}
            style={{ width: '100%' }}
          >
            <div style={{ width: 150 }} className={modalStyles.rulesModalText}>
              {promoCodeObject.promoCode}
            </div>
            <div
              style={{ width: 80, textAlign: 'right' }}
              className={modalStyles.rulesModalText}
            >
              {BigNumber.from(promoCodeObject.signups).toNumber()}
            </div>
            <div
              style={{ width: 80, textAlign: 'right' }}
              className={modalStyles.rulesModalText}
            >
              {formatGold(
                BigNumber.from(promoCodeObject.accumulated_earnings).toString(),
              )}
            </div>
            <div className={styles.affiliateButtons}>
              <button
                onClick={() => removePromoCode(promoCodeObject.promoCode)}
                className={`btn btn--default btn--blue`}
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  cursor: 'pointer',
                }}
              >
                Delete Code
              </button>
            </div>
          </div>
        ))}
        <Pagination
          currentPage={currentPage}
          itemsPerPage={ITEMSPERPAGE}
          totalCount={promoCodeObjects.length}
          onPageChange={pageChange}
        />
        <button
          className={`btn dfkModal__close`}
          aria-label={`Close Modal`}
          onClick={closeModal}
        />
      </Modal>
    </>
  )
}
