import createRootReducer from './reducers'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunkMiddleware from 'redux-thunk'

let store

export function configureStore(preloadedState?) {
  const middlewares = [thunkMiddleware].filter(Boolean)

  store = createStore(
    createRootReducer(),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )

  // for (const initializer of initializers) {
  //   initializer(store);
  // }

  return store
}

export default function getStore() {
  return store
}
