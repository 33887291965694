import { v4 as uuid } from 'uuid'
import AdminModeratorService from '../adminModeratorService'
import adminModeratorMuteSelectors from './adminModeratorMuteSelectors'

const prefix = 'ADMIN_MODERATOR_MUTE'

const adminModeratorMuteActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  doChangePage: (publicAddress, renderedPage) => async (dispatch, getState) => {
    const itemsPerPage = adminModeratorMuteSelectors.selectItemsPerPage(
      getState(),
    )
    dispatch(
      adminModeratorMuteActions.doFetch(
        publicAddress,
        renderedPage,
        itemsPerPage,
      ),
    )
  },

  doRefresh: (publicAddress) => async (dispatch, getState) => {
    const renderedPage = adminModeratorMuteSelectors.selectRenderedPage(
      getState(),
    )
    const itemsPerPage = adminModeratorMuteSelectors.selectItemsPerPage(
      getState(),
    )
    dispatch(
      adminModeratorMuteActions.doFetch(
        publicAddress,
        renderedPage,
        itemsPerPage,
      ),
    )
  },

  doFetch:
    (publicAddress, renderedPage, itemsPerPage, hideLoading = false) =>
    async (dispatch, getState) => {
      try {
        const fetchId = uuid()

        dispatch({
          type: adminModeratorMuteActions.FETCH_STARTED,
          payload: {
            fetchId,
            renderedPage,
            itemsPerPage,
            hideLoading,
          },
        })

        const data = await AdminModeratorService.fetchMuted(
          publicAddress,
          renderedPage,
          itemsPerPage,
        )

        if (fetchId === adminModeratorMuteSelectors.selectFetchId(getState())) {
          dispatch({
            type: adminModeratorMuteActions.FETCH_SUCCESS,
            payload: { rows: data.rows, count: data.count },
          })
        }
      } catch (error) {
        console.error(error)
        dispatch({
          type: adminModeratorMuteActions.FETCH_ERROR,
        })
      }
    },
}

export default adminModeratorMuteActions
