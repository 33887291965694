import Image from 'next/image'
import Link from 'next/link'
import styles from '../styles/Header.module.scss'
import Account from './account'
import AudioManager from './audioManager'
import HeaderWallet from './headerWallet'

// import sprite from '../img/sprite.svg';

export default function Header() {
  return (
    <header className={styles.header}>
      <Link href="/">
        <a className={styles.logo} aria-label="Home">
          <Image alt="Logo" src={'/logo.svg'} width={296} height={62} />
        </a>
      </Link>
      <nav className={styles.nav}>
        <Link href="/">
          <a className={`${styles.navLink} ${styles.navLinkActive}`}>
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6.54545 0H1.63636C0.733909 0 0 0.748333 0 1.66667V7.5C0 8.41833 0.733909 9.16667 1.63636 9.16667H6.54545C7.44791 9.16667 8.18182 8.41833 8.18182 7.5V1.66667C8.18182 0.748333 7.44791 0 6.54545 0Z" />
              <path d="M16.3636 0H11.4545C10.5521 0 9.81818 0.748333 9.81818 1.66667V7.5C9.81818 8.41833 10.5521 9.16667 11.4545 9.16667H16.3636C17.2661 9.16667 18 8.41833 18 7.5V1.66667C18 0.748333 17.2661 0 16.3636 0Z" />
              <path d="M6.54545 10.8333H1.63636C0.733909 10.8333 0 11.5817 0 12.5V18.3333C0 19.2525 0.733909 20 1.63636 20H6.54545C7.44791 20 8.18182 19.2525 8.18182 18.3333V12.5C8.18182 11.5817 7.44791 10.8333 6.54545 10.8333Z" />
              <path d="M16.3636 10.8333H11.4545C10.5521 10.8333 9.81818 11.5817 9.81818 12.5V18.3333C9.81818 19.2525 10.5521 20 11.4545 20H16.3636C17.2661 20 18 19.2525 18 18.3333V12.5C18 11.5817 17.2661 10.8333 16.3636 10.8333Z" />
            </svg>
            Stat 1 v 1
          </a>
        </Link>
        <Link href="/faq">
          <a className={styles.navLink}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              stroke="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM10 15.8036C9.5067 15.8036 9.10714 15.404 9.10714 14.9107C9.10714 14.4174 9.5067 14.0179 10 14.0179C10.4933 14.0179 10.8929 14.4174 10.8929 14.9107C10.8929 15.404 10.4933 15.8036 10 15.8036ZM11.404 10.904C11.2019 10.9821 11.0279 11.1192 10.9049 11.2976C10.7819 11.476 10.7154 11.6873 10.7143 11.904V12.4107C10.7143 12.5089 10.6339 12.5893 10.5357 12.5893H9.46429C9.36607 12.5893 9.28571 12.5089 9.28571 12.4107V11.9308C9.28571 11.4152 9.43527 10.9062 9.72991 10.4821C10.0179 10.067 10.4196 9.75 10.8929 9.5692C11.6518 9.27679 12.1429 8.64063 12.1429 7.94643C12.1429 6.96205 11.1808 6.16071 10 6.16071C8.8192 6.16071 7.85714 6.96205 7.85714 7.94643V8.11607C7.85714 8.21429 7.77679 8.29464 7.67857 8.29464H6.60714C6.50893 8.29464 6.42857 8.21429 6.42857 8.11607V7.94643C6.42857 7.0692 6.8125 6.25 7.50893 5.64063C8.17857 5.05357 9.0625 4.73214 10 4.73214C10.9375 4.73214 11.8214 5.0558 12.4911 5.64063C13.1875 6.25 13.5714 7.0692 13.5714 7.94643C13.5714 9.23661 12.721 10.3973 11.404 10.904Z" />
            </svg>
            faq
          </a>
        </Link>
      </nav>
      <div style={{ marginRight: 16, display: 'flex' }}>
        <AudioManager />
      </div>
      <HeaderWallet />
      <Account />
    </header>
  )
}
