import { toLower } from 'lodash'
import { ConfigStore } from '../modules/config/configStore'

export default function isModerator(publicAddress: string | undefined) {
  if (!publicAddress) {
    return false
  }

  const moderatorWallets = String(
    toLower(ConfigStore.get().MODERATOR_ADDRESSES),
  ).split(',')
  return moderatorWallets.includes(toLower(publicAddress))
}
