import { createSelector } from 'reselect'

const selectRaw = (state) => state.userModal

const selectUserAddress = createSelector([selectRaw], (raw) => raw.userAddress)

const userModalSelectors = {
  selectRaw,
  selectUserAddress,
}

export default userModalSelectors