import balanceActions from './balanceActions'

const initialData = {
  state: 'loading',
  goldBalance: 0,
  jewelBalance: 0,
  withdrawHistory: [],
  depositHistory: [],
  renderedDepositPage: 0,
  renderedWithdrawPage: 0,
  itemsPerPage: 25,
}

const balanceReducers = (state = initialData, { type, payload }) => {
  if (type === balanceActions.DEPOSIT_PAGINATION_CHANGED) {
    return {
      ...state,
      renderedDepositPage: payload,
    }
  }
  if (type === balanceActions.WITHDRAW_PAGINATION_CHANGED) {
    return {
      ...state,
      renderedWithdrawPage: payload,
    }
  }

  if (type === balanceActions.CLEAR) {
    return {
      ...initialData,
    }
  }

  if (type === balanceActions.FETCH_STARTED) {
    if (payload.skipLoading) {
      return {
        ...state,
        fetchId: payload.fetchId,
      }
    }

    return {
      ...initialData,
      fetchId: payload.fetchId,
    }
  }

  if (type === balanceActions.FETCH_SUCCESS) {
    return {
      ...state,
      ...payload,
      state: 'idle',
    }
  }

  if (type === balanceActions.FETCH_ERROR) {
    return {
      ...initialData,
      state: 'error',
    }
  }

  return state
}

export default balanceReducers
