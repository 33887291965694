const PREFIX = 'PROMO_CODE_LIST'

export const promoCodeListModalActions = {
  MODAL_OPENED: `${PREFIX}_MODAL_OPENED`,
  MODAL_CLOSED: `${PREFIX}_MODAL_CLOSED`,

  doOpenModal: (userAddress) => {
    return {
      type: promoCodeListModalActions.MODAL_OPENED,
      payload: userAddress,
    }
  },

  doCloseModal: () => {
    return {
      type: promoCodeListModalActions.MODAL_CLOSED,
    }
  },
}

export default promoCodeListModalActions