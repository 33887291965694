import authActions from './authActions'

const initialData = {
  modalOpen: false,
  profile: null,
}

const authReducers = (state = initialData, { type, payload }) => {
  if (type === authActions.PROFILE_CHANGED) {
    return {
      ...state,
      profile: payload,
    }
  }

  if (type === authActions.MODAL_CLOSED) {
    return {
      ...state,
      modalOpen: false,
    }
  }

  if (type === authActions.MODAL_OPENED) {
    return {
      ...state,
      modalOpen: true,
    }
  }

  return state
}

export default authReducers
