import { v4 as uuid } from 'uuid'
import RankingService from './rankingService'
import rankingSelectors from './rankingSelectors'

const prefix = 'RANKING'

const rankingActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  REFRESH_DATA: `${prefix}_REFRESH_DATA`,
  ORDER_CHANGED: `${prefix}_ORDER_CHANGED`,
  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  doChangeOrder: (order) => async (dispatch, getState) => {
    const itemsPerPage = rankingSelectors.selectItemsPerPage(getState())
    const renderedPage = rankingSelectors.selectRenderedPage(getState())
    const direction = rankingSelectors.selectDirection(getState())
    let sortOrder: string
    switch (direction) {
      case ('ASC'):
        sortOrder = 'DESC'
        break
      case ('DESC'):
        sortOrder = 'ASC'
        break
      default:
        sortOrder = 'DESC'
    }
    dispatch(rankingActions.doFetch(renderedPage, itemsPerPage, order, sortOrder))
  },

  doChangePage: (renderedPage) => async (dispatch, getState) => {
    const itemsPerPage = rankingSelectors.selectItemsPerPage(getState())
    const order = rankingSelectors.selectOrder(getState())
    const direction = rankingSelectors.selectDirection(getState())
    dispatch({
      type: rankingActions.ORDER_CHANGED,
      payload: { order, direction: direction },
    })
  },

  doFetch: (renderedPage, itemsPerPage, order, direction, hideLoading = false) => async (dispatch, getState) => {
    try {
      const fetchId = uuid()

      dispatch({
        type: rankingActions.FETCH_STARTED,
        payload: {
          order,
          direction,
          fetchId,
          renderedPage,
          itemsPerPage,
          hideLoading,
        },
      })

      const data = await RankingService.fetch(
        order,
        direction,
        renderedPage,
        itemsPerPage,
      )

      if (fetchId === rankingSelectors.selectFetchId(getState())) {
        dispatch({
          type: rankingActions.FETCH_SUCCESS,
          payload: { rows: data.rows, count: data.count },
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({ type: rankingActions.FETCH_ERROR })
    }
  },
}

export default rankingActions
