import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import Image from 'next/image'
import React from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { injected } from '../connectors'
import { useDisableBodyScroll } from '../hooks/useDisableBodyScroll'
import useMetaMaskOnboarding from '../hooks/useMetaMaskOnboarding'
import { switchToHarmonyNetwork } from '../lib/switchToHarmonyNetwork'
import authActions from '../modules/auth/authActions'
import authSelectors from '../modules/auth/authSelectors'
import styles from '../styles/Modals.module.scss'

export function AuthModal() {
  const dispatch = useDispatch()
  const isOpen = useSelector(authSelectors.selectModalOpen)

  const { activate, error, setError } = useWeb3React()

  const { isWeb3Available, startOnboarding } = useMetaMaskOnboarding()

  useDisableBodyScroll(isOpen)

  function closeModal() {
    dispatch(authActions.doCloseModal())
  }

  const isUnsupportedNetwork = error && error instanceof UnsupportedChainIdError

  async function onClick() {
    activate(injected).then(() => {
      if (!isUnsupportedNetwork) {
        closeModal()
      }
    })
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Auth Modal"
        className={`dfkModal ${styles.authModal}`}
        closeTimeoutMS={200}
      >
        <Image
          alt="Metamask logo"
          src={'/metamask-logo.svg'}
          width={69}
          height={66}
        />
        {isUnsupportedNetwork ? (
          <>
            <div className={styles.authModalText}>
              You are on a different network.
            </div>
            <button
              className="btn btn--default btn--blue"
              style={{ paddingLeft: 10, paddingRight: 10 }}
              onClick={() => switchToHarmonyNetwork(setError)}
            >
              Switch to {process.env.NEXT_PUBLIC_CHAIN_NAME}
            </button>
          </>
        ) : (
          <>
            <div className={styles.authModalText}>
              Please, Log In <br /> with your Metamask
            </div>
            {isWeb3Available ? (
              <button
                onClick={onClick}
                className={`btn btn--large btn--blue ${styles.authModalBtn}`}
              >
                Log In
              </button>
            ) : (
              <button
                className="btn btn--default btn--blue"
                style={{ paddingLeft: 10, paddingRight: 10 }}
                onClick={startOnboarding}
              >
                Install Metamask
              </button>
            )}
          </>
        )}
      </Modal>
    </>
  )
}
