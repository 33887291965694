import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import React, { useState } from 'react'
import Select from 'react-select'
import useTokenBalance from '../../hooks/useTokenBalance'
import useTokenContract from '../../hooks/useTokenContract'
import Messages from '../../lib/messages'
import { ConfigStore } from '../../modules/config/configStore'
import styles from '../../styles/Modals.module.scss'
import { InputFloat } from '../inputFloat'
import numeral from 'numeral'
import { useDispatch } from 'react-redux'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import { Web3Errors } from '../../modules/error/Web3Errors'
import ChatService from '../../modules/chat/chatService'

export default function UserModalTip(props) {
  const { userProfile, onTipComplete } = props
  const [loading, setLoading] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  const goldContract = useTokenContract(ConfigStore.get().GOLD_COIN_ADDRESS)
  const jewelContract = useTokenContract(ConfigStore.get().JEWEL_COIN_ADDRESS)

  const { data: goldBalanceInWallet, mutate: refreshGoldBalanceInWallet } =
    useTokenBalance(account, ConfigStore.get().GOLD_COIN_ADDRESS)
  const { data: jewelBalanceInWallet, mutate: refreshJewelBalanceInWallet } =
    useTokenBalance(account, ConfigStore.get().JEWEL_COIN_ADDRESS)

  const [coin, setCoin] = useState({
    value: 'gold',
    label: 'Gold',
  })
  const [tip, setTip] = useState('')

  async function doTipGold() {
    try {
      setLoading(true)

      const decimalPlaces = await goldContract.decimals()

      if (
        !goldBalanceInWallet ||
        goldBalanceInWallet.lte(
          ethers.utils.parseUnits(String(tip), decimalPlaces),
        )
      ) {
        setLoading(false)
        Messages.error('Not enough GOLD in wallet to tip.')
        return
      }

      dispatch(
        persistentToastActions.doAddToast(
          'userModalTip',
          'Waiting for transfer confirmation...',
        ),
      )

      const transaction = await goldContract.transfer(
        userProfile.publicAddress,
        ethers.utils.parseUnits(String(tip), decimalPlaces),
      )

      const receipt = await transaction.wait()

      await ChatService.createTip(
        account,
        receipt.transactionHash,
        coin.value.toUpperCase(),
      )

      Messages.success(
        `Successfully sent ${numeral(tip).format('0,0.[00]')} G tip to ${
          userProfile?.name || userProfile.publicAddress
        }.`,
      )

      setLoading(false)
      setTip('')
    } catch (error) {
      Web3Errors.handle(error)
      setLoading(false)
    } finally {
      dispatch(persistentToastActions.doRemoveToast('userModalTip'))
    }
  }

  async function doTipJewel() {
    try {
      setLoading(true)

      const decimalPlaces = await jewelContract.decimals()

      if (
        !jewelBalanceInWallet ||
        jewelBalanceInWallet.lte(
          ethers.utils.parseUnits(String(tip), decimalPlaces),
        )
      ) {
        setLoading(false)
        Messages.error('Not enough JEWEL in wallet to tip.')
        return
      }

      dispatch(
        persistentToastActions.doAddToast(
          'userModalTip',
          'Waiting for transfer confirmation...',
        ),
      )

      const transaction = await jewelContract.transfer(
        userProfile.publicAddress,
        ethers.utils.parseUnits(String(tip), decimalPlaces),
      )

      const receipt = await transaction.wait()

      await ChatService.createTip(
        account,
        receipt.transactionHash,
        coin.value.toUpperCase(),
      )

      Messages.success(
        `Successfully sent ${numeral(tip).format('0,0.[00]')} J tip to ${
          userProfile?.name || userProfile.publicAddress
        }.`,
      )

      setLoading(false)
      setTip('')
    } catch (error) {
      Web3Errors.handle(error)
      setLoading(false)
    } finally {
      dispatch(persistentToastActions.doRemoveToast('userModalTip'))
    }
  }

  return (
    <div className={styles.userModalTip}>
      <Select
        className={`custom-select h40 ${styles.userModalTipSelect}`}
        classNamePrefix="custom-select"
        options={[
          { value: 'gold', label: 'Gold' },
          { value: 'jewel', label: 'Jewel' },
        ]}
        placeholder={'Choose a coin'}
        onChange={(v) => setCoin(v)}
        value={coin}
      />
      <InputFloat
        type="text"
        placeholder="Tip"
        className={`custom-input h40 ${
          coin?.value === 'gold'
            ? styles.userModalTipSelectInputGold
            : styles.userModalTipSelectInputJewel
        }`}
        onChange={(e) => setTip(e.target.value)}
        value={tip}
      />
      <button
        disabled={!tip || loading}
        className={`btn btn--default btn--green ${styles.userModalTipBtn}`}
        onClick={coin?.value === 'gold' ? doTipGold : doTipJewel}
      >
        Send
      </button>
    </div>
  )
}
