import adminBadgeActions from './adminBadgeActions'

const initialData = {
  state: 'idle',
  rows: [],
  count: 0,
  renderedPage: 0,
  itemsPerPage: 25,
  fetchId: null,
}

const adminBadgeReducers = (state = initialData, { type, payload }) => {
  if (type === adminBadgeActions.FETCH_STARTED) {
    return {
      ...state,
      state: payload.hideLoading ? state.state : 'loading',
      fetchId: payload.fetchId,
      renderedPage: payload.renderedPage,
      itemsPerPage: payload.itemsPerPage,
    }
  }

  if (type === adminBadgeActions.FETCH_SUCCESS) {
    return {
      ...state,
      state: 'idle',
      rows: payload.rows,
      count: payload.count,
    }
  }

  if (type === adminBadgeActions.FETCH_ERROR) {
    return {
      ...state,
      state: 'error',
    }
  }

  return state
}

export default adminBadgeReducers
