import axios from 'axios'
import { toLower } from 'lodash'
import Web3 from 'web3'
import ProfilesContractAbi from '../../contracts/ProfilesContractAbi'
import providerMainnetUrlForRetry from '../../lib/providerMainnetUrlForRetry'
import { sleep } from '../../lib/sleep'
import toStringOrNull from '../../lib/toStringOrNull'
import { AuthToken } from '../auth/authToken'
import { ConfigStore } from '../config/configStore'

const userProfileCache = {}
export class UserProfileService {
  static async findBadges(address: string) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${address}/badges`,
    )

    return data
  }

  static async updateBadge(
    currentUserAddress,
    address: string,
    operation: 'add' | 'remove',
    badge: string,
  ) {
    const { data } = await axios.put(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${address}/badges`,
      {
        address,
        operation,
        badge,
      },
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(currentUserAddress)}`,
        },
      },
    )

    return data
  }

  static async updateChatBadge(
    currentUserAddress,
    operation: 'add' | 'remove',
    badge: string,
  ) {
    const { data } = await axios.put(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/badges/chat`,
      {
        operation,
        badge,
      },
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(currentUserAddress)}`,
        },
      },
    )

    return data
  }

  static async findProfileStats(address: string) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${address}/stats`,
    )

    return data
  }

  static async findProfileByAddress(address: string) {
    const blockChainData = await this.findProfileByAddressFromBlockchain(
      address,
    )

    if (blockChainData.notRegistered) {
      return blockChainData
    }

    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${address}`,
    )

    return { ...data, ...blockChainData }
  }

  static async findRankByAddress(address: string) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/${address}/rank`,
    )

    return data.rank
  }

  static async _getProfile(address: string, retry: number) {
    try {
      retry = Number(retry) || 0

      const web3Instance = new Web3(
        // Profile fetches always from MainNet
        new Web3.providers.HttpProvider(providerMainnetUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(
        ProfilesContractAbi,
        ConfigStore.get().PROFILES_CONTRACT_ADDRESS,
      )
      return await contract.methods.getProfile(address).call()
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 100)
        return await this._getProfile(address, retry + 1)
      }

      throw error
    }
  }

  static async findProfileByAddressFromBlockchain(address: string) {
    if (!process.browser) {
      return {
        address,
      }
    }

    let realAddress = address

    if (ConfigStore.get().DFK_ENVIRONMENT !== 'production') {
      if (
        toLower(address) ===
        toLower('0x041266DA57836664BbcC2bfC4Cf676987E045fEF')
      ) {
        address = '0xe58089465C9825fD8eeE6b9Db48000a059d5fAAd'
      }

      if (
        toLower(address) ===
        toLower('0xbb6cEeF08bA220161B489ad036DfDF98e9768Fd3')
      ) {
        address = '0xa8DEB26ca351A843eAC39451AdBd438E2eddF128'
      }
    }

    if (userProfileCache[address]) {
      return userProfileCache[address]
    }

    let profile = null
    try {
      profile = await this._getProfile(address, 0)
    } catch (error) {
      console.error(error)
    }

    if (!profile) {
      profile = {
        address: realAddress,
        notRegistered: true,
      }
    } else {
      const rank = await this.findRankByAddress(realAddress)
      profile = {
        address: realAddress,
        name: toStringOrNull(profile.name),
        nftId: toStringOrNull(profile.nftId),
        collectionId: toStringOrNull(profile.collectionId),
        isHeroAvatar: toStringOrNull(profile.collectionId) === '1',
        picUri: toStringOrNull(profile.picUri),
        rank,
      }
    }

    userProfileCache[address] = profile

    return profile
  }

  static async updateFavoriteHero(address: string, hero) {
    const { data } = await axios.put(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/profile/favorite-hero`,
      {
        publicAddress: address,
        favoriteHeroId: hero.id || hero.value,
      },
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(address)}`,
        },
      },
    )

    return data
  }
}
