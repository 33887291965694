import { ethers } from 'ethers'
import Web3 from 'web3'
import providerUrlForRetry from '../../lib/providerUrlForRetry'
import { sleep } from '../../lib/sleep'
import getOlderVersionsConfig from './olderVersionsConfig'

export class OlderVersionsBattleService {
  static async fetchAllDataFromAllVersions(account) {
    let goldAmount = ethers.BigNumber.from('0')
    let jewelAmount = ethers.BigNumber.from('0')
    let openBattles = []

    const skipCurrent = (v) => !v.current
    for (let olderVersion of getOlderVersionsConfig().filter(skipCurrent)) {
      if (olderVersion.escrowContractAddress) {
        if (goldAmount) {
          goldAmount = goldAmount.add(
            await this.fetchGoldBalance(
              account,
              olderVersion.escrowContractAddress,
              olderVersion.escrowContractAbi,
              0,
            ),
          )
        }

        if (jewelAmount) {
          jewelAmount = jewelAmount.add(
            await this.fetchJewelBalance(
              account,
              olderVersion.escrowContractAddress,
              olderVersion.escrowContractAbi,
              0,
            ),
          )
        }
      }

      if (olderVersion.battleContractAddress) {
        openBattles = [
          ...openBattles,
          ...(await this.fetchOpenBattlesOfUser(
            account,
            olderVersion.battleContractAddress,
            olderVersion.battleContractAbi,
            olderVersion.duelStatusEnumerator,
          )),
        ]
      }
    }

    return { goldAmount, jewelAmount, openBattles }
  }

  static async fetchOpenBattlesOfUser(
    account,
    address,
    abi,
    duelStatusEnumerator,
  ): Promise<
    Array<{
      pairId: string
      status: string
    }>
  > {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)
    const battleContract = new web3Instance.eth.Contract(abi, address)
    const pairIds = []
    let validStatuses = [
      'EMPTY',
      'FIRSTPLAYERIN',
      'SECONDPLAYERIN',
      'BOTHCONFIRMED',
      'GAMEFINISHED',
    ]

    let ended = false
    let i = 0
    while (!ended) {
      try {
        const pairId = (
          await battleContract.methods.pairings_involved(account, i).call()
        ).toString()

        i++

        const pairingStatus = (
          await battleContract.methods.pairings_status(pairId).call()
        ).toString()

        if (validStatuses.includes(duelStatusEnumerator[pairingStatus])) {
          pairIds.push({
            pairId,
            status: duelStatusEnumerator[pairingStatus],
          })
        }
      } catch (error) {
        ended = true
      }
    }

    return pairIds
  }

  static async postGame(account, address, abi, pairId) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)

    const battleContract = new web3Instance.eth.Contract(abi, address)

    await battleContract.methods
      .post_fight_updates(pairId)
      .send({ from: account })
  }

  static async abandonPostPairing(account, address, abi, pairId) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)

    const battleContract = new web3Instance.eth.Contract(abi, address)

    await battleContract.methods
      .abandon_game_post_pairing(pairId)
      .send({ from: account })
  }

  static async abandonPrePairing(account, address, abi, pairId) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)

    const battleContract = new web3Instance.eth.Contract(abi, address)

    await battleContract.methods
      .abandon_game_pre_pairing(pairId)
      .send({ from: account })
  }

  static async fetchGoldBalance(account, address, abi, retry: number) {
    try {
      retry = Number(retry) || 0
      const web3Instance = new Web3(
        new Web3.providers.HttpProvider(providerUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(abi, address)

      const goldBalance = await contract.methods.gold_balance(account).call()
      return goldBalance
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchGoldBalance(account, address, abi, retry + 1)
      }

      throw error
    }
  }

  static async fetchJewelBalance(account, address, abi, retry: number) {
    try {
      retry = Number(retry) || 0
      const web3Instance = new Web3(
        new Web3.providers.HttpProvider(providerUrlForRetry(retry)),
      )
      const contract = new web3Instance.eth.Contract(abi, address)

      const jewelBalance = await contract.methods.jewel_balance(account).call()
      return jewelBalance
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchJewelBalance(account, address, abi, retry + 1)
      }

      throw error
    }
  }

  static async withdrawGold(account, address, abi, amount) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)
    const contract = new web3Instance.eth.Contract(abi, address)

    await contract.methods.withdraw_gold(String(amount)).send({ from: account })
  }

  static async withdrawJewel(account, address, abi, amount) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)
    const contract = new web3Instance.eth.Contract(abi, address)

    await contract.methods
      .withdraw_jewel(String(amount))
      .send({ from: account })
  }
}
