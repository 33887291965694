import { max, sortBy } from 'lodash'
import moment from 'moment'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import duelStatusEnumerator from '../../enumerators/duelStatusEnumerator'
import isAddressEqual from '../../lib/isAddressEqual'
import providerUrlForRetry from '../../lib/providerUrlForRetry'
import { sleep } from '../../lib/sleep'
import { ConfigStore } from '../config/configStore'
import HeroService from '../hero/heroService'
import { LiveGamesHeroStore } from './liveGamesHeroStore'

export default class LiveGamesService {
  static async storeHeroOnLastGame(account, heroId) {
    const liveGames: any = await this._fetchAllLiveBattles(account)
    const liveGameWithoutHero = liveGames.find(
      (liveGame) => liveGame.isJoined && !liveGame.hero,
    )
    if (liveGameWithoutHero) {
      LiveGamesHeroStore.setHeroTo(account, liveGameWithoutHero.pairId, heroId)
    }
  }

  static async fetch(account) {
    let battles = sortBy(
      [
        ...(await this._fetchAllLiveBattles(account)),
        ...(await this._fetchUserLiveBattles(account)),
      ],
      'createdAt',
    ).reverse()

    return battles
  }

  static async _fetchBattlesOfUser(account, retry: number) {
    try {
      retry = Number(retry) || 0
      const web3Instance = new Web3(providerUrlForRetry(0))
      const contract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      return await contract.methods.fetch_battles_of_user(account).call()
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this._fetchBattlesOfUser(account, retry + 1)
      }

      throw error
    }
  }

  static async _fetchOpenBattles(retry: number) {
    try {
      retry = Number(retry) || 0
      const web3Instance = new Web3(providerUrlForRetry(0))
      const contract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      return await contract.methods.fetch_open_battles().call()
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this._fetchOpenBattles(retry + 1)
      }

      throw error
    }
  }

  static async _fetchUserLiveBattles(account) {
    if (!account) {
      return []
    }

    let battles = []

    if (account) {
      battles = await this._fetchBattlesOfUser(account, 0)
    }

    battles = (
      await Promise.all(
        battles.map(async (battle) => {
          if (!battle || !battle.gamer_pair) {
            return null
          }

          const pairId = battle.pair_id.toString()
          const status = duelStatusEnumerator[battle.status]

          if (!['BOTHCONFIRMED', 'GAMEFINISHED'].includes(status)) {
            return null
          }

          const currentUserPair = battle.gamer_pair.find((pair) => {
            return account === pair.gamer_address
          })
          const otherUserPair = battle.gamer_pair.find((pair) => {
            return account !== pair.gamer_address
          })
          const anyPair = battle.gamer_pair[0]

          const bet = anyPair.gold_staked.toString()

          const closesAt = moment
            .unix(
              max(
                [
                  currentUserPair?.creation_time,
                  otherUserPair?.creation_time,
                ].filter(Boolean),
              ),
            )
            .add(ConfigStore.get().BATTLE_TIMEOUT_IN_SECONDS, 'seconds')
            .toDate()

          let hero

          const heroId = LiveGamesHeroStore.getHeroOf(account, pairId)
          if (heroId) {
            hero = await HeroService.fetch(heroId)
          }

          const bothConfirmedTime = currentUserPair?.both_confirmed_timestamp
            ? moment.unix(currentUserPair?.both_confirmed_timestamp)
            : null

          return {
            status,
            bet,
            hero,
            pairId,
            closesAt,
            bothConfirmedTime,
          }
        }),
      )
    ).filter(Boolean)

    return battles
  }

  static async _fetchAllLiveBattles(account) {
    const battles = await this._fetchOpenBattles(0)

    return (
      await Promise.all(
        battles.map(async (battle) => {
          if (!battle || !battle.gamer_pair) {
            return null
          }

          const pairId = battle.pair_id.toString()

          const status = duelStatusEnumerator[battle.status]

          if (!['FIRSTPLAYERIN'].includes(status)) {
            return null
          }

          const currentUserPair = battle.gamer_pair.find((pair) => {
            return account && account === pair.gamer_address
          })

          const otherUserPair = battle.gamer_pair.find((pair) => {
            return !account || account !== pair.gamer_address
          })

          const anyPair = battle.gamer_pair[0]

          const bet = anyPair.gold_staked.toString()

          const createdAt = moment
            .unix(anyPair.creation_time.toString())
            .toDate()

          const startsAt = moment(createdAt)
            .add(ConfigStore.get().BATTLE_TIMEOUT_IN_SECONDS, 'seconds')
            .toDate()

          let hero
          const heroId = LiveGamesHeroStore.getHeroOf(account, pairId)
          if (heroId) {
            hero = await HeroService.fetch(heroId)
          }

          return {
            status,
            bet,
            createdAt,
            startsAt,
            hero,
            pairId,
            isJoined: Boolean(currentUserPair),
          }
        }),
      )
    ).filter(Boolean)
  }

  static async isPostDuel(account, pairId, contract) {
    try {
      const battle = await contract.methods
        .fetch_individual_battle(pairId)
        .call()

      if (duelStatusEnumerator[battle.status] !== 'GAMEFINISHED') {
        return false
      }

      return battle.gamer_pair.some((pair) =>
        isAddressEqual(pair.gamer_address, account),
      )
    } catch (error) {
      console.error(error)
      return false
    }
  }
}
