import { v4 as uuid } from 'uuid'
import AdminModeratorService from '../../adminModerator/adminModeratorService'
import adminBadgeSelectors from './adminBadgeSelectors'

const prefix = 'ADMIN_MODERATOR_BADGE'

const adminBadgeActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  doChangePage: (publicAddress, renderedPage) => async (dispatch, getState) => {
    const itemsPerPage = adminBadgeSelectors.selectItemsPerPage(getState())
    dispatch(
      adminBadgeActions.doFetch(publicAddress, renderedPage, itemsPerPage),
    )
  },

  doFetch:
    (publicAddress, renderedPage, itemsPerPage, hideLoading = false) =>
    async (dispatch, getState) => {
      try {
        const fetchId = uuid()

        dispatch({
          type: adminBadgeActions.FETCH_STARTED,
          payload: {
            fetchId,
            renderedPage,
            itemsPerPage,
            hideLoading,
          },
        })

        const data = await AdminModeratorService.fetchBadges(
          publicAddress,
          renderedPage,
          itemsPerPage,
        )

        if (fetchId === adminBadgeSelectors.selectFetchId(getState())) {
          dispatch({
            type: adminBadgeActions.FETCH_SUCCESS,
            payload: { rows: data.rows, count: data.count },
          })
        }
      } catch (error) {
        console.error(error)
        dispatch({
          type: adminBadgeActions.FETCH_ERROR,
        })
      }
    },
}

export default adminBadgeActions
