import { BigNumber, ethers, utils } from 'ethers'
import {
  RARITY_LEVELS,
  choices,
  statsGenesMap,
  visualGenesMap,
  ZERO_ADDRESS,
} from './mapHeroConstants'
import moment from 'moment'
import heroMaleFirstName from '../../modules/hero/heroMaleFirstName'
import heroFemaleFirstName from '../../modules/hero/heroFemaleFirstName'
import heroLastName from '../../modules/hero/heroLastName'

export function mapHero(heroRaw) {
  const visualGenes = convertGenes(heroRaw.info?.visualGenes, visualGenesMap)
  const statGenes = convertGenes(heroRaw.info?.statGenes, statsGenesMap)

  if (typeof heroRaw.id == 'string') {
    heroRaw.id = BigNumber.from(heroRaw.id)
    heroRaw.xp = BigNumber.from(heroRaw.state?.xp)
    heroRaw.staminaFullAt = BigNumber.from(heroRaw.state?.staminaFullAt)
    heroRaw.summonedTime = BigNumber.from(heroRaw.summoningInfo?.summonedTime)
    heroRaw.nextSummonTime = BigNumber.from(
      heroRaw.summoningInfo?.nextSummonTime,
    )
  }

  const firstname_string =
    visualGenes.gender === 'male'
      ? heroMaleFirstName[heroRaw.info?.firstName || 0]
      : heroFemaleFirstName[heroRaw.info?.firstName || 0]
  const lastname_string = heroLastName[heroRaw.info?.lastName || 0]

  const name = [firstname_string, lastname_string].filter(Boolean).join(' ')

  return {
    name,
    firstname_string,
    lastname_string,
    firstName: firstname_string,
    lastName: lastname_string,
    id: ethers.BigNumber.from(heroRaw.id).toNumber(),
    background: visualGenes.background,
    class: statGenes.class || statGenes.mainClass,
    classAsNumber: Number(heroRaw?.info?.class) || 0,
    subClass: statGenes.subClass,
    element: statGenes.element,
    gender: visualGenes.gender,
    generation: Number(heroRaw.info?.generation),
    summonerId: heroRaw.summoningInfo?.summonerId,
    assistantId: heroRaw.summoningInfo?.assistantId,
    currentQuest: heroRaw.currentQuest,
    isQuesting: heroRaw.state?.currentQuest !== ZERO_ADDRESS,
    level: Number(heroRaw.state?.level),
    xp: ethers.BigNumber.from(heroRaw.state?.xp).toNumber(),
    rarity: RARITY_LEVELS[heroRaw.info?.rarity],
    rarityNum: heroRaw.info?.rarity,
    shiny: heroRaw.info?.shiny,
    shinyStyle: heroRaw.info?.shiny ? heroRaw.info?.shinyStyle : 0,

    staminaFullAt: moment.unix(
      ethers.BigNumber.from(heroRaw.state?.staminaFullAt).toNumber(),
    ),
    summonedDate: moment.unix(
      ethers.BigNumber.from(heroRaw.summoningInfo?.summonedTime).toNumber(),
    ),
    nextSummonTime: moment.unix(
      ethers.BigNumber.from(heroRaw.summoningInfo?.nextSummonTime).toNumber(),
    ),
    summons: Number(heroRaw.summoningInfo?.summons),
    maxSummons: Number(heroRaw.summoningInfo?.maxSummons),
    summonsRemaining:
      Number(heroRaw.summoningInfo?.maxSummons) < 11
        ? Number(heroRaw.summoningInfo?.maxSummons) -
          Number(heroRaw.summoningInfo?.summons)
        : 11,
    price: 0,
    summoningPrice: 0,
    winner: null,
    auction: {
      onAuction: false,
      startingPrice: 0,
      endingPrice: 0,
      startedAt: null,
      duration: null,
    },
    visualGenes: visualGenes,
    statGenes: statGenes,
    stats: heroRaw.stats,
    skills: {
      mining: Number(heroRaw.professions?.mining) / 10,
      gardening: Number(heroRaw.professions?.gardening) / 10,
      fishing: Number(heroRaw.professions?.fishing) / 10,
      foraging: Number(heroRaw.professions?.foraging) / 10,
    },
  }
}

const convertGenes = (_genes, genesMap): any => {
  // First, convert the genes to kai.
  const rawKai = genesToKai(BigInt(_genes.toString())).split(' ').join('')

  const genes = {}

  // Remove spaces, and get every 4th character.
  for (const k in rawKai.split('')) {
    if (rawKai.hasOwnProperty(k)) {
      const trait = genesMap[Math.floor(Number(k) / 4)]

      const kai = rawKai[k]
      const valueNum = kai2dec(kai)

      genes[trait] = choices[trait][valueNum]
    }
  }

  return genes
}

function kai2dec(kai) {
  const ALPHABET = '123456789abcdefghijkmnopqrstuvwx'
  return ALPHABET.indexOf(kai)
}

function genesToKai(genes) {
  const ALPHABET = '123456789abcdefghijkmnopqrstuvwx'
  const BASE = BigInt(ALPHABET.length)

  let buf = ''
  while (genes >= BASE) {
    const mod = genes % BASE
    buf = ALPHABET[Number(mod)] + buf
    genes = (genes - mod) / BASE
  }

  // Add the last 4 (finally).
  buf = ALPHABET[Number(genes)] + buf

  // Pad with leading 0s.
  buf = buf.padStart(48, '1')

  return buf.replace(/(.{4})/g, '$1 ')
}

export const calculateRequiredXp = (currentLevel) => {
  let xpNeeded
  const nextLevel = currentLevel + 1
  switch (true) {
    case currentLevel < 6:
      xpNeeded = nextLevel * 1000
      break
    case currentLevel < 9:
      xpNeeded = 4000 + (nextLevel - 5) * 2000
      break
    case currentLevel < 16:
      xpNeeded = 12000 + (nextLevel - 9) * 4000
      break
    case currentLevel < 36:
      xpNeeded = 40000 + (nextLevel - 16) * 5000
      break
    case currentLevel < 56:
      xpNeeded = 140000 + (nextLevel - 36) * 7500
      break
    case currentLevel >= 56:
      xpNeeded = 290000 + (nextLevel - 56) * 10000
      break
    default:
      xpNeeded = 0
      break
  }

  return xpNeeded
}
