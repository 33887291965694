import rankingActions from './rankingActions'

const initialData = {
  state: 'idle',
  rows: [],
  count: 0,
  renderedPage: 0,
  itemsPerPage: 4,
  fetchId: null,
  order: 'wins',
  direction: 'ASC' || 'DESC',
}

const rankingReducers = (state = initialData, { type, payload }) => {
  if (type === rankingActions.ORDER_CHANGED) {
    return {
      ...state,
      order: payload.order,
      direction: payload.direction,
    }
  }

  if (type === rankingActions.FETCH_STARTED) {
    return {
      ...state,
      state: payload.hideLoading ? state.state : 'loading',
      fetchId: payload.fetchId,
      renderedPage: payload.renderedPage,
      itemsPerPage: payload.itemsPerPage,
      order: payload.order,
      direction: payload.direction,
    }
  }

  if (type === rankingActions.FETCH_SUCCESS) {
    return {
      ...state,
      state: payload.count ? 'idle' : 'empty',
      rows: payload.rows,
      count: payload.count,
    }
  }

  if (type === rankingActions.FETCH_ERROR) {
    return {
      ...state,
      state: 'error',
    }
  }

  return state
}

export default rankingReducers