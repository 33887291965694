import moment from 'moment'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import HeroContractAbi from '../../contracts/HeroContractAbi'
import { mapHero } from '../../lib/mapHero'
import providerMainnetUrlForRetry from '../../lib/providerMainnetUrlForRetry'
import { sleep } from '../../lib/sleep'
import { ConfigStore } from '../config/configStore'

function toJSON(arg) {
  const jsonObj = Object.assign({}, arg)
  const proto = Object.getPrototypeOf(arg)
  for (const key of Object.getOwnPropertyNames(proto)) {
    const desc = Object.getOwnPropertyDescriptor(proto, key)
    const hasGetter = desc && typeof desc.get === 'function'
    if (hasGetter) {
      jsonObj[key] = arg[key]
    }
  }
  return jsonObj
}

let heroWithExpirationCache = {}
export default class HeroService {
  static async clearCache() {
    heroWithExpirationCache = {}
  }

  static async fetch(
    heroId,
    statsToKeep?,
    infoToKeep?,
    stateToKeep?,
    retry?: number,
  ) {
    try {
      retry = Number(retry) || 0
      if (!process.browser) {
        return null
      }

      const heroWithExpiration = heroWithExpirationCache[heroId]

      if (heroWithExpiration) {
        let hero = heroWithExpiration.hero
        const expiresAt = heroWithExpiration.expiresAt

        if (!expiresAt || moment(expiresAt).isAfter(moment())) {
          if (statsToKeep) {
            hero = { ...hero, stats: statsToKeep }
          }

          if (infoToKeep) {
            hero = {
              ...hero,
              info: {
                ...(hero.info || {}),
                ...infoToKeep,
              },
            }
          }

          if (stateToKeep) {
            hero = {
              ...hero,
              state: {
                ...(hero.state || {}),
                ...stateToKeep,
              },
            }
          }

          return mapHero(hero)
        }
      }

      const web3Instance = new Web3(providerMainnetUrlForRetry(retry))

      const heroContract = new web3Instance.eth.Contract(
        HeroContractAbi,
        ConfigStore.get().HERO_CONTRACT_ADDRESS_MAINNET,
      )

      let hero = await heroContract.methods.getHero(heroId).call()

      if (hero) {
        hero = {
          id: hero.id,
          summoningInfo: toJSON(hero.summoningInfo),
          info: toJSON(hero.info),
          state: toJSON(hero.state),
          stats: toJSON(hero.stats),
          primaryStatGrowth: toJSON(hero.primaryStatGrowth),
          secondaryStatGrowth: toJSON(hero.secondaryStatGrowth),
          professions: toJSON(hero.professions),
        }

        heroWithExpirationCache[heroId] = {
          hero,
          expiresAt: moment().add(1, 'hour').toISOString(),
        }
      }

      hero.stats = statsToKeep ? statsToKeep : hero.stats

      hero.info = infoToKeep
        ? {
            ...(hero.info || {}),
            ...infoToKeep,
          }
        : hero.info

      hero.state = stateToKeep
        ? {
            ...(hero.state || {}),
            ...stateToKeep,
          }
        : hero.state

      return mapHero(hero)
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetch(
          heroId,
          statsToKeep,
          infoToKeep,
          stateToKeep,
          retry + 1,
        )
      }

      throw error
    }
  }

  static async cooldownFinishesAt(heroId) {
    // @ts-ignore
    const web3Instance = new Web3(window.ethereum)

    const battleContract = new web3Instance.eth.Contract(
      BattleContractAbi,
      ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
    )

    const heroCooldownUntil = await battleContract.methods
      .heroCooldownUntil(heroId)
      .call()

    if (!heroCooldownUntil) {
      return false
    }

    const timestamp = moment.unix(heroCooldownUntil.toString())

    if (timestamp.isAfter(moment())) {
      return timestamp
    }

    return null
  }
}
