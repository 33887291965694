export class LiveGamesHeroStore {
  static getHeroOf(address, pairId) {
    const duelHero = this._get()
    return duelHero[`${address}-${pairId}`]
  }

  static setHeroTo(address, pairId, heroId) {
    const duelHero = this._get()
    duelHero[`${address}-${pairId}`] = heroId
    this._set(duelHero)
  }

  static _get() {
    if (!process.browser) return {}

    try {
      return JSON.parse(localStorage.getItem('liveGamesHeroes')) || {}
    } catch (error) {
      return {}
    }
  }
  static _set(heroes: any) {
    if (!process.browser) return

    localStorage.setItem('liveGamesHeroes', JSON.stringify(heroes))
  }

  static _clear() {
    if (!process.browser) return

    localStorage.removeItem('liveGamesHeroes')
  }
}
