import { toLower } from 'lodash'
import isAddressEqual from '../../lib/isAddressEqual'

export class AuthToken {
  static isSignedIn(publicAddress) {
    return Boolean(this.get(publicAddress))
  }

  static get(publicAddress) {
    if (!process.browser) {
      return null
    }

    const storedPublicAddress = localStorage.getItem('publicAddress')

    if (!isAddressEqual(storedPublicAddress, publicAddress)) {
      return null
    }

    return localStorage.getItem('jwt') || null
  }

  static set(publicAddress, token) {
    if (!process.browser) {
      return
    }

    localStorage.setItem('publicAddress', toLower(publicAddress) || '')
    localStorage.setItem('jwt', token || '')
  }

  static clear() {
    if (!process.browser) {
      return
    }

    localStorage.removeItem('publicAddress')
    localStorage.removeItem('jwt')
  }
}
