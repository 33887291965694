import { BigNumber } from 'ethers'
import isAddressEqual from '../../lib/isAddressEqual'

// Copy logic of https://github.com/ChainWaveLabs/defi-kingdoms-arena-contracts/blob/main/contracts/FightLogic.sol
export class DuelFightLogic {
  static convertHp(playerNumber: 1 | 2, duel: any) {
    const player1Stats = duel?.player1Hero?.stats || {}
    const player2Stats = duel?.player2Hero?.stats || {}

    const [player1Hp, player2Hp] = this._fetchHpToCompare(
      player1Stats.hp,
      player2Stats.hp,
      duel?.player1Hero?.level,
      duel?.player2Hero?.level,
      duel?.player1Hero?.classAsNumber,
      duel?.player2Hero?.classAsNumber,
      player1Stats.vitality,
      player2Stats.vitality,
    )

    if (Number(playerNumber) === 1) {
      return Number(player1Hp)
    } else {
      return Number(player2Hp)
    }
  }

  static convertMp(playerNumber: 1 | 2, duel: any) {
    const player1Stats = duel?.player1Hero?.stats || {}
    const player2Stats = duel?.player2Hero?.stats || {}

    const [player1Mp, player2Mp] = this._fetchMpToCompare(
      player1Stats.mp,
      player2Stats.mp,
      duel?.player1Hero?.level,
      duel?.player2Hero?.level,
      duel?.player1Hero?.classAsNumber,
      duel?.player2Hero?.classAsNumber,
      player1Stats.intelligence,
      player2Stats.intelligence,
      player1Stats.wisdom,
      player2Stats.wisdom,
    )

    if (Number(playerNumber) === 1) {
      return Number(player1Mp)
    } else {
      return Number(player2Mp)
    }
  }

  static convertStats(playerNumber: 1 | 2, duel: any) {
    const player1Stats = duel?.player1Hero?.stats || {}
    const player2Stats = duel?.player2Hero?.stats || {}

    const player1Level = duel?.player1Hero?.level || '0'
    const player2Level = duel?.player2Hero?.level || '0'

    const player1Class = duel?.player1Hero?.classAsNumber || '0'
    const player2Class = duel?.player2Hero?.classAsNumber || '0'

    return {
      luck: Number(
        this._fetchStatsToCompare(
          player1Stats.luck,
          player2Stats.luck,
          player1Level,
          player2Level,
          player1Class,
          player2Class,
        )[playerNumber - 1],
      ),
      wisdom: Number(
        this._fetchStatsToCompare(
          player1Stats.wisdom,
          player2Stats.wisdom,
          player1Level,
          player2Level,
          player1Class,
          player2Class,
        )[playerNumber - 1],
      ),
      agility: Number(
        this._fetchStatsToCompare(
          player1Stats.agility,
          player2Stats.agility,
          player1Level,
          player2Level,
          player1Class,
          player2Class,
        )[playerNumber - 1],
      ),
      strength: Number(
        this._fetchStatsToCompare(
          player1Stats.strength,
          player2Stats.strength,
          player1Level,
          player2Level,
          player1Class,
          player2Class,
        )[playerNumber - 1],
      ),
      vitality: Number(
        this._fetchStatsToCompare(
          player1Stats.vitality,
          player2Stats.vitality,
          player1Level,
          player2Level,
          player1Class,
          player2Class,
        )[playerNumber - 1],
      ),
      dexterity: Number(
        this._fetchStatsToCompare(
          player1Stats.dexterity,
          player2Stats.dexterity,
          player1Level,
          player2Level,
          player1Class,
          player2Class,
        )[playerNumber - 1],
      ),
      endurance: Number(
        this._fetchStatsToCompare(
          player1Stats.endurance,
          player2Stats.endurance,
          player1Level,
          player2Level,
          player1Class,
          player2Class,
        )[playerNumber - 1],
      ),
      intelligence: Number(
        this._fetchStatsToCompare(
          player1Stats.intelligence,
          player2Stats.intelligence,
          player1Level,
          player2Level,
          player1Class,
          player2Class,
        )[playerNumber - 1],
      ),
    }
  }

  static convertDamage(round, duel: any) {
    // const isPlayer1Winner = duel?.isDraw
    //   ? false
    //   : isAddressEqual(duel?.winnerAddress, duel?.player1Address)

    // let isPlayer1Defender = round.defender === duel.player1Address

    // Damange logic changes only after duel 10200
    // if (Number(duel?.pairId) < 10200) {
    return Number(round.damage)
    // } else {
    //   if (duel?.isDraw) {
    //     return Math.floor(
    //       BigNumber.from(round.damage || 0)
    //         .div(100)
    //         .toNumber(),
    //     )
    //   } else {
    //     if (isPlayer1Defender) {
    //       if (isPlayer1Winner) {
    //         return Math.floor(
    //           BigNumber.from(round.damage || 0)
    //             .div(100)
    //             .toNumber(),
    //         )
    //       } else {
    //         return Math.ceil(
    //           BigNumber.from(round.damage || 0)
    //             .div(100)
    //             .toNumber(),
    //         )
    //       }
    //     } else {
    //       if (isPlayer1Winner) {
    //         return Math.ceil(
    //           BigNumber.from(round.damage || 0)
    //             .div(100)
    //             .toNumber(),
    //         )
    //       } else {
    //         return Math.floor(
    //           BigNumber.from(round.damage || 0)
    //             .div(100)
    //             .toNumber(),
    //         )
    //       }
    //     }
    //   }
    // }
  }

  static _fetchHpToCompare(
    _heroOneHp: BigNumber,
    _heroTwoHp: BigNumber,
    _heroOneLevel: BigNumber,
    _heroTwoLevel: BigNumber,
    _heroOneClass: BigNumber,
    _heroTwoClass: BigNumber,

    _heroOneVitality: BigNumber,
    _heroTwoVitality: BigNumber,
  ) {
    _heroOneHp = BigNumber.from(_heroOneHp)
    _heroTwoHp = BigNumber.from(_heroTwoHp)
    _heroOneLevel = BigNumber.from(_heroOneLevel)
    _heroTwoLevel = BigNumber.from(_heroTwoLevel)
    _heroOneClass = BigNumber.from(_heroOneClass)
    _heroTwoClass = BigNumber.from(_heroTwoClass)
    _heroOneVitality = BigNumber.from(_heroOneVitality)
    _heroTwoVitality = BigNumber.from(_heroTwoVitality)

    const [heroTwoAdjustment, heroOneAdjustment] = this._fetchHpAdjustments(
      _heroOneLevel,
      _heroTwoLevel,
      _heroOneClass,
      _heroTwoClass,
      _heroOneVitality,
      _heroTwoVitality,
    )

    _heroOneHp = _heroOneHp.mul(100)
    _heroTwoHp = _heroTwoHp.mul(100)

    if (_heroOneLevel.gt(_heroTwoLevel) || _heroOneLevel.lt(_heroTwoLevel)) {
      if (_heroOneLevel.gt(_heroTwoLevel)) {
        _heroTwoHp = _heroTwoHp.add(heroTwoAdjustment)
      } else {
        _heroOneHp = _heroOneHp.add(heroOneAdjustment)
      }
    }

    return [_heroOneHp, _heroTwoHp]
  }

  static _fetchHpAdjustments(
    _heroOneLevel: BigNumber,
    _heroTwoLevel: BigNumber,
    _heroOneClass: BigNumber,
    _heroTwoClass: BigNumber,

    _heroOneVitality: BigNumber,
    _heroTwoVitality: BigNumber,
  ) {
    let constantVariable
    let multiplierVariable
    let highLevelHeroClass
    let levelDifference

    if (_heroOneLevel.gt(_heroTwoLevel)) {
      highLevelHeroClass = _heroOneClass
      levelDifference = _heroOneLevel.sub(_heroTwoLevel)
    } else {
      highLevelHeroClass = _heroTwoClass
      levelDifference = _heroTwoLevel.sub(_heroOneLevel)
    }

    if (highLevelHeroClass == 0) {
      constantVariable = 2300
      multiplierVariable = 115
    } else if (highLevelHeroClass == 1) {
      constantVariable = 2350
      multiplierVariable = 118
    } else if (highLevelHeroClass == 2) {
      constantVariable = 2000
      multiplierVariable = 100
    } else if (highLevelHeroClass == 3) {
      constantVariable = 2000
      multiplierVariable = 100
    } else if (highLevelHeroClass == 4) {
      constantVariable = 1900
      multiplierVariable = 95
    } else if (highLevelHeroClass == 5) {
      constantVariable = 1900
      multiplierVariable = 95
    } else if (highLevelHeroClass == 6) {
      constantVariable = 2150
      multiplierVariable = 108
    } else if (highLevelHeroClass == 7) {
      constantVariable = 2250
      multiplierVariable = 113
    } else if (highLevelHeroClass == 8) {
      constantVariable = 2250
      multiplierVariable = 113
    } else if (highLevelHeroClass == 9) {
      constantVariable = 1900
      multiplierVariable = 95
    } else if (highLevelHeroClass == 10) {
      constantVariable = 2400
      multiplierVariable = 120
    } else if (highLevelHeroClass == 11) {
      constantVariable = 2050
      multiplierVariable = 103
    } else if (highLevelHeroClass == 12) {
      constantVariable = 1800
      multiplierVariable = 90
    } else if (highLevelHeroClass == 13) {
      constantVariable = 2000
      multiplierVariable = 100
    } else if (highLevelHeroClass == 14) {
      constantVariable = 2150
      multiplierVariable = 108
    } else if (highLevelHeroClass == 15) {
      constantVariable = 2350
      multiplierVariable = 118
    } else if (highLevelHeroClass == 16) {
      constantVariable = 1850
      multiplierVariable = 93
    } else {
      constantVariable = 2400
      multiplierVariable = 120
    }

    _heroOneVitality = _heroOneVitality.mul(100)
    _heroTwoVitality = _heroTwoVitality.mul(100)

    return [
      this._fetchHpAdjustmentsCont(
        levelDifference,
        _heroOneVitality,
        _heroOneClass,
        constantVariable,
        multiplierVariable,
      ),
      this._fetchHpAdjustmentsCont(
        levelDifference,
        _heroTwoVitality,
        _heroTwoClass,
        constantVariable,
        multiplierVariable,
      ),
    ]
  }

  static _fetchHpAdjustmentsCont(
    levelDifference: BigNumber,
    greaterHeroVitality: BigNumber,
    greaterHeroClass: BigNumber,
    constantVariable: BigNumber,
    multiplierVariable: BigNumber,
  ) {
    levelDifference = BigNumber.from(levelDifference)
    greaterHeroVitality = BigNumber.from(greaterHeroVitality)
    greaterHeroClass = BigNumber.from(greaterHeroClass)
    constantVariable = BigNumber.from(constantVariable)
    multiplierVariable = BigNumber.from(multiplierVariable)

    if (levelDifference.isZero()) {
      return BigNumber.from(0)
    }

    let class_multiplier = this._getClassMultiplierOnly(greaterHeroClass)
    class_multiplier = class_multiplier.div(8) //divide by 8 b/c 8 difference stats; gets the average individual stat increase per level increase
    greaterHeroVitality = greaterHeroVitality.sub(class_multiplier) //Since you are adding levels to the lesser hero, you always want to start with the stat of the greater hero's level minus 1
    if (greaterHeroVitality.lt(0)) {
      greaterHeroVitality = BigNumber.from(0)
    }
    let multiplierPrecision = 100
    let totalAdjustment = BigNumber.from(0)
    for (let i = 0; i < Number(levelDifference); i++) {
      let singleLevelAdjustment = constantVariable.add(
        greaterHeroVitality.mul(multiplierVariable).div(multiplierPrecision),
      )
      totalAdjustment = totalAdjustment.add(singleLevelAdjustment)
      greaterHeroVitality = greaterHeroVitality.sub(class_multiplier)
      if (greaterHeroVitality.lt(0)) {
        greaterHeroVitality = BigNumber.from(0)
      }
    }

    return totalAdjustment
  }

  static _fetchMpToCompare(
    _heroOneMp: BigNumber,
    _heroTwoMp: BigNumber,
    _heroOneLevel: BigNumber,
    _heroTwoLevel: BigNumber,
    _heroOneClass: BigNumber,
    _heroTwoClass: BigNumber,

    _heroOneIntelligence: BigNumber,
    _heroTwoIntelligence: BigNumber,

    _heroOneWisdom: BigNumber,
    _heroTwoWisdom: BigNumber,
  ) {
    _heroOneMp = BigNumber.from(_heroOneMp)
    _heroTwoMp = BigNumber.from(_heroTwoMp)
    _heroOneLevel = BigNumber.from(_heroOneLevel)
    _heroTwoLevel = BigNumber.from(_heroTwoLevel)
    _heroOneClass = BigNumber.from(_heroOneClass)
    _heroTwoClass = BigNumber.from(_heroTwoClass)
    _heroOneIntelligence = BigNumber.from(_heroOneIntelligence)
    _heroTwoIntelligence = BigNumber.from(_heroTwoIntelligence)
    _heroOneWisdom = BigNumber.from(_heroOneWisdom)
    _heroTwoWisdom = BigNumber.from(_heroTwoWisdom)

    const [heroTwoAdjustment, heroOneAdjustment] = this._fetchMpAdjustments(
      _heroOneLevel,
      _heroTwoLevel,
      _heroOneClass,
      _heroTwoClass,
      _heroOneIntelligence,
      _heroTwoIntelligence,
      _heroOneWisdom,
      _heroTwoWisdom,
    )

    _heroOneMp = _heroOneMp.mul(100)
    _heroTwoMp = _heroTwoMp.mul(100)

    if (_heroOneLevel.gt(_heroTwoLevel) || _heroOneLevel.lt(_heroTwoLevel)) {
      if (_heroOneLevel.gt(_heroTwoLevel)) {
        _heroTwoMp = _heroTwoMp.add(heroTwoAdjustment)
      } else {
        _heroOneMp = _heroOneMp.add(heroOneAdjustment)
      }
    }

    return [_heroOneMp, _heroTwoMp]
  }

  static _fetchMpAdjustments(
    _heroOneLevel: BigNumber,
    _heroTwoLevel: BigNumber,
    _heroOneClass: BigNumber,
    _heroTwoClass: BigNumber,

    _heroOneIntelligence: BigNumber,
    _heroTwoIntelligence: BigNumber,

    _heroOneWisdom: BigNumber,
    _heroTwoWisdom: BigNumber,
  ) {
    let constantVariable
    let multiplierVariable
    let highLevelHeroClass
    let levelDifference

    if (_heroOneLevel.gt(_heroTwoLevel)) {
      highLevelHeroClass = _heroOneClass
      levelDifference = _heroOneLevel.sub(_heroTwoLevel)
    } else {
      highLevelHeroClass = _heroTwoClass
      levelDifference = _heroTwoLevel.sub(_heroOneLevel)
    }

    if (highLevelHeroClass == 0) {
      constantVariable = 410
      multiplierVariable = 12375
    } else if (highLevelHeroClass == 1) {
      constantVariable = 460
      multiplierVariable = 13500
    } else if (highLevelHeroClass == 2) {
      constantVariable = 530
      multiplierVariable = 15000
    } else if (highLevelHeroClass == 3) {
      constantVariable = 530
      multiplierVariable = 15000
    } else if (highLevelHeroClass == 4) {
      constantVariable = 655
      multiplierVariable = 17625
    } else if (highLevelHeroClass == 5) {
      constantVariable = 530
      multiplierVariable = 15000
    } else if (highLevelHeroClass == 6) {
      constantVariable = 425
      multiplierVariable = 12750
    } else if (highLevelHeroClass == 7) {
      constantVariable = 425
      multiplierVariable = 12750
    } else if (highLevelHeroClass == 8) {
      constantVariable = 425
      multiplierVariable = 12750
    } else if (highLevelHeroClass == 9) {
      constantVariable = 655
      multiplierVariable = 17625
    } else if (highLevelHeroClass == 10) {
      constantVariable = 565
      multiplierVariable = 15750
    } else if (highLevelHeroClass == 11) {
      constantVariable = 600
      multiplierVariable = 16500
    } else if (highLevelHeroClass == 12) {
      constantVariable = 655
      multiplierVariable = 17625
    } else if (highLevelHeroClass == 13) {
      constantVariable = 525
      multiplierVariable = 15000
    } else if (highLevelHeroClass == 14) {
      constantVariable = 530
      multiplierVariable = 15000
    } else if (highLevelHeroClass == 15) {
      constantVariable = 475
      multiplierVariable = 13875
    } else if (highLevelHeroClass == 16) {
      constantVariable = 710
      multiplierVariable = 18750
    } else {
      constantVariable = 525
      multiplierVariable = 15000
    }

    _heroOneIntelligence = _heroOneIntelligence.mul(100)
    _heroOneWisdom = _heroOneWisdom.mul(100)

    _heroTwoIntelligence = _heroTwoIntelligence.mul(100)
    _heroTwoWisdom = _heroTwoWisdom.mul(100)

    return [
      this._fetchMpAdjustmentsCont(
        levelDifference,
        _heroOneIntelligence,
        _heroOneWisdom,
        _heroOneClass,
        constantVariable,
        multiplierVariable,
      ),
      this._fetchMpAdjustmentsCont(
        levelDifference,
        _heroTwoIntelligence,
        _heroTwoWisdom,
        _heroTwoClass,
        constantVariable,
        multiplierVariable,
      ),
    ]
  }

  static _fetchMpAdjustmentsCont(
    levelDifference,
    greaterHeroInt,
    greaterHeroWis,
    greaterHeroClass,
    constantVariable,
    multiplierVariable,
  ) {
    levelDifference = BigNumber.from(levelDifference)
    greaterHeroInt = BigNumber.from(greaterHeroInt)
    greaterHeroWis = BigNumber.from(greaterHeroWis)
    greaterHeroClass = BigNumber.from(greaterHeroClass)
    constantVariable = BigNumber.from(constantVariable)
    multiplierVariable = BigNumber.from(multiplierVariable)

    if (levelDifference.isZero()) {
      return 0
    }

    let class_multiplier = this._getClassMultiplierOnly(greaterHeroClass)
    class_multiplier = class_multiplier.div(8) //divide by 8 b/c 8 difference stats; gets the average individual stat increase per level increase

    greaterHeroInt = greaterHeroInt.sub(class_multiplier) //Since you are adding levels to the lesser hero, you always want to start with the stat of the greater hero's level minus 1
    if (greaterHeroInt.lt(0)) {
      greaterHeroInt = BigNumber.from(0)
    }
    greaterHeroWis = greaterHeroWis.sub(class_multiplier) //Since you are adding levels to the lesser hero, you always want to start with the stat of the greater hero's level minus 1
    if (greaterHeroWis.lt(0)) {
      greaterHeroWis = BigNumber.from(0)
    }
    let multiplierPrecision = 100000
    let totalAdjustment = BigNumber.from(0)
    for (let i = 0; i < Number(levelDifference); i++) {
      let intCalculation = greaterHeroInt.mul(6).div(10)
      let wisCalculation = greaterHeroWis.mul(4).div(10)
      let singleLevelAdjustment = constantVariable.add(
        intCalculation
          .add(wisCalculation)
          .mul(multiplierVariable)
          .div(multiplierPrecision),
      )
      totalAdjustment = totalAdjustment.add(singleLevelAdjustment)
      greaterHeroInt = greaterHeroInt.sub(class_multiplier)
      if (greaterHeroInt.lt(0)) {
        greaterHeroInt = BigNumber.from(0)
      }
      greaterHeroWis = greaterHeroWis.sub(class_multiplier)
      if (greaterHeroWis.lt(0)) {
        greaterHeroWis = BigNumber.from(0)
      }
    }

    return totalAdjustment
  }

  static _fetchStatsToCompare(
    player1_skill_to_compare: BigNumber,
    player2_skill_to_compare: BigNumber,
    _heroOneLevel: BigNumber,
    _heroTwoLevel: BigNumber,
    _heroOneClass: BigNumber,
    _heroTwoClass: BigNumber,
  ) {
    player1_skill_to_compare = BigNumber.from(player1_skill_to_compare)
    player2_skill_to_compare = BigNumber.from(player2_skill_to_compare)
    _heroOneLevel = BigNumber.from(_heroOneLevel)
    _heroTwoLevel = BigNumber.from(_heroTwoLevel)
    _heroOneClass = BigNumber.from(_heroOneClass)
    _heroTwoClass = BigNumber.from(_heroTwoClass)

    const [level_difference, class_multiplier] = this._fetchClassMultiplier(
      _heroOneLevel,
      _heroTwoLevel,
      _heroOneClass,
      _heroTwoClass,
    )

    player1_skill_to_compare = player1_skill_to_compare.mul(100)
    player2_skill_to_compare = player2_skill_to_compare.mul(100)

    if (level_difference.gt(0)) {
      //divide by 8 b/c 8 difference stats. Get average stat difference based on level difference and higher level hero's class
      //then add the difference to the lower level hero's stat
      let equalizeStats = class_multiplier.mul(level_difference).div(8)

      if (_heroOneLevel.gt(_heroTwoLevel)) {
        player2_skill_to_compare = player2_skill_to_compare.add(equalizeStats)
      } else {
        player1_skill_to_compare = player1_skill_to_compare.add(equalizeStats)
      }
    }

    return [
      player1_skill_to_compare.div(100),
      player2_skill_to_compare.div(100),
    ]
  }

  static _fetchClassMultiplier(
    _heroOneLevel,
    _heroTwoLevel,
    _heroOneClass,
    _heroTwoClass,
  ) {
    _heroOneLevel = Number(_heroOneLevel)
    _heroTwoLevel = Number(_heroTwoLevel)
    _heroOneClass = Number(_heroOneClass)
    _heroTwoClass = Number(_heroTwoClass)

    let level_difference
    let class_multiplier

    if (_heroOneLevel > _heroTwoLevel) {
      level_difference = _heroOneLevel - _heroTwoLevel
      class_multiplier = BigNumber.from(
        this._getClassMultiplierOnly(_heroOneClass),
      )
    } else {
      level_difference = _heroTwoLevel - _heroOneLevel
      class_multiplier = BigNumber.from(
        this._getClassMultiplierOnly(_heroTwoClass),
      )
    }
    return [BigNumber.from(level_difference), BigNumber.from(class_multiplier)]
  }

  static _getClassMultiplierOnly(heroClass) {
    heroClass = Number(heroClass)
    let class_multiplier

    if (
      heroClass == 0 ||
      heroClass == 1 ||
      heroClass == 2 ||
      heroClass == 3 ||
      heroClass == 4 ||
      heroClass == 4 ||
      heroClass == 5 ||
      heroClass == 6 ||
      heroClass == 7 ||
      heroClass == 8 ||
      heroClass == 9
    ) {
      class_multiplier = 700
    } else if (
      heroClass == 10 ||
      heroClass == 11 ||
      heroClass == 12 ||
      heroClass == 13 ||
      heroClass == 14
    ) {
      class_multiplier = 763
    } else if (heroClass == 15 || heroClass == 16) {
      class_multiplier = 825
    } else {
      class_multiplier = 888
    }
    return BigNumber.from(class_multiplier)
  }
}
