import audioActions from './audioActions'
import { AudioState } from './audioState'

const initialData = {
  isMusicMuted: AudioState.isMusicMuted(),
  isEffectMuted: AudioState.isEffectMuted(),
  loserAddress: null,
  battleSounds: {
    player: null,
    action: null,
  },
}

const audioReducers = (state = initialData, { type, payload }) => {
  if (type === audioActions.EFFECT_MUTE) {
    AudioState.setEffectMute(true)
    return {
      ...state,
      isEffectMuted: true,
    }
  }

  if (type === audioActions.EFFECT_UNMUTE) {
    AudioState.setEffectMute(false)
    return {
      ...state,
      isEffectMuted: false,
    }
  }

  if (type === audioActions.MUSIC_MUTE) {
    AudioState.setMusicMute(true)
    return {
      ...state,
      isMusicMuted: true,
    }
  }

  if (type === audioActions.MUSIC_UNMUTE) {
    AudioState.setMusicMute(false)
    return {
      ...state,
      isMusicMuted: false,
    }
  }

  if (type === audioActions.END_FIGHT) {
    return {
      ...state,
      loserAddress: payload,
    }
  }

  if (type === audioActions.BATTLE_SOUNDS) {
    return {
      ...state,
      battleSounds: payload,
    }
  }

  if (type === audioActions.RESET) {
    return {
      ...state,
      loserAddress: null,
      battleSounds: {
        player: null,
        action: null,
      },
    }
  }

  return state
}

export default audioReducers
