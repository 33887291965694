import styles from '../styles/Pagination.module.scss'
import ReactPaginate from 'react-paginate'

export default function Pagination(props: {
  currentPage: number | null
  itemsPerPage: number | null
  totalCount: number | null
  onPageChange: (page: number) => void | null
}) {
  if (!props.totalCount || !props.itemsPerPage || !props.onPageChange) {
    return null
  }

  const pageCount = Math.ceil(props.totalCount / props.itemsPerPage)

  if (pageCount == 1) {
    return null
  }

  return (
    <nav className={styles.wrapper}>
      {/* @ts-ignore */}
      <ReactPaginate
        forcePage={props.currentPage}
        activeLinkClassName={`${styles.activeLink} ${styles.link}`}
        pageClassName={styles.link}
        containerClassName={styles.content}
        breakLinkClassName={styles.dots}
        breakLabel="..."
        nextLabel={
          <span className={styles.arrow}>
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L6 6L1 11"
                stroke="#65D474"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        }
        onPageChange={(selectedItem) =>
          props.onPageChange(selectedItem.selected)
        }
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={
          <span className={styles.arrow}>
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 1L1 6L6 11"
                stroke="#65D474"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        }
        renderOnZeroPageCount={null}
      />
    </nav>
  )
}
