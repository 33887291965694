import React, { useState } from 'react'
import Modal from 'react-modal'
import styles from '../styles/Modals.module.scss'

export function BetaDisclaimerModal(props) {
  const [isOpen, setIsOpen] = useState(true)

  if (!process.browser) {
    return null
  }

  const isAccepted = Boolean(localStorage.getItem('betaDisclaimerNoticed'))

  function doNotice() {
    localStorage.setItem('betaDisclaimerNoticed', 'true')
    setIsOpen(false)
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen && !isAccepted}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="You Are Currently Using The Beta Site Of DFK Arena"
        className={`dfkModal ${styles.authModal}`}
        closeTimeoutMS={200}
      >
        <div className={`heading dfkModalTitle ${styles.rulesModalTitle}`}>
          You Are Currently Using The Beta Site Of DFK Arena
        </div>
        <div className={styles.authModalText} style={{ textAlign: 'left' }}>
          The beta site is used to test future game updates. Game features may
          not function as intended. The beta site uses your real tokens and
          NFTs. Any and all transactions made on this site occur in real time in
          your wallet. Consider carefully before participating in Beta testing.
          Beta test features are used for balance testing and are not guaranteed
          to be implemented. Be careful before making financial decisions based
          on Beta test mechanics. All mechanics in DFK Arena are subject to
          change in future updates.
        </div>
        <button
          className="btn btn--default btn--green"
          style={{ paddingLeft: 10, paddingRight: 10 }}
          onClick={doNotice}
        >
          I understand
        </button>
      </Modal>
    </>
  )
}
