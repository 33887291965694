import { createSelector } from 'reselect'

const selectRaw = (state) => state.promoCodeListReducers
const selectUserAddress = createSelector([selectRaw], (raw) => raw.userAddress)

const promoCodeListSelectors = {
  selectRaw,
  selectUserAddress,
}

export default promoCodeListSelectors