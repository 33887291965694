import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import UserModalService from '../modules/users/userModalService'

const usePlayerInfo = (userAddress) => {
  const [state, setState] = useState<
    'success' | 'loading' | 'serverLoading' | 'error' | 'empty'
  >('loading')

  const [data, setData] = useState(null)

  useEffect(() => {
    if (process.browser) {
      fetch().then(() => {})
    }
  }, [userAddress])

  async function fetch() {
    if (!userAddress) {
      setState('loading')
      setData(null)
      return
    }

    setState('loading')
    try {
      const response = await UserModalService.fetch(userAddress)

      setData(response)
      setState('success')
    } catch (error) {
      console.error(error)
      setState('error')
      setData(null)
    }
  }

  return { state, data, fetch }
}

export default usePlayerInfo
