export class AudioState {
  private static startAsMuted = false

  static isMusicMuted() {
    if (!process.browser) {
      return this.startAsMuted
    }

    try {
      return String(localStorage.getItem('musicMuted')) === 'true'
    } catch (error) {
      console.error(error)
      return false
    }
  }
  static isEffectMuted() {
    if (!process.browser) {
      return this.startAsMuted
    }

    try {
      return String(localStorage.getItem('effectMuted')) === 'true'
    } catch (error) {
      console.error(error)
      return false
    }
  }

  static setMusicMute(mute: Boolean) {
    if (!process.browser) {
      return
    }

    localStorage.setItem('musicMuted', String(mute))
  }

  static setEffectMute(mute: Boolean) {
    if (!process.browser) {
      return
    }

    localStorage.setItem('effectMuted', String(mute))
  }
}
