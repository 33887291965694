import { sortBy, uniqBy } from 'lodash'
import React, { useMemo } from 'react'
import styles from '../../styles/Chat.module.scss'
import ChatPrivateUser from './chatPrivateUser'

interface ChatPrivateUsersProps {
  privateUsers: Array<any>
  setPrivateUser: any
}

export default function ChatPrivateUsers(props: ChatPrivateUsersProps) {
  const sortedPrivateUsers = useMemo(() => {
    return uniqBy(
      sortBy(props.privateUsers, 'lastMessage.createdAt'),
      'id',
    ).reverse()
  }, [props.privateUsers])

  return (
    <div className={styles.messages}>
      <div className={styles.chatUsersScroller}>
        {sortedPrivateUsers.map((privateUser) => (
          <ChatPrivateUser
            key={privateUser.id}
            user={privateUser}
            onClick={() => props.setPrivateUser(privateUser)}
          />
        ))}
      </div>
    </div>
  )
}
