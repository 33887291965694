import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWeb3React } from '@web3-react/core'
import moment from 'moment'
import Image from 'next/image'
import Link from 'next/link'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import reactStringReplace from 'react-string-replace'
import classNames from '../../lib/classNames'
import isAdmin from '../../lib/isAdmin'
import isModerator from '../../lib/isModerator'
import Messages from '../../lib/messages'
import authSelectors from '../../modules/auth/authSelectors'
import ChatService from '../../modules/chat/chatService'
import { ApiErrors } from '../../modules/error/ApiErrors'
import userModalActions from '../../modules/users/userModalActions'
import styles from '../../styles/Chat.module.scss'
import { shortenHex } from '../../util'
import ProfileAvatar from '../ProfileAvatar'

export default function ChatMessage(props) {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const { message } = props
  const { battlesMentions, usersMentions, text, createdAt } = message
  const profile = useSelector(authSelectors.selectProfile)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const isCurrentUserAdminOrModerator = isAdmin(account) || isModerator(account)

  let isGlobal = Boolean(message.user)
  let user = message.user || message.userFrom

  const chatBadges = user.chatBadges || []

  function doOpenUserModal(userAddress) {
    dispatch(userModalActions.doOpenModal(userAddress))
  }

  const textWithMentions = useMemo(() => {
    let replacedText = text

    for (let userMention of usersMentions || []) {
      replacedText = reactStringReplace(
        replacedText,
        `@${userMention.name}`,
        (match, i) => {
          return (
            <a
              key={i}
              onClick={() => doOpenUserModal(userMention.publicAddress)}
            >
              {match}
            </a>
          )
        },
      )
    }

    for (let battleMention of battlesMentions || []) {
      replacedText = reactStringReplace(
        replacedText,
        `#${battleMention.pairId}`,
        (match, i) => {
          return (
            <Link key={i} href={`/duel/${battleMention.pairId}`}>
              <a>{match}</a>
            </Link>
          )
        },
      )
    }

    return replacedText
  }, [battlesMentions, usersMentions, text])

  const hasMentionOfCurrentUser = useMemo(() => {
    return usersMentions && usersMentions.some((um) => um.id === profile?.id)
  }, usersMentions)

  async function doDelete() {
    const confirmed = confirm(
      `Delete message from ${
        user?.name || user?.publicAddress
      }? This can't be undone.`,
    )

    if (!confirmed) {
      return
    }

    try {
      setLoadingDelete(true)
      await ChatService.deleteGlobalMessage(account, message.id)
      Messages.success(
        `${user?.name || user?.publicAddress}'s message successfully deleted.`,
      )
    } catch (error) {
      ApiErrors.handle(error)
    } finally {
      setLoadingDelete(false)
    }
  }

  if (message.isTip) {
    return (
      <div className={styles.notification}>
        <div className={styles.messageText}>
          {message.text.includes('GOLD') ? (
            <Image alt="Coin" src={'/gold_coin.png'} width={18} height={18} />
          ) : (
            <Image alt="Coin" src={'/jewel_coin.svg'} width={18} height={18} />
          )}{' '}
          {textWithMentions}
        </div>
      </div>
    )
  }

  return (
    <div id={message.id} className={styles.message}>
      <span
        onClick={() => {
          doOpenUserModal(user.publicAddress)
        }}
      >
        <ProfileAvatar
          showOnlineStatus
          className={styles.messageAvatar}
          lazyLoad
          profile={user}
          size={'small'}
        />
      </span>
      <div
        className={classNames(
          styles.messageContent,
          hasMentionOfCurrentUser ? styles.messageContentHighlighted : '',
        )}
      >
        <div className={styles.messageTop}>
          <span
            onClick={() => {
              doOpenUserModal(user.publicAddress)
            }}
            className={styles.messageUsername}
            data-testid={user.name}
          >
            {user.name || shortenHex(user.publicAddress)}
            <span className={styles.badges}>
              {chatBadges.map((badge) => {
                return (
                  <img
                    key={badge.id}
                    alt={`icon-${badge.id}`}
                    src={badge.imageUrl}
                    width={10}
                    height={10}
                    data-tip={`<span>${badge.label}</span>`}
                    data-html={true}
                  />
                )
              })}
            </span>
          </span>
        </div>
        <div style={{ display: 'flex', gap: 4, flexWrap: 'nowrap' }}>
          <div
            title={moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}
            className={styles.messageTime}
          >
            {moment(createdAt).fromNow()}
          </div>
          {isCurrentUserAdminOrModerator && isGlobal && (
            <button
              title="Delete"
              onClick={doDelete}
              disabled={loadingDelete}
              className={`btn ${styles.messageClose}`}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          )}
        </div>
        <div className={styles.messageText}>{textWithMentions}</div>
      </div>
    </div>
  )
}
