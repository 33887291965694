import axios from 'axios'
import { ethers } from 'ethers'
import { AuthToken } from './authToken'

let web3: any

export default class AuthService {
  static async signIn(publicAddress) {
    let nonce = await this._findNonce(publicAddress)

    let signature
    try {
      signature = await this._sign(nonce)
    } catch (error) {
      console.error(error)
      // User canceled signature
      return false
    }
    const jwtToken = await this._fetchJwtToken(publicAddress, signature)
    AuthToken.set(publicAddress, jwtToken)
    return true
  }

  static async isAdmin(publicAddress) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/auth/${publicAddress}/admin`,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data.isAdmin || false
  }

  static async _fetchMe(publicAddress) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/auth/me`,
      {
        headers: {
          Authorization: `Bearer ${AuthToken.get(publicAddress)}`,
        },
      },
    )

    return data.accessToken
  }

  static async _fetchJwtToken(publicAddress, signature) {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/auth/${publicAddress}/sign-in`,
      { signature },
    )

    return data.accessToken
  }

  static async _findNonce(publicAddress) {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/auth/${publicAddress}/nonce`,
    )

    return data.nonce
  }

  static async _sign(nonce) {
    let signature
    // @ts-ignore
    await window.ethereum.enable()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    signature = await signer.signMessage(
      `Signing to Defi Kingdoms Arena one-time nonce: ${nonce}`,
    )
    return signature
  }
}
