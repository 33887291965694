import { useWeb3React } from '@web3-react/core'
import { useDispatch, useSelector } from 'react-redux'
import promoCodeActions from '../../modules/promoCodeModal/promoCodeActions'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import promoCodeSelectors from '../../modules/promoCodeModal/promoCodeSelectors'
import Modal from 'react-modal'
import styles from '../../styles/Modals.module.scss'
import profileStyles from '../../styles/Profile.module.scss'
import Image from 'next/image'
import React, { useState } from 'react'
import useContract from '../../hooks/useContract'
import { ConfigStore } from '../../modules/config/configStore'
import AffiliateContractAbi from '../../contracts/AffiliateContractAbi'
import Messages from '../../lib/messages'

export function PromoCodeModal() {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const [promoCode, setPromoCode] = useState(null)
  const userAddress = useSelector(promoCodeSelectors.selectUserAddress)
  const affiliateContract = useContract(
    ConfigStore.get().AFFILIATE_CONTRACT_ADDRESS,
    AffiliateContractAbi,
  )
  const signUpWithPromo = async () => {
    try {
      await affiliateContract.functions.sign_up_with_promo(promoCode)
      Messages.success('Successfully signed up with promo code!')
      closeModal()
    } catch (error) {
      console.error(error)
      Messages.error(`Error signing up with promo code ${promoCode}`)
    }
  }

  const handleInput = (word) => {
    setPromoCode(word ? word.trim() : '')
  }

  function closeModal() {
    dispatch(promoCodeActions.doCloseModal())
  }

  useDisableBodyScroll(Boolean(userAddress))

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={Boolean(userAddress)}
        onRequestClose={closeModal}
        contentLabel={'Promo Code Modal'}
        className={`dfkModal ${styles.userModal}`}
        closeTimeoutMS={200}
      >
        <div className={`heading dfkModalTitle ${styles.rulesModalTitle}`}>
          Looks like you're new here!
        </div>
        <div style={{ marginBlock: '12px' }}>
          <Image
            alt="Metamask logo"
            src={'/logo.svg'}
            width={160}
            height={120}
          />
        </div>
        <div className={profileStyles.affiliateWrapper}>
          <div className={profileStyles.form}>
            <input
              placeholder={'Insert Promo Code'}
              type={'text'}
              onChange={(e) => {
                handleInput(e.target.value)
              }}
              className={profileStyles.formInput}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <button
            disabled={!promoCode}
            onClick={signUpWithPromo}
            className={`btn btn--default btn--green`}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              cursor: 'pointer',
            }}
          >
            Sign Up With Promo Code
          </button>

          <div style={{ marginBottom: 4 }}>
            <p style={{ color: 'white', fontSize: '1.4rem' }}>
              Don't have a promo code?
            </p>

            <button
              style={{ paddingLeft: 8, paddingRight: 8 }}
              className="btn btn--default btn--dark"
              onClick={closeModal}
            >
              Continue to DFKArena
            </button>
          </div>
        </div>

        <button
          className={`btn dfkModal__close`}
          aria-label={`Close modal`}
          onClick={closeModal}
        />
      </Modal>
    </>
  )
}
