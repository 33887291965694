const prefix = 'FULL_PAGE_LOADING'

const persistentToastActions = {
  ADD_TOAST: `${prefix}_ADD_TOAST`,
  REMOVE_TOAST: `${prefix}_REMOVE_TOAST`,
  REMOVE_ALL_TOASTS: `${prefix}_REMOVE_ALL_TOASTS`,

  doAddToast(id, message?) {
    return {
      type: persistentToastActions.ADD_TOAST,
      payload: { id, message },
    }
  },

  doRemoveToast(id) {
    return {
      type: persistentToastActions.REMOVE_TOAST,
      payload: { id },
    }
  },

  doRemoveAllToasts() {
    return {
      type: persistentToastActions.REMOVE_ALL_TOASTS,
    }
  },
}

export default persistentToastActions
