import BigNumber from 'bignumber.js'
import { createSelector } from 'reselect'

const selectRaw = (state) => state.balance

const selectState = createSelector([selectRaw], (raw) => raw.state)
const selectFetchId = createSelector([selectRaw], (raw) => raw.fetchId)
const selectGoldBalance = createSelector([selectRaw], (raw) => raw.goldBalance)
const selectItemsPerPage = createSelector(
  [selectRaw],
  (raw) => raw.itemsPerPage,
)
const selectCurrentDepositPage = createSelector(
  [selectRaw],
  (raw) => raw.renderedDepositPage,
)
const selectCurrentWithdrawPage = createSelector(
  [selectRaw],
  (raw) => raw.renderedWithdrawPage,
)

const selectDepositHistory = createSelector(
  [selectRaw],
  (raw) => raw.depositHistory || [],
)

const selectDepositHistoryPaginated = createSelector(
  [selectRaw, selectCurrentDepositPage, selectItemsPerPage],
  (raw, renderedPage, itemsPerPage) => {
    return (
      raw.depositHistory.slice(
        renderedPage * itemsPerPage,
        (renderedPage + 1) * itemsPerPage,
      ) || []
    )
  },
)
const selectWithdrawHistory = createSelector(
  [selectRaw],
  (raw) => raw.withdrawHistory || [],
)

const selectWithdrawHistoryPaginated = createSelector(
  [selectRaw, selectCurrentWithdrawPage, selectItemsPerPage],
  (raw, renderedPage, itemsPerPage) => {
    return (
      raw.withdrawHistory.slice(
        renderedPage * itemsPerPage,
        (renderedPage + 1) * itemsPerPage,
      ) || []
    )
  },
)

const selectJewelBalance = createSelector(
  [selectRaw],
  (raw) => raw.jewelBalance,
)

const selectDepositHistoryLength = createSelector(
  [selectDepositHistory],
  (history) => {
    return history.length
  },
)
const selectWithdrawHistoryLength = createSelector(
  [selectWithdrawHistory],
  (history) => {
    return history.length
  },
)

const selectGoldTotalDeposit = createSelector(
  [selectDepositHistory],
  (history) => {
    return history
      .filter((dh) => dh.token === 'gold')
      .reduce((total, current) => {
        return new BigNumber(current.amount).plus(total)
      }, new BigNumber(0))
      .toNumber()
  },
)

const selectGoldTotalWithdraw = createSelector(
  [selectWithdrawHistory],
  (history) => {
    return history
      .filter((dh) => dh.token === 'gold')
      .reduce((total, current) => {
        return new BigNumber(current.amount).plus(total)
      }, new BigNumber(0))
      .toNumber()
  },
)

const selectJewelTotalDeposit = createSelector(
  [selectDepositHistory],
  (history) => {
    return history
      .filter((dh) => dh.token === 'jewel')
      .reduce((total, current) => {
        return new BigNumber(current.amount).plus(total)
      }, new BigNumber(0))
      .toNumber()
  },
)

const selectJewelTotalWithdraw = createSelector(
  [selectWithdrawHistory],
  (history) => {
    return history
      .filter((dh) => dh.token === 'jewel')
      .reduce((total, current) => {
        return new BigNumber(current.amount).plus(total)
      }, new BigNumber(0))
      .toNumber()
  },
)

const balanceSelectors = {
  selectState,
  selectRaw,
  selectDepositHistory,
  selectDepositHistoryPaginated,
  selectWithdrawHistory,
  selectWithdrawHistoryPaginated,
  selectGoldBalance,
  selectJewelBalance,
  selectGoldTotalDeposit,
  selectGoldTotalWithdraw,
  selectJewelTotalDeposit,
  selectJewelTotalWithdraw,
  selectItemsPerPage,
  selectCurrentDepositPage,
  selectCurrentWithdrawPage,
  selectDepositHistoryLength,
  selectWithdrawHistoryLength,
  selectFetchId,
}

export default balanceSelectors
