import userModalActions from './userModalActions'

const initialData = {
  data: null,
}

const userModalReducers = (state = initialData, { type, payload }) => {

  if (type === userModalActions.MODAL_CLOSED) {
    return {
      ...state,
      userAddress: null,
    }
  }

  if (type === userModalActions.MODAL_OPENED) {
    return {
      ...state,
      userAddress: payload,
    }
  }

  if (type === userModalActions.CHANGE_FAVORITE_HERO) {
    return {
      ...state,
      favoriteHero: payload,
    }
  }

  return state
}

export default userModalReducers