import { HeroOptionsForm } from './heroFilterFormInterface'

const prefix = 'HERO_FILTERS'

const heroFilterActions = {
  FILTERED_HEROES: `${prefix}_FILTERED_HEROES`,
  UPDATE_FILTER: `${prefix}_UPDATE`,
  PAGE_CHANGED: `${prefix}_PAGE_CHANGED`,

  doChangePage: (renderedPage) => {
    return {
      type: heroFilterActions.PAGE_CHANGED,
      payload: renderedPage,
    }
  },

  doUpdateFilter: (filters: HeroOptionsForm) => {
    return {
      type: heroFilterActions.UPDATE_FILTER,
      payload: filters,
    }
  },

  doFilteredHeroes: (filteredHeroes) => {
    return {
      type: heroFilterActions.FILTERED_HEROES,
      payload: filteredHeroes,
    }
  },
}

export default heroFilterActions