import React from 'react' // useState
import styles from '../styles.module.css'

/* MALE ARMS */
const maleLeftarmbtmhighlight = '/svgs/male/LeftArmBtm-highlight.svg'
const maleLeftarmbtmshadow = '/svgs/male/LeftArmBtm-shadow.svg'
const maleLeftarmtophighlight = '/svgs/male/LeftArmTop-highlight.svg'
const maleLeftarmtopshadow = '/svgs/male/LeftArmTop-shadow.svg'

const maleRightarmbtmhighlight = '/svgs/male/RightArmBtm-highlight.svg'
const maleRightarmbtmshadow = '/svgs/male/RightArmBtm-shadow.svg'
const maleRightarmtophighlight = '/svgs/male/RightArmTop-highlight.svg'
const maleRightarmtopshadow = '/svgs/male/RightArmTop-shadow.svg'

const archerLeftArmTop = '/svgs/male/clothes/archer-LeftArmTop.svg'
const archerLeftArmBottom = '/svgs/male/clothes/archer-LeftArmBtm.svg'

const archerRightArmTop = '/svgs/male/clothes/archer-RightArmTop.svg'
const archerRightArmBottom = '/svgs/male/clothes/archer-RightArmBtm.svg'

const knightLeftArmTop = '/svgs/male/clothes/knight-LeftArmTop.svg'
const knightLeftArmBottom = '/svgs/male/clothes/knight-LeftArmBtm.svg'

const knightRightArmTop = '/svgs/male/clothes/knight-RightArmTop.svg'
const knightRightArmBottom = '/svgs/male/clothes/knight-RightArmBtm.svg'

const monkLeftArmTop = '/svgs/male/clothes/monk-LeftArmTop.svg'
const monkLeftArmBottom = '/svgs/male/clothes/monk-LeftArmBtm.svg'

const monkRightArmTop = '/svgs/male/clothes/monk-RightArmTop.svg'
const monkRightArmBottom = '/svgs/male/clothes/monk-RightArmBtm.svg'

const pirateLeftArmTop = '/svgs/male/clothes/pirate-LeftArmTop.svg'
const pirateLeftArmBottom = '/svgs/male/clothes/pirate-LeftArmBtm.svg'

const pirateRightArmTop = '/svgs/male/clothes/pirate-RightArmTop.svg'
const pirateRightArmBottom = '/svgs/male/clothes/pirate-RightArmBtm.svg'

const priestLeftArmTop = '/svgs/male/clothes/priest-LeftArmTop.svg'
const priestLeftArmBottom = '/svgs/male/clothes/priest-LeftArmBtm.svg'

const priestRightArmTop = '/svgs/male/clothes/priest-RightArmTop.svg'
const priestRightArmBottom = '/svgs/male/clothes/priest-RightArmBtm.svg'

const thiefLeftArmTop = '/svgs/male/clothes/thief-LeftArmTop.svg'
const thiefLeftArmBottom = '/svgs/male/clothes/thief-LeftArmBtm.svg'

const thiefRightArmTop = '/svgs/male/clothes/thief-RightArmTop.svg'
const thiefRightArmBottom = '/svgs/male/clothes/thief-RightArmBtm.svg'

const warriorLeftArmTop = '/svgs/male/clothes/warrior-LeftArmTop.svg'
const warriorLeftArmBottom = '/svgs/male/clothes/warrior-LeftArmBtm.svg'

const warriorRightArmTop = '/svgs/male/clothes/warrior-RightArmTop.svg'
const warriorRightArmBottom = '/svgs/male/clothes/warrior-RightArmBtm.svg'

const wizardLeftArmTop = '/svgs/male/clothes/wizard-LeftArmTop.svg'
const wizardLeftArmBottom = '/svgs/male/clothes/wizard-LeftArmBtm.svg'

const wizardRightArmTop = '/svgs/male/clothes/wizard-RightArmTop.svg'
const wizardRightArmBottom = '/svgs/male/clothes/wizard-RightArmBtm.svg'

const archerWeapon = '/svgs/male/weapons/archer-weapon.svg'
const knightWeapon = '/svgs/male/weapons/knight-weapon.svg'
const monkWeapon = '/svgs/male/weapons/monk-weapon.svg'
const pirateWeapon = '/svgs/male/weapons/pirate-weapon.svg'
const priestWeapon = '/svgs/male/weapons/priest-weapon.svg'
const thiefWeapon = '/svgs/male/weapons/thief-weapon.svg'
const warriorWeapon = '/svgs/male/weapons/warrior-weapon.svg'
const wizardWeapon = '/svgs/male/weapons/wizard-weapon.svg'

/* ADVANCED HEROES */
const paladinLeftArmTop = '/svgs/male/clothes/advanced/paladin-LeftArmTop.svg'
const paladinLeftArmBottom =
  '/svgs/male/clothes/advanced/paladin-LeftArmBtm.svg'

const paladinRightArmTop = '/svgs/male/clothes/advanced/paladin-RightArmTop.svg'
const paladinRightArmBottom =
  '/svgs/male/clothes/advanced/paladin-RightArmBtm.svg'

const darkKnightLeftArmTop =
  '/svgs/male/clothes/advanced/darkKnight-LeftArmTop.svg'
const darkKnightLeftArmBottom =
  '/svgs/male/clothes/advanced/darkKnight-LeftArmBtm.svg'

const darkKnightRightArmTop =
  '/svgs/male/clothes/advanced/darkKnight-RightArmTop.svg'
const darkKnightRightArmBottom =
  '/svgs/male/clothes/advanced/darkKnight-RightArmBtm.svg'

const summonerLeftArmTop = '/svgs/male/clothes/advanced/summoner-LeftArmTop.svg'
const summonerLeftArmBottom =
  '/svgs/male/clothes/advanced/summoner-LeftArmBtm.svg'

const summonerRightArmTop =
  '/svgs/male/clothes/advanced/summoner-RightArmTop.svg'
const summonerRightArmBottom =
  '/svgs/male/clothes/advanced/summoner-RightArmBtm.svg'

const ninjaLeftArmTop = '/svgs/male/clothes/advanced/ninja-LeftArmTop.svg'
const ninjaLeftArmBottom = '/svgs/male/clothes/advanced/ninja-LeftArmBtm.svg'

const ninjaRightArmTop = '/svgs/male/clothes/advanced/ninja-RightArmTop.svg'
const ninjaRightArmBottom = '/svgs/male/clothes/advanced/ninja-RightArmBtm.svg'

/* ELITE HEROES */
const dragoonLeftArmTop = '/svgs/male/clothes/elite/dragoon-LeftArmTop.svg'
const dragoonLeftArmBottom = '/svgs/male/clothes/elite/dragoon-LeftArmBtm.svg'

const dragoonRightArmTop = '/svgs/male/clothes/elite/dragoon-RightArmTop.svg'
const dragoonRightArmBottom = '/svgs/male/clothes/elite/dragoon-RightArmBtm.svg'

const sageLeftArmTop = '/svgs/male/clothes/elite/sage-LeftArmTop.svg'
const sageLeftArmBottom = '/svgs/male/clothes/elite/sage-LeftArmBtm.svg'

const sageRightArmTop = '/svgs/male/clothes/elite/sage-RightArmTop.svg'
const sageRightArmBottom = '/svgs/male/clothes/elite/sage-RightArmBtm.svg'

/* LEGENDARY HEROES */
const dreadKnightLeftArmTop =
  '/svgs/male/clothes/legendary/dreadknight-LeftArmTop.svg'
const dreadKnightLeftArmBottom =
  '/svgs/male/clothes/legendary/dreadknight-LeftArmBtm.svg'

const dreadKnightRightArmTop =
  '/svgs/male/clothes/legendary/dreadknight-RightArmTop.svg'
const dreadKnightRightArmBottom =
  '/svgs/male/clothes/legendary/dreadknight-RightArmBtm.svg'

const paladinWeapon = '/svgs/male/weapons/paladin-weapon.svg'
const darkKnightWeapon = '/svgs/male/weapons/darkKnight-weapon.svg'
const summonerWeapon = '/svgs/male/weapons/summoner-weapon.svg'
const ninjaWeapon = '/svgs/male/weapons/ninja-weapon.svg'
const dragoonWeapon = '/svgs/male/weapons/dragoon-weapon.svg'
const sageWeapon = '/svgs/male/weapons/sage-weapon.svg'
const dreadKnightWeapon = '/svgs/male/weapons/dreadknight-weapon.svg'

const getClassInfo = (mainClass) => {
  switch (mainClass) {
    case 'archer': {
      return {
        classWeapon: archerWeapon,
        rightArmTop: archerRightArmTop,
        rightArmBottom: archerRightArmBottom,
        leftArmTop: archerLeftArmTop,
        leftArmBotttom: archerLeftArmBottom,
      }
    }
    case 'knight': {
      return {
        classWeapon: knightWeapon,
        rightArmTop: knightRightArmTop,
        rightArmBottom: knightRightArmBottom,
        leftArmTop: knightLeftArmTop,
        leftArmBotttom: knightLeftArmBottom,
      }
    }
    case 'monk': {
      return {
        classWeapon: monkWeapon,
        rightArmTop: monkRightArmTop,
        rightArmBottom: monkRightArmBottom,
        leftArmTop: monkLeftArmTop,
        leftArmBotttom: monkLeftArmBottom,
      }
    }
    case 'pirate': {
      return {
        classWeapon: pirateWeapon,
        rightArmTop: pirateRightArmTop,
        rightArmBottom: pirateRightArmBottom,
        leftArmTop: pirateLeftArmTop,
        leftArmBotttom: pirateLeftArmBottom,
      }
    }
    case 'priest': {
      return {
        classWeapon: priestWeapon,
        rightArmTop: priestRightArmTop,
        rightArmBottom: priestRightArmBottom,
        leftArmTop: priestLeftArmTop,
        leftArmBotttom: priestLeftArmBottom,
      }
    }
    case 'thief': {
      return {
        classWeapon: thiefWeapon,
        rightArmTop: thiefRightArmTop,
        rightArmBottom: thiefRightArmBottom,
        leftArmTop: thiefLeftArmTop,
        leftArmBotttom: thiefLeftArmBottom,
      }
    }
    case 'warrior': {
      return {
        classWeapon: warriorWeapon,
        rightArmTop: warriorRightArmTop,
        rightArmBottom: warriorRightArmBottom,
        leftArmTop: warriorLeftArmTop,
        leftArmBotttom: warriorLeftArmBottom,
      }
    }
    case 'wizard': {
      return {
        classWeapon: wizardWeapon,
        rightArmTop: wizardRightArmTop,
        rightArmBottom: wizardRightArmBottom,
        leftArmTop: wizardLeftArmTop,
        leftArmBotttom: wizardLeftArmBottom,
      }
    }
    case 'paladin': {
      return {
        classWeapon: paladinWeapon,
        rightArmTop: paladinRightArmTop,
        rightArmBottom: paladinRightArmBottom,
        leftArmTop: paladinLeftArmTop,
        leftArmBotttom: paladinLeftArmBottom,
      }
    }
    case 'darkKnight': {
      return {
        classWeapon: darkKnightWeapon,
        rightArmTop: darkKnightRightArmTop,
        rightArmBottom: darkKnightRightArmBottom,
        leftArmTop: darkKnightLeftArmTop,
        leftArmBotttom: darkKnightLeftArmBottom,
      }
    }
    case 'summoner': {
      return {
        classWeapon: summonerWeapon,
        rightArmTop: summonerRightArmTop,
        rightArmBottom: summonerRightArmBottom,
        leftArmTop: summonerLeftArmTop,
        leftArmBotttom: summonerLeftArmBottom,
      }
    }
    case 'ninja': {
      return {
        classWeapon: ninjaWeapon,
        rightArmTop: ninjaRightArmTop,
        rightArmBottom: ninjaRightArmBottom,
        leftArmTop: ninjaLeftArmTop,
        leftArmBotttom: ninjaLeftArmBottom,
      }
    }
    case 'dragoon': {
      return {
        classWeapon: dragoonWeapon,
        rightArmTop: dragoonRightArmTop,
        rightArmBottom: dragoonRightArmBottom,
        leftArmTop: dragoonLeftArmTop,
        leftArmBotttom: dragoonLeftArmBottom,
      }
    }
    case 'sage': {
      return {
        classWeapon: sageWeapon,
        rightArmTop: sageRightArmTop,
        rightArmBottom: sageRightArmBottom,
        leftArmTop: sageLeftArmTop,
        leftArmBotttom: sageLeftArmBottom,
      }
    }
    case 'dreadKnight': {
      return {
        classWeapon: dreadKnightWeapon,
        rightArmTop: dreadKnightRightArmTop,
        rightArmBottom: dreadKnightRightArmBottom,
        leftArmTop: dreadKnightLeftArmTop,
        leftArmBotttom: dreadKnightLeftArmBottom,
      }
    }
    default: {
      return {}
    }
  }
}

const RightArmTopMale = ({ armTop, armTopHighlight, armTopShadow, stroke }) => (
  <React.Fragment>
    <img src={armTop} className={styles.clothing} />
    <img src={armTopHighlight} className={styles.highlight} />
    <img src={armTopShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path
        stroke={stroke}
        d="M11 19h2M10 20h3M10 21h3M10 22h3M10 23h3M10 24h3M10 25h3M10 26h3M10 27h3M11 28h1"
      />
    </svg>
  </React.Fragment>
)

const RightArmBtmMale = ({ armBtm, armBtmHighlight, armBtmShadow, stroke }) => (
  <React.Fragment>
    <img src={armBtm} className={styles.clothing} />
    <img src={armBtmHighlight} className={styles.highlight} />
    <img src={armBtmShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path
        stroke={stroke}
        d="M10 26h2M9 27h3M8 28h4M7 29h5M7 30h4M7 31h4M8 32h2"
      />
    </svg>
  </React.Fragment>
)

const LeftArmTopMale = ({ armTop, armTopHighlight, armTopShadow, stroke }) => (
  <React.Fragment>
    <img src={armTop} className={styles.clothing} />
    <img src={armTopHighlight} className={styles.highlight} />
    <img src={armTopShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path
        stroke={stroke}
        d="M18 19h2M18 20h3M18 21h4M19 22h3M19 23h4M19 24h4M19 25h4M19 26h4M19 27h4"
      />
    </svg>
  </React.Fragment>
)

const LeftArmBtmMale = ({ armBtm, armBtmHighlight, armBtmShadow, stroke }) => (
  <React.Fragment>
    <img src={armBtm} className={styles.clothing} />
    <img src={armBtmHighlight} className={styles.highlight} />
    <img src={armBtmShadow} className={styles.shadow} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 30 45"
      shapeRendering="crispEdges"
      className={styles.color}
    >
      <path
        stroke={stroke}
        d="M21 26h3M19 27h5M19 28h6M19 29h6M19 30h6M19 31h6M20 32h4M20 33h2"
      />
    </svg>
  </React.Fragment>
)

const MaleArms = ({ mainClass, stroke }) => {
  const classInfo = getClassInfo(mainClass)
  return (
    <>
      <div className={`${styles.heroRightArm} ${styles.bodyPart}`}>
        <div className={`${styles.heroArmRightTop} ${styles.bodyPart}`}>
          <RightArmTopMale
            armTop={classInfo.rightArmTop}
            armTopShadow={maleRightarmtopshadow}
            armTopHighlight={maleRightarmtophighlight}
            stroke={stroke}
          />
        </div>
        <div className={`${styles.heroArmRightBtm} ${styles.bodyPart}`}>
          <RightArmBtmMale
            armBtm={classInfo.rightArmBottom}
            armBtmShadow={maleRightarmbtmshadow}
            armBtmHighlight={maleRightarmbtmhighlight}
            stroke={stroke}
          />
          {mainClass != 'monk' && (
            <div className={styles.heroWeapon}>
              <img src={classInfo.classWeapon} className={styles.clothing} />
            </div>
          )}
        </div>
      </div>
      <div className={`${styles.heroLeftArm} ${styles.bodyPart}`}>
        <div className={`${styles.heroArmLeftTop} ${styles.bodyPart}`}>
          <LeftArmTopMale
            armTop={classInfo.leftArmTop}
            armTopShadow={maleLeftarmtopshadow}
            armTopHighlight={maleLeftarmtophighlight}
            stroke={stroke}
          />
        </div>
        <div className={`${styles.heroArmLeftBtm} ${styles.bodyPart}`}>
          <LeftArmBtmMale
            armBtm={classInfo.leftArmBotttom}
            armBtmShadow={maleLeftarmbtmshadow}
            armBtmHighlight={maleLeftarmbtmhighlight}
            stroke={stroke}
          />
          {mainClass == 'monk' && (
            <div className={styles.heroWeapon}>
              <img src={classInfo.classWeapon} className={styles.clothing} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MaleArms
