import { useWeb3React } from '@web3-react/core'
import { uniqBy } from 'lodash'
import Image from 'next/image'
import React, { useEffect, useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import useAuthSignIn from '../../hooks/useAuthSignIn'
import classNames from '../../lib/classNames'
import Messages from '../../lib/messages'
import AdminModeratorService from '../../modules/adminModerator/adminModeratorService'
import { ApiErrors } from '../../modules/error/ApiErrors'
import { UserProfileService } from '../../modules/users/userProfileService'
import styles from '../../styles/Modals.module.scss'

export default function UserModalAdminBadges(props) {
  const { account } = useWeb3React()
  const { loadingSignIn, doSignIn, isSignedIn } = useAuthSignIn(account)
  const [allBadges, setAllBadges] = useState([])

  const { address, userName } = props

  const [badges, setBadges] = useState(props.badges || [])

  useEffect(() => {
    AdminModeratorService.fetchBadges(account, null, null)
      .then(({ rows }) => {
        setAllBadges(rows)
      })
      .catch((error) => ApiErrors.handle(error))
  }, [])

  useEffect(() => {
    setBadges(props.badges || [])
  }, [props.badges])

  function isIncluded(currentBadge) {
    const _isIncluded = (badges || []).some(
      (badge) => currentBadge.id === badge.id,
    )
    return _isIncluded
  }

  async function addBadge(badge) {
    if (!isSignedIn) {
      if (!loadingSignIn) {
        doSignIn()
      }
      return
    }

    setBadges(uniqBy([...badges, badge], 'id'))

    try {
      await UserProfileService.updateBadge(account, address, 'add', badge.id)
      Messages.success(`${badge.label} badge successfully added`)
    } catch (error) {
      setBadges(badges.filter((current) => current.id !== badge.id))
      ApiErrors.handle(error)
    }
  }

  async function removeBadge(badge) {
    if (!isSignedIn) {
      if (!loadingSignIn) {
        doSignIn()
      }
      return
    }
    setBadges(badges.filter((current) => current.id !== badge.id))

    try {
      await UserProfileService.updateBadge(account, address, 'remove', badge.id)
      Messages.success(`${badge.label} badge successfully removed`)
    } catch (error) {
      setBadges(uniqBy([...badges, badge], 'id'))
      ApiErrors.handle(error)
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [badges])

  return (
    <>
      {allBadges && Boolean(allBadges.length) && (
        <div className={classNames(styles.userModalBadges)}>
          {allBadges.map((badge, index) => {
            return (
              <div
                key={badge.id}
                onClick={() =>
                  isIncluded(badge) ? removeBadge(badge) : addBadge(badge)
                }
                data-tip={`<span>${badge.label}</span>`}
                data-html={true}
                className={classNames(
                  styles.userModalBadge,
                  styles.badge,
                  styles.badgeAdmin,
                  !isIncluded(badge) ? styles.badgeNotIncluded : '',
                )}
                data-testid={`badge-${badge.id}`}
              >
                <img alt="icon" src={badge.imageUrl} width={26} height={26} />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
