import BattleContractAbi from '../../contracts/BattleContractAbi'
import BattleContractAbiBeta1 from '../../contracts/BattleContractAbiBeta1'
import BattleContractAbiBeta2 from '../../contracts/BattleContractAbiBeta2'
import BattleContractAbiBeta3 from '../../contracts/BattleContractAbiBeta3'
import BattleContractAbiBeta4 from '../../contracts/BattleContractAbiBeta4'
import BattleContractAbiBeta5 from '../../contracts/BattleContractAbiBeta5'
import EscrowContractAbi from '../../contracts/EscrowContractAbi'
import EscrowContractAbiBeta1 from '../../contracts/EscrowContractAbiBeta1'
import EscrowContractAbiBeta2 from '../../contracts/EscrowContractAbiBeta2'
import EscrowContractAbiBeta3 from '../../contracts/EscrowContractAbiBeta3'
import duelStatusEnumerator from '../../enumerators/duelStatusEnumerator'
import duelStatusEnumeratorBeforeBeta4 from '../../enumerators/duelStatusEnumeratorBeforeBeta4'
import { ConfigStore } from '../config/configStore'

const getOlderVersionsConfig = () => [
  {
    label: 'current',
    current: true,
    battleContractAddress: ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
    escrowContractAddress: ConfigStore.get().ESCROW_CONTRACT_ADDRESS,
    battleContractAbi: BattleContractAbi,
    escrowContractAbi: EscrowContractAbi,
    duelStatusEnumerator: duelStatusEnumerator,
  },
  {
    label: 'Beta 5',
    battleContractAddress: ConfigStore.get().BATTLE_CONTRACT_BETA5_ADDRESS,
    battleContractAbi: BattleContractAbiBeta5,
    duelStatusEnumerator: duelStatusEnumerator,
  },
  {
    label: 'Beta 4',
    battleContractAddress: ConfigStore.get().BATTLE_CONTRACT_BETA4_ADDRESS,
    battleContractAbi: BattleContractAbiBeta4,
    duelStatusEnumerator: duelStatusEnumerator,
  },
  {
    label: 'Beta 3',
    battleContractAddress: ConfigStore.get().BATTLE_CONTRACT_BETA3_ADDRESS,
    escrowContractAddress: ConfigStore.get().ESCROW_CONTRACT_BETA3_ADDRESS,
    battleContractAbi: BattleContractAbiBeta3,
    escrowContractAbi: EscrowContractAbiBeta3,
    duelStatusEnumerator: duelStatusEnumeratorBeforeBeta4,
  },
  {
    label: 'Beta 2',
    battleContractAddress: ConfigStore.get().BATTLE_CONTRACT_BETA2_ADDRESS,
    escrowContractAddress: ConfigStore.get().ESCROW_CONTRACT_BETA2_ADDRESS,
    battleContractAbi: BattleContractAbiBeta2,
    escrowContractAbi: EscrowContractAbiBeta2,
    duelStatusEnumerator: duelStatusEnumeratorBeforeBeta4,
  },
  {
    label: 'Beta 1',
    battleContractAddress: ConfigStore.get().BATTLE_CONTRACT_BETA1_ADDRESS,
    escrowContractAddress: ConfigStore.get().ESCROW_CONTRACT_BETA1_ADDRESS,
    battleContractAbi: BattleContractAbiBeta1,
    escrowContractAbi: EscrowContractAbiBeta1,
    duelStatusEnumerator: duelStatusEnumeratorBeforeBeta4,
  },
]

export default getOlderVersionsConfig
