import { toLower } from 'lodash'
import { ConfigStore } from '../modules/config/configStore'

export default function isAdmin(publicAddress: string | undefined) {
  if (!publicAddress) {
    return false
  }

  const adminWallets = String(toLower(ConfigStore.get().ADMIN_ADDRESSES)).split(
    ',',
  )
  return adminWallets.includes(toLower(publicAddress))
}
