import BalanceService from './balanceService'
import { v4 as uuid } from 'uuid'
import balanceSelectors from './balanceSelectors'

const prefix = 'BALANCE'

const balanceActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  DEPOSIT_PAGINATION_CHANGED: `${prefix}_DEPOSIT_PAGINATION_CHANGED`,
  WITHDRAW_PAGINATION_CHANGED: `${prefix}_WITHDRAW_PAGINATION_CHANGED`,

  CLEAR: `${prefix}_CLEAR`,

  doClear() {
    return {
      type: balanceActions.CLEAR,
    }
  },

  doDepositPageChange: (page) => {
    return {
      type: balanceActions.DEPOSIT_PAGINATION_CHANGED,
      payload: page,
    }
  },

  doWithdrawPageChanged: (page) => {
    return {
      type: balanceActions.WITHDRAW_PAGINATION_CHANGED,
      payload: page,
    }
  },

  doFetch:
    (currentUserAccount, skipLoading = false) =>
    async (dispatch, getState) => {
      if (!currentUserAccount) {
        dispatch(balanceActions.doClear())
        return
      }

      try {
        const fetchId = uuid()

        dispatch({
          type: balanceActions.FETCH_STARTED,
          payload: {
            skipLoading,
            fetchId,
          },
        })

        const goldBalance = await BalanceService.fetchGoldBalance(
          currentUserAccount,
          0,
        )
        const jewelBalance = await BalanceService.fetchJewelBalance(
          currentUserAccount,
          0,
        )
        const withdrawHistory = await BalanceService.fetchWithdrawHistory(
          currentUserAccount,
          0,
        )

        const depositHistory = await BalanceService.fetchDepositHistory(
          currentUserAccount,
          0,
        )

        if (fetchId === balanceSelectors.selectFetchId(getState())) {
          dispatch({
            type: balanceActions.FETCH_SUCCESS,
            payload: {
              goldBalance,
              jewelBalance,
              withdrawHistory,
              depositHistory,
            },
          })
        }
      } catch (error) {
        console.error(error)
        if (!skipLoading) {
          dispatch({
            type: balanceActions.FETCH_ERROR,
          })
        }
      }
    },
}

export default balanceActions
